import React, { useEffect, useMemo, useState } from 'react';
import { DialogContent, FormControl, InputLabel } from '@mui/material';
import DialogComponent from '../../Dialogs/DialogComponent';
import Input from '../../Input';
import {
  useCreateVisualCategoryMutation,
  useGetVisualCategoriesQuery,
  useGetVisualCategoryQuery,
  useUpdateVisualCategoryMutation,
} from '../../../redux/services/visualCategory/api';
import useSnackbar from '../../../hooks/useSnackbar';
import { useGetVisualsByCategoryQuery } from '../../../redux/services/visuals/api';
import UpdateCategoryComponent from './UpdateCategoryComponent';

interface Props {
  open: boolean;
  categoryId?: string;
  onClose: () => void;
}

const CreateRenameGroup = (props: Props) => {
  const { open, categoryId, onClose } = props;

  const [name, setName] = useState<string>('');
  const [isSaveStarted, setIsSaveStarted] = useState<boolean>(false);

  const isCreate = !categoryId;

  const { data: visualCategories = [] } = useGetVisualCategoriesQuery();
  const { data: visualCategory, isFetching: isVisualCategoryFetching } =
    useGetVisualCategoryQuery(categoryId, {
      refetchOnMountOrArgChange: true,
      skip: !open || isCreate,
    });
  const { data: visuals, isLoading: isVisualsLoading } =
    useGetVisualsByCategoryQuery(visualCategory?.name, {
      skip: !visualCategory?.name,
    });

  const { showSuccess, showForbiddenError } = useSnackbar();

  const [
    createVisualCategory,
    { isSuccess: isCreateVisualSuccess, error: createVisualError },
  ] = useCreateVisualCategoryMutation();
  const [
    updateVisualCategory,
    { isSuccess: isUpdateVisualSuccess, error: updateVisualError, reset },
  ] = useUpdateVisualCategoryMutation();

  useEffect(() => {
    setName((open && visualCategory?.name) || '');
  }, [open, visualCategory]);

  const handleUpdateGroup = async () => {
    if (isCreate) {
      createVisualCategory({ name });
      return;
    }
    if (visuals?.length > 0) {
      setIsSaveStarted(true);
    }
    updateVisualCategory({
      id: categoryId,
      body: { name },
    });
  };

  const isActionButtonDisabled = useMemo(() => {
    if (isVisualsLoading) return true;
    const isFoundCategory =
      visualCategories.findIndex((cat) => cat.name === name) !== -1;
    if (isCreate) {
      return isFoundCategory || !name;
    }
    return (
      !visualCategory ||
      name === visualCategory?.name ||
      isFoundCategory ||
      !name
    );
  }, [name, visualCategory, isVisualsLoading]);

  useEffect(() => {
    if (isCreateVisualSuccess) {
      showSuccess(`${name} visual group has been successfully created`);

      onClose();
    }
  }, [isCreateVisualSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: createVisualError,
      customForbiddenMessage:
        "You don't have enough permissions to create visual group",
      customDefaultMessage:
        "Visual group hasn't been created. Something went wrong",
    });
  }, [createVisualError]);

  useEffect(() => {
    if (isUpdateVisualSuccess && visuals.length === 0) {
      showSuccess(`${name} visual group has been successfully updated`);
      reset();
      onClose();
    }
  }, [isUpdateVisualSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: updateVisualError,
      customForbiddenMessage:
        "You don't have enough permissions to update visual group",
      customDefaultMessage:
        "Visual group hasn't been updated. Something went wrong",
    });
  }, [updateVisualError]);

  const handleCloseUpdate = () => {
    setIsSaveStarted(false);
    onClose();
  };

  return (
    <DialogComponent
      isOpened={open}
      handleAction={handleUpdateGroup}
      closeModal={onClose}
      title={`${isCreate ? 'Create' : 'Rename'} group`}
      actionTitle="Save"
      isActionButtonDisabled={isActionButtonDisabled}
      id={`${isCreate ? 'Create' : 'Rename'}Group-dialog`}
      actionId={`${isCreate ? 'Create' : 'Rename'}Group-save_button`}
      cancelId={`${isCreate ? 'Create' : 'Rename'}Group-cancel_button`}
      sx={{ zIndex: 1402 }}
    >
      {isSaveStarted && (
        <UpdateCategoryComponent
          elementIds={visuals.map(({ id }) => id)}
          categoryName={name}
          handleCloseUpdate={handleCloseUpdate}
        />
      )}
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="group-name">
            Group name
          </InputLabel>
          <Input
            placeholder="Enter group name"
            id="group-name"
            value={name}
            disabled={isVisualCategoryFetching}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
      </DialogContent>
    </DialogComponent>
  );
};

CreateRenameGroup.defaultProps = {
  categoryId: undefined,
};

export default CreateRenameGroup;
