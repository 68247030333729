import { IconButton, styled } from '@mui/material';

export const FullscreenBtn = styled(IconButton)(({ theme }) => ({
  height: '44px',
  width: '44px',
  marginRight: 0,
  marginLeft: theme.spacing(2),
  color: theme.palette.mode === 'light' ? theme.palette.secondary.main : '#fff',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  '&.diagram': {
    backgroundColor: '#fff',
    borderColor: '#EBF4F9',
    color: theme.palette.secondary.main,
  },
  '&.active': {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
}));
