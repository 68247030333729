import React, { useState } from 'react';
import { Alert, Box, styled, Tooltip } from '@mui/material';
import { FullScreenHandle } from 'react-full-screen';
import { ReactFlowProvider } from 'react-flow-renderer';
import { CloseRounded, FullscreenRounded } from '@mui/icons-material';
import CreateTemplateDiagram from '../../../common/Diagram/CreateProjectDiagram';
import Sidebar from '../../../common/Diagram/library/Sidebar';
import { GalleryIcon } from '../../../icons';
import { DiagramWrap, RightContent, ToogleBtn } from './styles';
import { FullscreenBtn } from '../visuals/styles';

const IconBtn = styled(FullscreenBtn)(({ theme }) => ({
  width: 42,
  height: 42,
  color: theme.palette.text.secondary,
}));

interface Props {
  isIOS: boolean;
  handleRight: FullScreenHandle;
  fullscreenRight: boolean;
  setFullscreenRight: React.Dispatch<React.SetStateAction<boolean>>;
  isEditable: boolean;
}

const RightContentBlock = ({
  isIOS,
  handleRight,
  fullscreenRight,
  setFullscreenRight,
  isEditable,
}: Props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [error] = useState({ message: '', reason: '' });

  const handleSidebarShow = () => {
    setShowSidebar(true);
  };

  const handleSidebarHide = () => {
    setShowSidebar(false);
  };

  const fullscreenWithSidebar =
    showSidebar && (fullscreenRight || handleRight.active);

  return (
    <RightContent className={fullscreenRight ? 'fullscreen' : ''}>
      {!error?.message && (
        <Box
          sx={{
            position: 'absolute',
            top: '0.5rem',
            right: fullscreenWithSidebar ? '308px' : '0.5rem',
            zIndex: 9,
          }}
        >
          {!isIOS ? (
            <>
              {handleRight.active ? (
                <IconBtn className="active diagram" onClick={handleRight.exit}>
                  <CloseRounded />
                </IconBtn>
              ) : (
                <IconBtn className="diagram" onClick={handleRight.enter}>
                  <FullscreenRounded />
                </IconBtn>
              )}
            </>
          ) : (
            <>
              {fullscreenRight ? (
                <FullscreenBtn
                  className="active diagram"
                  onClick={() => setFullscreenRight(false)}
                >
                  <CloseRounded />
                </FullscreenBtn>
              ) : (
                <FullscreenBtn
                  className="diagram"
                  onClick={() => setFullscreenRight(true)}
                >
                  <FullscreenRounded />
                </FullscreenBtn>
              )}
            </>
          )}
        </Box>
      )}
      {!error?.message ? (
        <ReactFlowProvider>
          <DiagramWrap>
            <CreateTemplateDiagram
              isEditable={isEditable}
              isFullscreen={handleRight.active || fullscreenRight}
              onFullscreenEnter={handleRight.enter}
              onFullscreenExit={handleRight.exit}
              withFullscreen={false}
            />
            {isEditable && (
              <>
                {showSidebar ? (
                  <Sidebar onHide={handleSidebarHide} />
                ) : (
                  <Tooltip title="Open visuals panel" placement="left">
                    <ToogleBtn onClick={handleSidebarShow} className="outlined">
                      <GalleryIcon />
                    </ToogleBtn>
                  </Tooltip>
                )}
              </>
            )}
          </DiagramWrap>
        </ReactFlowProvider>
      ) : (
        <Box sx={{ p: 2 }}>
          <Alert severity="error">YAML is invalid.</Alert>
          <pre>{error?.message}</pre>
        </Box>
      )}
    </RightContent>
  );
};

export default RightContentBlock;
