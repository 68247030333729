import React from 'react';
import { Box, IconButton, Typography, styled, Stack } from '@mui/material';
import cn from 'classnames';
import { CloseRounded, ExpandMoreRounded } from '@mui/icons-material';
import { editor } from 'monaco-editor';
import ProblemsTable from './ProblemsTable';
import ProblemsCount from './ProblemsCount';

export enum monacoSeverity {
  Hint = 1,
  Info = 2,
  Warning = 4,
  Error = 8,
}

const Root = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
  border: '1px solid transparent',
  '&.opened': {
    borderColor: theme.palette.divider,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    overflow: 'hidden',
    flex: 1,
  },
}));

const Flex = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const Head = styled(Flex)(() => ({
  justifyContent: 'space-between',
  minHeight: 20,
}));

const Title = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
}));

const ProblemsBlock = ({
  problemsList,
  expanded,
  setExpanded,
  opened,
  setOpened,
  onClick,
}: {
  problemsList: editor.IMarker[];
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: ({
    startLineNumber,
    startColumn,
  }: {
    startLineNumber: number;
    startColumn: number;
  }) => void;
}) => {
  const handleToggleOpen = (ev: React.BaseSyntheticEvent) => {
    ev.stopPropagation();
    setOpened((prev) => !prev);
  };

  const handleMinimize = (ev: React.BaseSyntheticEvent) => {
    ev.stopPropagation();
    setOpened((prev) => !prev);
    setExpanded(false);
  };

  const toggleExpand = (ev: React.BaseSyntheticEvent) => {
    ev.stopPropagation();
    setExpanded((prev) => !prev);
  };

  const problemsClass =
    cn(expanded || (opened && 'opened')) ||
    monacoSeverity[
      Math.max(...problemsList.map((problem) => problem.severity))
    ]?.toLowerCase() ||
    'info';

  return (
    <Root
      id="Problems"
      className={cn(expanded && 'expanded', opened && 'opened')}
    >
      <Head>
        <Flex sx={{ cursor: 'pointer' }} onClick={(e) => handleToggleOpen(e)}>
          <Title>Problems</Title>
          <ProblemsCount
            className={problemsClass}
            problemsCount={problemsList.length}
          />
          {!opened && (
            <ExpandMoreRounded sx={{ transform: 'rotate(180deg)' }} />
          )}
        </Flex>
        <Flex>
          {opened && (
            <>
              <IconButton sx={{ flex: 1 }} onClick={(e) => toggleExpand(e)}>
                <ExpandMoreRounded
                  sx={{
                    transform: !expanded ? 'rotate(180deg)' : 'none',
                  }}
                />
              </IconButton>
              <IconButton sx={{ flex: 1 }} onClick={handleMinimize}>
                <CloseRounded />
              </IconButton>
            </>
          )}
        </Flex>
      </Head>
      {(opened || expanded) && (
        <ProblemsTable problemsList={problemsList} onClick={onClick} />
      )}
    </Root>
  );
};

export default ProblemsBlock;
