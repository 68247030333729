import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setToggleAccessible } from '../redux/modules/view';
import { useAppDispatch } from '../redux/store';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import { envVars } from '../env';

// eslint-disable-next-line react/display-name
const withHidden = (Component: any) => (props) => {
  const dispatch = useAppDispatch();

  const isDemo = useSelector((state: IAppState) => state.isDemo);

  const isGranted = envVars.demoMode;

  useEffect(() => {
    if (isGranted) {
      dispatch(setToggleAccessible(true));
    }
  }, []);

  if (!isDemo) return null;
  return <Component {...props} />;
};

export default withHidden;
