import React, { useState, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { Box, Tooltip } from '@mui/material';
import { MapRounded } from '@mui/icons-material';
import { Head, HeadLeft, HeadRight, Title } from './styles';
import SiteFilter from '../../../common/SiteFilter';
import StatusFilter from '../StatusFilter';
import { ListIcon } from '../../../icons';
import { SwitchBtn, SwitchWrap } from '../../../common/styles/switch';
import { ROUTES_PATH } from '../../../constants';
import { Site } from '../../../redux/services/sites/types';
import FilterComponents from './FiltersComponent';
import { useAppSelector } from '../../../redux/store';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';

interface Props {
  mapView: boolean;
  setMapView: Dispatch<SetStateAction<boolean>>;
  isSearch: boolean;
  searchValue: string;
  handleSearchInput: (_ev: ChangeEvent<HTMLInputElement>) => void;
  handleCollapseSearch: () => void;
  status: string[];
  statuses: string[];
  handleStatusChange: (_ev: any) => void;
  isFilter: boolean;
  handleToggleFilter: () => void;
  sites: Site[];
  activeSites: string[];
  handleExpandSearch: () => void;
  handleCreateOpen: () => void;
}

const TopBar = ({
  mapView,
  setMapView,
  isSearch,
  searchValue,
  handleSearchInput,
  handleCollapseSearch,
  status,
  statuses,
  handleStatusChange,
  isFilter,
  handleToggleFilter,
  sites,
  activeSites,
  handleExpandSearch,
  handleCreateOpen,
}: Props) => {
  const [selection, setSelection] = useState(['ALLSITES']);

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  const history = useHistory();

  const onMapView = () => {
    history.push(`${ROUTES_PATH.SYSTEMS}/map`);
    setMapView(true);
  };

  const onListView = () => {
    history.push(`${ROUTES_PATH.SYSTEMS}/list`);
    setMapView(false);
  };

  return (
    <Head>
      <HeadLeft>
        <Title sx={{ display: { xs: 'none', md: 'block' } }} variant="h1">
          Systems
        </Title>
        {mapView && (
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              mr: '0.75rem',
            }}
          >
            <SiteFilter selection={selection} setSelection={setSelection} />
            <StatusFilter
              value={status}
              onChange={handleStatusChange}
              statuses={statuses}
              sx={{ minWidth: '122px', ml: '0.75rem' }}
            />
          </Box>
        )}

        <FilterComponents
          mapView={mapView}
          isSearch={isSearch}
          searchValue={searchValue}
          handleSearchInput={handleSearchInput}
          handleCollapseSearch={handleCollapseSearch}
          status={status}
          statuses={statuses}
          handleStatusChange={handleStatusChange}
          isFilter={isFilter}
          handleToggleFilter={handleToggleFilter}
          sites={sites}
          activeSites={activeSites}
          handleExpandSearch={handleExpandSearch}
          handleCreateOpen={handleCreateOpen}
          selection={selection}
          setSelection={setSelection}
          onMapView={onMapView}
          onListView={onListView}
        />
      </HeadLeft>
      <HeadRight>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          {!isEdgeEnv && (
            <SwitchWrap>
              <Tooltip title="List view">
                <SwitchBtn
                  onClick={onListView}
                  className={!mapView && 'active'}
                  color="inherit"
                  id="System-list_view"
                >
                  <ListIcon />
                </SwitchBtn>
              </Tooltip>
              <Tooltip title="Map view">
                <SwitchBtn
                  onClick={onMapView}
                  className={mapView && 'active'}
                  color="inherit"
                  id="System-map_view"
                >
                  <MapRounded />
                </SwitchBtn>
              </Tooltip>
            </SwitchWrap>
          )}
        </Box>
      </HeadRight>
    </Head>
  );
};

export default TopBar;
