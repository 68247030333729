//@ts-nocheck
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Handle, Position } from 'react-flow-renderer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PointComponent(props: SvgIconProps) {
  const newProps = {
    data: props.data,
    id: props.id,
    isconnectable: props.isConnectable?.toString(),
    selected: props.selected,
    type: props.type,
    xpos: props.xPos,
    ypos: props.yPos,
    targetposition: props.targetPosition,
    sourceposition: props.sourcePosition,
  };

  return (
    <SvgIcon {...newProps}>
      <Handle
        className="point"
        type="target"
        position={Position.Top}
        id="t-center"
      />
      <Handle
        className="point"
        type="source"
        id="s-center"
        position={Position.Top}
      />
      {/* <path id="Path_396" data-name="Path 396" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_397"
        data-name="Path 397"
        d="M12,2A10,10,0,1,0,22,12,10.016,10.016,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20Zm3-8a3,3,0,1,1-3-3A3,3,0,0,1,15,12Z"
      /> */}
    </SvgIcon>
  );
}

export default PointComponent;
