import { useEffect, useState } from 'react';
import yaml from 'js-yaml';
import { Node, XYPosition } from 'react-flow-renderer';
import { editor } from 'monaco-editor';
import { diffTrimmedLines } from 'diff';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../redux/store/index';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import {
  setTemplateVisuals,
  setTemplateOldText,
  setTemplateFileName,
  setTemplateIsLoading,
  setTemplateAction,
} from '../redux/modules/editor/slice';
import { ROUTES_PATH, YAML_LANGUAGE } from '../constants';
import useSnackbar from './useSnackbar';
import useYAMLEditor from './useYAMLEditor';
import {
  useDeleteProjectMutation,
  useGetProjectDocumentQuery,
  useUpdateProjectYAMLMutation,
} from '../redux/services/ensProjects/api';
import { createConfigTemplate } from '../common/Diagram/CreateProjectDiagram/utils';
import {
  useCreateSystemConfigurationTemplateMutation,
  useDeleteSystemConfigurationTemplateMutation,
  useGetSystemConfigurationTemplatesQuery,
} from '../redux/services/systemConfigurationTemplate/api';
import { useGetVisualsQuery } from '../redux/services/visuals/api';
import {
  ActionsType,
  IEdgeAction,
} from '../typescript/interfaces/editor.interface';
import useDebounce from './useDebounce';
import { IDiagramInstrument } from '../typescript/interfaces/instrument.interface';

export const parseToJSON = (value: string) => {
  try {
    const parsedValue = yaml.load(value);
    if (!parsedValue) return {};
    return parsedValue;
  } catch (err) {
    console.warn({ err });
    return {};
  }
};
const parseToYAML = (value: { [key: string]: any }) =>
  yaml.dump(value, {
    noCompatMode: true,
  });

const generateComponent = (visual, yamlDoc, position) => {
  const newDoc = { ...yamlDoc };
  const { components, visuals } = newDoc;

  const searchName = visual.name || visual.id;

  const name =
    searchName in components
      ? `${searchName} ${
          Object.keys(components).filter(
            (component) => component.indexOf(searchName) > -1,
          ).length
        }`
      : searchName;
  const isVisualExists = searchName in visuals;

  if (!isVisualExists) {
    const newVisualJSON = {
      flairs: {},
      id: visual.id,
      version: visual.activeAssetVersion,
    };

    newDoc.visuals = { ...newDoc.visuals, [searchName]: newVisualJSON };
  }

  const newComponentJSON = {
    instruments: {},
    ports: {},
    render: [position.x, position.y],
    visual: {
      $ref: `#/visuals/${searchName}`,
      flairs: visual.flairs,
      input: {},
      size: visual.size,
    },
  };

  newDoc.components = { ...newDoc.components, [name]: newComponentJSON };

  return { doc: newDoc, name };
};

const useYAMLTemplateEditorState = ({ id }: { id: string }) => {
  const location = useLocation();

  const yamlDocumentJSON = useAppSelector(
    (state: IAppState) => state.editor.template.json,
  );
  const isLoading = useAppSelector(
    (state: IAppState) => state.editor.template.isLoading,
  );
  const isFocusEditor = useAppSelector(
    (state: IAppState) => state.editor.template.isFocusEditor,
  );

  const [textData, setTextData] = useState<string>();
  // TODO handling initial text state for prompt
  const [name, setName] = useState<string>('');
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(true);
  const debouncedTextData = useDebounce(textData, 1000);

  const { yamlInstance } = useYAMLEditor({ isSetMarkers: false });

  const { showSuccess, showForbiddenError } = useSnackbar();

  const { data: documentData, isLoading: isDocumentLoading } =
    useGetProjectDocumentQuery(id, { skip: !id });

  const { data: visuals } = useGetVisualsQuery();
  const { data: templates } = useGetSystemConfigurationTemplatesQuery();

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_VISUAL)) return;
    if (id) return;
    const defaultTemplate = createConfigTemplate({ name: '', description: '' });
    setTextData(defaultTemplate);
    setTemplateOldText(defaultTemplate);
    const jsonVisuals = parseToJSON(defaultTemplate);
    dispatch(setTemplateVisuals(jsonVisuals));
  }, []);

  useEffect(() => {
    if (!documentData) return;
    setTextData(documentData);
  }, [documentData]);

  useEffect(() => {
    if (!templates) return;
    if (id) return;
    setIsNameInvalid(templates.some((template) => template.name === name));
  }, [name]);

  const [
    createTemplate,
    { isSuccess: isCreateTemplateSuccess, error: createError },
  ] = useCreateSystemConfigurationTemplateMutation();
  const [
    updateProject,
    { isSuccess: isUpdateProjectSuccess, error: updateError },
  ] = useUpdateProjectYAMLMutation();
  const [
    deleteSystemConfiguration,
    {
      isSuccess: isConfigurationDeletionSuccess,
      error: configurationDeletionError,
    },
  ] = useDeleteSystemConfigurationTemplateMutation();
  const [
    deleteProject,
    { isSuccess: isProjectDeletionSuccess, error: projectDeletionError },
  ] = useDeleteProjectMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_VISUAL)) return;
    if (!documentData) return;
    const jsonVisuals = parseToJSON(documentData);
    if (JSON.stringify(documentData) !== JSON.stringify(jsonVisuals)) {
      dispatch(setTemplateVisuals(jsonVisuals));
      setTextData(documentData);
      setTemplateOldText(documentData);
      dispatch(setTemplateFileName(`${jsonVisuals?.name}.${YAML_LANGUAGE}`));
    }
  }, [documentData]);

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_VISUAL)) return;
    if (!debouncedTextData || !yamlInstance) return;
    if (!isFocusEditor) {
      yamlInstance?.setValue(debouncedTextData);
      return;
    }
    const changes = diffTrimmedLines(textData, debouncedTextData);
    const filteredUpdates = changes.filter(
      ({ added, removed }) => added || removed,
    );
    if (!filteredUpdates || (filteredUpdates && filteredUpdates?.length === 0))
      return;

    let nodePosition: number | undefined;

    const matches = filteredUpdates.map((v) =>
      yamlInstance
        ?.getModel()
        ?.findMatches(v.value, true, false, true, null, false)
        .filter(({ range }) => {
          if (!nodePosition) return true;
          if (range.startLineNumber >= nodePosition) return true;
          return false;
        }),
    ) as editor.FindMatch[][];

    const filteredMatches = matches.reduce(
      (mathcesAccumulator, currentMatchRow) => {
        const flatMatches = mathcesAccumulator.flat(2).filter((v) => v);

        const notMatches = currentMatchRow.find(
          ({ range }) =>
            !flatMatches.find(
              (matchAcc) =>
                JSON.stringify(matchAcc.range) === JSON.stringify(range),
            ),
        );
        mathcesAccumulator.push(notMatches);
        return mathcesAccumulator;
      },
      [],
    );

    filteredMatches.forEach((v, idx) => {
      if (!v) return;
      const { range } = v;
      if (!range) return;

      const op = {
        range,
        text:
          filteredUpdates?.[idx + 1]?.value ||
          filteredUpdates?.[idx - 1]?.value,
        forceMoveMarkers: false,
      };

      yamlInstance.executeEdits('source', [op]);
    });
  }, [debouncedTextData]);

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_VISUAL)) return;
    if (Object.keys(yamlDocumentJSON).length === 0) return;
    parseToYAML(yamlDocumentJSON);
    setTextData(parseToYAML(yamlDocumentJSON));
    setName(yamlDocumentJSON?.name || '');
  }, [yamlDocumentJSON]);

  useEffect(() => {
    if (isCreateTemplateSuccess) {
      showSuccess(`Template ${yamlDocumentJSON.name} was successfully created`);
    }
    if (isUpdateProjectSuccess) {
      showSuccess(`Project ${yamlDocumentJSON.name} was successfully updated`);
    }
  }, [isCreateTemplateSuccess, isUpdateProjectSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: createError,
      customForbiddenMessage:
        "You don't have enough permissions to create a system configuration template",
      customDefaultMessage: 'Failed to create system configuration template',
    });
  }, [createError]);

  useEffect(() => {
    showForbiddenError({
      error: updateError,
      customForbiddenMessage:
        "You don't have enough permissions to update a project",
      customDefaultMessage: 'Failed to update project',
    });
  }, [updateError]);

  useEffect(() => {
    if (isProjectDeletionSuccess) {
      showSuccess(
        `Project ${name} deletion has started and will continue on the background`,
      );
    }
  }, [isProjectDeletionSuccess]);

  useEffect(() => {
    if (isConfigurationDeletionSuccess) {
      showSuccess(`Template ${name} was successfully deleted`);
    }
  }, [isConfigurationDeletionSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: projectDeletionError,
      customForbiddenMessage:
        "You don't have enough permissions to delete this project",
      customDefaultMessage: `Project ${name} was not deleted`,
    });
  }, [projectDeletionError]);

  useEffect(() => {
    showForbiddenError({
      error: configurationDeletionError,
      customForbiddenMessage:
        "You don't have enough permissions to delete this system configuration template",
      customDefaultMessage: `Template ${name} was not deleted`,
    });
  }, [configurationDeletionError]);

  useEffect(() => {
    if (location?.pathname?.startsWith(ROUTES_PATH.CREATE_VISUAL)) return;
    if (isDocumentLoading) {
      dispatch(setTemplateIsLoading(true));
      return;
    }
    dispatch(setTemplateIsLoading(false));
  }, [isDocumentLoading]);

  const handleTextValue = (newText: string) => {
    const jsonVisuals = parseToJSON(newText);
    dispatch(setTemplateVisuals(jsonVisuals));
  };

  const handleFileUpload = (newText: string) => {
    handleTextValue(newText);
  };

  const handleNameChange = (newValue: string) => {
    setName(newValue);
    dispatch(setTemplateVisuals({ ...yamlDocumentJSON, name: newValue }));
  };

  const handleNodeAdd = ({
    nodeId,
    position,
  }: {
    nodeId: string;
    position?: XYPosition;
  }) => {
    const pos: XYPosition = position || { x: 0, y: 0 };
    const insertVisual = visuals.find((v) => v.id === nodeId);
    const newYaml = generateComponent(insertVisual, yamlDocumentJSON, pos);

    dispatch(setTemplateVisuals(newYaml.doc));
    const action = {
      type: ActionsType.nodeCreated,
      nodeId: { id: newYaml.name },
    };
    dispatch(setTemplateAction(action));
  };

  const handleNodeRemove = ({
    label,
    visualId,
  }: {
    label: string;
    visualId: string;
  }) => {
    const newJsonDoc = { ...yamlDocumentJSON };
    const components = { ...newJsonDoc.components };
    const connectors = { ...newJsonDoc.connectors };
    const newVisuals = { ...newJsonDoc.visuals };
    const componentKeys = Object.keys(components);
    const connectorKeys = Object.keys(connectors);
    const visualKeys = Object.entries(newVisuals);
    const foundVisual = visualKeys.find(
      ([_, val]: [_: string, val: { id?: string }]) => visualId === val?.id,
    )?.[0];

    if (foundVisual) {
      const countComponents = componentKeys.reduce((acc, componentId) => {
        if (componentId.toLowerCase().includes(foundVisual?.toLowerCase())) {
          return acc + 1;
        }
        return acc;
      }, 0);

      if (countComponents < 2) {
        delete newVisuals[foundVisual];
      }
    }

    delete components[label];

    connectorKeys.forEach((connectorId) => {
      if (
        connectorId.startsWith(`${label} to`) ||
        connectorId.startsWith(`${label} - output`) ||
        connectorId.endsWith(`to ${label}`) ||
        connectorId.includes(`to ${label} - input`)
      ) {
        delete connectors[connectorId];
      }
    });

    dispatch(
      setTemplateVisuals({
        ...newJsonDoc,
        components: {
          ...components,
        },
        connectors: {
          ...connectors,
        },
        visuals: {
          ...newVisuals,
        },
      }),
    );
  };

  const handleSaveAction = () => {
    if (id?.startsWith('PROJ')) {
      updateProject({ id, data: textData });
      return;
    }
    createTemplate(textData);
  };

  const handleLineSelect = ({
    startColumn,
    startLineNumber,
  }: {
    startColumn: number;
    startLineNumber: number;
  }) => {
    const action = {
      type: ActionsType.lineSelected,
      line: startLineNumber,
      column: startColumn,
    };
    dispatch(setTemplateAction(action));
  };

  const handleNodeClick = ({ nodeId }) => {
    const model = yamlInstance.getModel();
    const index = model.getValue().indexOf(`${nodeId}:`);
    const position = model.getPositionAt(index);
    const action = {
      type: ActionsType.lineSelected,
      line: position.lineNumber,
      column: position.column,
    };
    dispatch(setTemplateAction(action));
  };

  const handleDeleteDocument = () => {
    if (!id) return;
    if (id.startsWith('PROJ')) {
      deleteProject(id);
    }
    deleteSystemConfiguration(id);
  };

  const handleDiagramChange = ({ nodes }: { nodes: Node[] }) => {
    const newJsonDoc = { ...yamlDocumentJSON };
    const { connectors, components } = newJsonDoc;
    const filteredNodes = nodes.filter(
      (node) => node.id.startsWith('edgeDot_') && !node.id.endsWith('_extra'),
    );
    const newConnectors = Object.fromEntries(
      Object.entries(connectors).map(
        ([label, value]: [
          key: string,
          value: {
            instruments?: IDiagramInstrument[];
            ports?: { $ref: string }[];
            waypoints?: { x: number; y: number }[];
          },
        ]) => {
          const waypoints = filteredNodes.filter((waypoint) =>
            waypoint.id.includes(label),
          );
          if (waypoints.length === 0) return [label, value];
          const sourceNode = Object.entries(components)?.find(([node]) =>
            label.startsWith(node),
          )[1] as { render: number[] };
          if (!sourceNode) return [label, value];
          const newWaypoints = waypoints.map(({ position }) => ({
            x: Math.round(position.x) - sourceNode.render[0],
            y: Math.round(position.y) - sourceNode.render[1],
          }));

          return [label, { ...value, waypoints: newWaypoints }];
        },
      ),
    );

    newJsonDoc.connectors = newConnectors;

    dispatch(setTemplateVisuals(newJsonDoc));
  };

  const handleNodePosition = ({ node }: { node: Node }) => {
    if (node.id.startsWith('edgeDot')) return;
    const newJsonDoc = { ...yamlDocumentJSON };
    const { components } = newJsonDoc;
    const updatedNode = {
      ...components[node.id],
      render: [Math.round(node.position.x), Math.round(node.position.y)],
    };
    newJsonDoc.components = {
      ...newJsonDoc.components,
      [node.id]: updatedNode,
    };
    dispatch(setTemplateVisuals(newJsonDoc));

    if (yamlInstance) {
      const model = yamlInstance.getModel();
      const matchNode = model.findMatches(
        `${node.id}:`,
        true,
        false,
        true,
        null,
        false,
      )[0];

      if (matchNode) {
        const matchRenderNode = model
          .findMatches('render:', true, false, true, null, false)
          .filter(
            ({ range }) =>
              range.startLineNumber > matchNode.range.startLineNumber,
          )
          .sort(
            (a, b) =>
              Math.abs(
                a.range.startLineNumber - matchNode.range.startLineNumber,
              ) -
              Math.abs(
                b.range.startLineNumber - matchNode.range.startLineNumber,
              ),
          )[0];

        if (matchRenderNode) {
          const action = {
            type: ActionsType.lineHighlighted,
            startLine: matchRenderNode.range.startLineNumber,
            endLine: matchRenderNode.range.startLineNumber + 2,
          };

          setTimeout(() => {
            dispatch(setTemplateAction(action));
          }, 1500);
        }
      }
    }
  };

  const handleEdges = (action: IEdgeAction) => {
    const newJsonDoc = { ...yamlDocumentJSON };
    if (action.type === 'add') {
      const { source, sourceHandle, target, targetHandle } = action.edge;
      const connectorId = `${source} - ${sourceHandle} to ${target} - ${targetHandle}`;
      const newConnector = {
        instruments: {},
        ports: [
          {
            $ref: `#/components/${source}/ports/${sourceHandle}`,
          },
          {
            $ref: `#/components/${target}/ports/${targetHandle}`,
          },
        ],
      };
      newJsonDoc.connectors = {
        ...newJsonDoc.connectors,
        [connectorId]: newConnector,
      };
      dispatch(setTemplateVisuals(newJsonDoc));
    } else if (action.type === 'remove') {
      const connectors = { ...newJsonDoc.connectors };
      delete connectors[action.id];
      newJsonDoc.connectors = {
        ...connectors,
      };
      dispatch(setTemplateVisuals(newJsonDoc));
    }
  };

  return {
    json: yamlDocumentJSON,
    text: textData,
    name,
    isLoading,
    isNameInvalid,
    handleTextValue,
    handleFileUpload,
    handleNameChange,
    handleNodeAdd,
    handleNodeRemove,
    handleSaveAction,
    handleLineSelect,
    handleNodeClick,
    handleDeleteDocument,
    handleDiagramChange,
    handleNodePosition,
    handleEdges,
  };
};

export default useYAMLTemplateEditorState;
