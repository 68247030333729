import { Stack, Box, styled } from '@mui/material';

export const Root = styled(Stack)(({ theme }) => ({
  boxSizing: 'border-box',
  height: 'calc(100% - 60px)',
  maxHeight: 'calc(100% - 60px)',
  [theme.breakpoints.down('md')]: {
    maxHeight: 'unset',
  },
  '& .library': {
    margin: `0 ${theme.spacing(3)}`,
    marginBottom: theme.spacing(2),
  },
}));

export const HeadWrap = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    paddingRight: 0,
  },
}));

export const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: 0,
  [theme.breakpoints.down('md')]: {
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: theme.spacing(2),
  },
}));

export const InnerWrap = styled(Box)<{ tabValue: string; desktop: boolean }>(
  ({ desktop, tabValue, theme }) => ({
    flex: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(2),
    maxHeight: 'calc(100% - 60px)',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: '50px',
      paddingBottom: 0,
    },
    '& > div': {
      display: desktop || tabValue === '0' ? 'block' : 'none',
      width: !desktop ? '100% !important' : '',
      '& aside': {
        margin: !desktop ? 0 : '',
      },
    },
    '& > section': {
      display: desktop || tabValue === '1' ? 'block' : 'none',
      margin: !desktop ? 0 : '',
    },
  }),
);

export const LeftContent = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  padding: theme.spacing(2),
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  '&.fullscreen': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    backgroundColor: theme.palette.background.default,
  },
}));
