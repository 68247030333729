import React, { useCallback, useContext } from 'react';
import { styled } from '@mui/material';
import cn from 'classnames';
import { Context } from '../../CreateProjectDiagram/context';

const Dot = styled('div')({
  width: 14,
  height: 14,
  display: 'block',
  borderRadius: '50%',
  backgroundColor: '#4C9FC8',
  '&.extra-dot': {
    backgroundColor: 'red',
  },
  '&.hidden': {
    display: 'none',
  },
});

interface Props {
  id: string;
  data: any;
}

function EdgeDot(props: Props) {
  const { id, data = {} } = props;
  const { isHidden } = data;
  const { setNodes, zoom, nodesDraggable } = useContext(Context) || {};

  const isExtraDot = id.includes('extra');

  const handleRemoveDot = useCallback(() => {
    if (!isExtraDot) {
      const [, edgeId] = id.split('_');

      setNodes((prev: any) =>
        prev.filter(
          (n) =>
            n.id !== id &&
            !(n.id.includes(`edgeDot_${edgeId}`) && n.id.includes('extra')),
        ),
      );
    }
  }, [id, isExtraDot]);

  const zoomRounded = zoom > 0.48 ? 0.5 : 1;
  const size = zoom ? Math.max(3, Math.round(10 / (zoom * zoomRounded))) : '';

  return (
    <Dot
      className={cn(
        isExtraDot ? 'extra-dot' : '',
        (!nodesDraggable || isHidden) && 'hidden',
      )}
      onDoubleClick={handleRemoveDot}
      style={{ width: size, height: size }}
    />
  );
}

export default EdgeDot;
