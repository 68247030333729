import React, { useState } from 'react';
import { Button, DialogContent, Stack } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import DialogComponent from '../../../common/Dialogs/DialogComponent';
import CreateRenameGroup from './CreateRenameGroup';
import { useGetVisualCategoriesQuery } from '../../../redux/services/visualCategory/api';
import { CardHeadRoot } from './styles';
import Card from './Card';
import { isForbiddenError } from '../../../redux/utils';
import { sortArrayByProperty } from '../../../utils/stringUtils';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ManageGroups = (props: Props) => {
  const { open, onClose } = props;

  const [isCreateRenameOpen, setIsCreateRenameOpen] = useState<boolean>(false);

  const { data: visualCategories = [], error: visualCategoryError } =
    useGetVisualCategoriesQuery();

  const handleCreateCategory = () => {
    setIsCreateRenameOpen(true);
  };

  const handleCloseModal = () => {
    setIsCreateRenameOpen(false);
  };

  if (isForbiddenError(visualCategoryError)) return null;

  return (
    <DialogComponent
      isOpened={open}
      closeModal={onClose}
      isActionButtonDisplayed={false}
      cancelButtonTitle="Close"
      cancelId="ManageGroups-close"
      sx={{ zIndex: 1402 }}
    >
      <CardHeadRoot>
        Manage groups
        <Button
          onClick={handleCreateCategory}
          startIcon={<AddRounded />}
          variant="contained"
          color="secondary"
          id="ManageGroups-new_group"
          sx={{ ml: 'auto' }}
        >
          New Group
        </Button>
      </CardHeadRoot>
      <DialogContent>
        <Stack id="ManageGroups-list" gap={2}>
          {sortArrayByProperty(visualCategories, 'name').map((cat) => (
            <Card key={cat.id} category={cat} />
          ))}
        </Stack>
        {isCreateRenameOpen ? (
          <CreateRenameGroup
            open={isCreateRenameOpen}
            onClose={handleCloseModal}
          />
        ) : null}
      </DialogContent>
    </DialogComponent>
  );
};

export default ManageGroups;
