import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AlertUrgentIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        id="circle"
        d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z"
        transform="translate(4 4)"
        fill="#fff"
      />
      <path
        id="Background"
        d="M0,0H24V24H0Z"
        fill="rgba(255,255,255,0)"
        opacity="0"
      />
      <path
        id="Path_171"
        data-name="Path 171"
        d="M-12466-14396a8.009,8.009,0,0,1-8-8,8.01,8.01,0,0,1,8-8,8.008,8.008,0,0,1,8,8A8.008,8.008,0,0,1-12466-14396Zm1.461-5.671a.825.825,0,0,0-.584.247.814.814,0,0,0-.237.585.822.822,0,0,0,.238.589.812.812,0,0,0,.583.246.807.807,0,0,0,.582-.245.811.811,0,0,0,.239-.586.831.831,0,0,0-.821-.836Zm-3,0a.813.813,0,0,0-.583.243.818.818,0,0,0-.238.585.821.821,0,0,0,.239.593.8.8,0,0,0,.571.242h.011a.817.817,0,0,0,.582-.245.823.823,0,0,0,.235-.59.815.815,0,0,0-.236-.582A.813.813,0,0,0-12467.539-14401.666Zm3-6.337a.662.662,0,0,0-.463.2.663.663,0,0,0-.191.467v4a.655.655,0,0,0,.189.47.649.649,0,0,0,.465.2.664.664,0,0,0,.655-.669v-4a.646.646,0,0,0-.188-.467A.659.659,0,0,0-12464.541-14408Zm-2.994,0a.661.661,0,0,0-.471.2.659.659,0,0,0-.188.47v4a.666.666,0,0,0,.188.474.646.646,0,0,0,.459.2h.012a.649.649,0,0,0,.465-.2.658.658,0,0,0,.189-.47v-4a.654.654,0,0,0-.188-.47A.655.655,0,0,0-12467.535-14408Z"
        transform="translate(12478 14416)"
        fill="#f05452"
      />
    </SvgIcon>
  );
}

export default AlertUrgentIcon;
