import React from 'react';
import { SettingsOutlined } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';

interface Props {
  handleEditProfileOpen: () => void;
}

const EditProfileButton = (props: Props) => {
  const { handleEditProfileOpen } = props;

  return (
    <MenuItem
      id="UserMenu-edit_profile_button"
      sx={{ p: '6px' }}
      onClick={handleEditProfileOpen}
    >
      <ListItemIcon>
        <SettingsOutlined />
      </ListItemIcon>
      Edit profile
    </MenuItem>
  );
};

export default EditProfileButton;
