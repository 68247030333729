import { rootApi } from '..';
import { atreusApiUrl } from '../../../env';
import { DashboardLayout, Thresholds } from '../../../hooks/usePreferences';
import { IProject } from '../../../typescript/interfaces/project.interface';

export interface Preference {
  presets?: {
    [key: string]: any;
  };
  quickAccess?: string[];
  systemStatus?: string[];
  activeSites?: string[];
  thresholds?: Thresholds;
  dashboardLayout?: DashboardLayout;
}

export interface PreferenceResponse {
  preferences: Preference;
}

const BASE_URL = `${atreusApiUrl()}/preferences`;

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreferences: builder.query<PreferenceResponse, void>({
      query: () => `${BASE_URL}`,
      providesTags: ['Preferences'],
    }),
    createPreferences: builder.mutation<void, any>({
      query: (data: IProject) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Preferences'],
    }),
    patchPreferences: builder.mutation<void, any>({
      query: (data: IProject) => ({
        url: `${BASE_URL}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Preferences'],
    }),
    deletePreferences: builder.mutation<void, any>({
      query: (data: IProject) => ({
        url: `${BASE_URL}`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Preferences'],
    }),
  }),
});

export const {
  useGetPreferencesQuery,
  useCreatePreferencesMutation,
  usePatchPreferencesMutation,
  useDeletePreferencesMutation,
} = extendedApi;
