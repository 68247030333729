import React from 'react';
import BaseFlair from './BaseFlair';

interface Props {
  className?: string;
  data?: any;
  selected?: boolean;
}

const defaultMinHeight = 10;
const defaultMinWidth = 10;

const VisualViewController = (props: Props) => {
  const { className, data, selected } = props;

  return (
    <BaseFlair
      className={className}
      data={data}
      selected={selected}
      resizeRatio={1}
      lockAspectRatio
      defaultMinWidth={defaultMinWidth}
      defaultMinHeight={defaultMinHeight}
      content={
        <svg
          key={`wrapper_${selected}`}
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: '100%', height: '100%' }}
        >
          <rect width="100%" height="100%" x="0" y="0" fill="#4c9fc8" />
          <rect width="80%" height="80%" x="10%" y="10%" fill="#ffffff" />
        </svg>
      }
    />
  );
};

VisualViewController.defaultProps = {
  className: '',
  data: {},
  selected: false,
};

export default VisualViewController;
