const data = [
  {
    _id: '1',
    action: 'Run started',
    target: 'Buffer Blend - Carson',
    user: 'manager@paracloud.ai',
    date: '2021-11-05T14:00:17.675Z',
    isSeen: false,
  },
  {
    _id: '2',
    action: 'Run completed',
    target: 'Buffer Blend - Carson',
    date: '2021-11-05T15:00:17.675Z',
    isSeen: false,
  },
  {
    _id: '3',
    action: 'Alarm',
    target: 'Buffer Blend - Tallinn',
    date: '2021-11-05T15:00:17.675Z',
    isSeen: false,
  },
];

export default data;
