import {
  Box,
  FormControl,
  IconButton,
  Typography,
  styled,
  Stack,
  alpha,
} from '@mui/material';

export const Root = styled(Stack)(({ theme }) => ({
  height: 'calc(100% - 60px)',
  '&.loaded': {
    minHeight: '280px',
  },
  '& .search': {
    maxWidth: '480px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
}));

export const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(3),
  paddingBottom: 0,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    padding: `0 ${theme.spacing(2)}`,
  },
}));

export const HeadLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
  },
}));

export const HeadRight = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const Title = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontSize: '2rem',
  fontWeight: 600,
  marginRight: theme.spacing(2),
}));

export const SelectControl = styled(FormControl)(({ theme }) => ({
  minWidth: 150,
  marginBottom: 0,
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    minWidth: 84,
    marginRight: theme.spacing(1),
  },
}));

export const ContentWrap = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  padding: `0 ${theme.spacing(3)}`,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    border: 0,
    borderRadius: 0,
    padding: '0',
    marginBottom: 0,
  },
}));

export const ToggleBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: '1px solid transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&.active': {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    borderColor:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },
}));
