import { atreusApiUrl } from '../../../env';
import { rootApi } from '..';
import {
  IPasswordSettings,
  PasswordSettingsPayload,
  V010Identity,
} from '../users/types';

const BASE_URL = atreusApiUrl();

interface RequestProps {
  url: string;
  method: string;
  headers?: {
    [key: string]: string;
  };
}

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<V010Identity, string>({
      query: (accessToken: string) => {
        const requestParams: RequestProps = {
          url: `${BASE_URL}/me`,
          method: 'GET',
          headers: { Authorization: `Bearer ${accessToken}` },
        };

        return requestParams;
      },
      providesTags: ['CurrentUser'],
    }),
    getPasswordSettings: builder.query<IPasswordSettings, void>({
      query: () => ({
        url: `${BASE_URL}/settings`,
        method: 'GET',
      }),
      providesTags: ['PasswordSettings'],
    }),
    updatePasswordSettings: builder.mutation<
      IPasswordSettings,
      PasswordSettingsPayload
    >({
      query: (body) => ({
        url: `${BASE_URL}/settings`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PasswordSettings'],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useUpdatePasswordSettingsMutation,
  useGetPasswordSettingsQuery,
} = extendedApi;
