import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { RemoveCircleOutlineRounded } from '@mui/icons-material';
import { StatusIcon } from '../../../common/styles/system';
import RunningLoader from '../../../common/RunningLoader';
import { useGetSystemsQuery } from '../../../redux/services/systems/api';
import { ISystem } from '../../../typescript/interfaces/system.interface';

const CurrentStateIcon = ({
  title,
  system,
}: {
  title: string;
  system: ISystem;
}) => {
  if (title === 'Running')
    return <RunningLoader size={20} variant="indeterminate" value={25} />;
  if (title !== 'Running' && system?.display)
    return <RunningLoader size={20} variant="determinate" value={100} />;
  if (system?.machineState === 'Online')
    return <RunningLoader size={20} variant="determinate" value={100} />;
  return (
    <RemoveCircleOutlineRounded
      sx={{
        color: 'text.disabled',
        fontSize: '1.7rem',
        width: '100%',
        height: '100%',
      }}
    />
  );
};

const SystemStatusIconSubscription = ({
  id,
  pollingInterval = 10000,
  block = false,
  style,
}: {
  id: string;
  pollingInterval: number;
  block: boolean;
  style: any;
}) => {
  const { data: system } = useGetSystemsQuery(undefined, {
    selectFromResult: ({ data }) => ({ data: data?.find((s) => s.id === id) }),
  });

  const title = useMemo(() => {
    if (system?.state > 0) return 'Running';
    if (system?.display) return system.display;
    if (system?.machineState) return system.machineState;
    return 'Loading';
  }, [system]);

  return (
    <Tooltip title={title}>
      <StatusIcon
        role="img"
        style={
          block
            ? {
                width: '24px',
                height: '24px',
                position: 'relative',
                top: style?.top || '12px',
                left: style?.left || '6px',
              }
            : {
                width: '24px',
                height: '24px',
                position: 'absolute',
                top: '50%',
                right: '0.75rem',
              }
        }
      >
        <CurrentStateIcon system={system} title={title} />
      </StatusIcon>
    </Tooltip>
  );
};

export default SystemStatusIconSubscription;
