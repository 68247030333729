import React, { useEffect, useState, memo, useMemo } from 'react';
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Tooltip as MuiTooltip,
} from '@mui/material';
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  BrokenImageRounded,
} from '@mui/icons-material';
import DialogComponent from '../../../common/Dialogs/DialogComponent';
import {
  SubTitle,
  TinyPreviewWrap,
  Tooltip,
  TransferCardWrap,
  TransferList,
  TransferModalContent,
} from './styles';
import Search from '../../Search';
import { VisualCategoryResponse } from '../../../redux/services/visualCategory/types';
import { useGetVisualsQuery } from '../../../redux/services/visuals/api';
import { VisualResponse } from '../../../redux/services/visuals/types';
import PreviewItemComponent from './PreviewItemComponent';
import UpdateCategoryComponent from './UpdateCategoryComponent';

interface TooltipProps {
  name: string;
  visual: VisualResponse;
}

const TooltipContent = (props: TooltipProps) => {
  const { name, visual } = props;

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          width: 100,
          height: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2,
          mx: 'auto',
        }}
      >
        {<PreviewItemComponent visual={visual} /> || (
          <BrokenImageRounded
            sx={{
              color: 'secondary.main',
              opacity: 0.75,
              fontSize: '3rem',
            }}
          />
        )}
      </Box>
      <Typography sx={{ color: '#484848' }}>{name}</Typography>
    </Box>
  );
};

interface TwoSidesListProps {
  items: VisualResponse[];
  handleToggle: (id: string) => void;
  checkedItems: VisualResponse[];
  type: string;
}

const CustomList = (props: TwoSidesListProps) => {
  const { items, checkedItems, handleToggle, type } = props;

  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const itemsFiltered = useMemo(() => {
    if (!searchValue) return items;
    return items.filter(
      ({ name }) => name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
    );
  }, [items, searchValue]);

  return (
    <TransferCardWrap>
      <Box>
        <Search
          placeholder="Search"
          defaultExpanded
          id={`GroupComponents-${type}-search`}
          value={searchValue}
          onSearch={handleSearchValueChange}
        />
      </Box>
      <TransferList
        dense
        id={`GroupComponents-${type}-list`}
        sx={{ flex: 1, overflow: 'auto' }}
      >
        {itemsFiltered.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              onClick={() => handleToggle(value.id)}
              selected={checkedItems.some(({ id }) => value.id === id)}
              id={`GroupComponents-${type}-${value.name.replace(/ /g, '_')}`}
              sx={{ px: 1 }}
            >
              <Tooltip
                title={<TooltipContent visual={value} name={value.name} />}
                placement="left"
              >
                <ListItemIcon>
                  <TinyPreviewWrap>
                    <PreviewItemComponent visual={value} />
                  </TinyPreviewWrap>
                </ListItemIcon>
              </Tooltip>
              <ListItemText id={labelId} secondary={value.name} />
            </ListItemButton>
          );
        })}
      </TransferList>
    </TransferCardWrap>
  );
};

interface Props {
  category: VisualCategoryResponse;
  open: boolean;
  onClose: () => void;
}

const GroupComponents = (props: Props) => {
  const { open, onClose, category } = props;

  const { data: visuals = [] } = useGetVisualsQuery(undefined, {
    selectFromResult: (res) => ({
      data: res?.data?.filter(
        (v) => v.category === null || v.category === category.name,
      ),
      error: res?.error,
    }),
  });

  const [checked, setChecked] = useState<VisualResponse[]>([]);
  const [left, setLeft] = useState<VisualResponse[]>([]);
  const [right, setRight] = useState<VisualResponse[]>([]);
  const [isSaveStarted, setIsSaveStarted] = useState<boolean>(false);

  const initialList = useMemo(() => {
    if (!visuals.length) return [];
    return visuals
      .filter((v) => v.category === category.name)
      .map(({ id }) => id);
  }, [visuals]);

  useEffect(() => {
    if (!visuals) return;
    setLeft(visuals.filter((v) => v.category === null));
    setRight(visuals.filter((v) => v.category === category.name));
  }, [visuals?.length]);

  const handleToggle = (value: string) => {
    setChecked((prev) => {
      const newState = [...prev];
      if (prev.some((v) => v.id === value)) {
        return newState.filter((v) => v.id !== value);
      }
      if (!prev.some((v) => v.id === value)) {
        return [...newState, visuals.find((v) => v.id === value)];
      }
      return prev;
    });
  };

  const moveSelected = ({
    selected,
    setFrom,
    setTo,
  }: {
    selected: VisualResponse[];
    setFrom: React.Dispatch<React.SetStateAction<VisualResponse[]>>;
    setTo: React.Dispatch<React.SetStateAction<VisualResponse[]>>;
  }) => {
    setFrom((prev) =>
      prev.filter((v) => !selected.find((sel) => sel.id === v.id)),
    );
    setTo((prev) => [
      ...prev,
      ...selected.filter((sel) => !prev.find((v) => sel.id === v.id)),
    ]);
  };

  const handleCheckedRight = () => {
    moveSelected({
      selected: checked,
      setFrom: setLeft,
      setTo: setRight,
    });
    setChecked([]);
  };

  const handleCheckedLeft = () => {
    moveSelected({
      selected: checked,
      setFrom: setRight,
      setTo: setLeft,
    });
    setChecked([]);
  };

  const handleAction = () => {
    setIsSaveStarted(true);
  };

  const handleCloseUpdate = () => {
    setIsSaveStarted(false);
    onClose();
  };

  return (
    <DialogComponent
      isOpened={open}
      closeModal={onClose}
      title={`${category.name} group components`}
      actionTitle="Save"
      id="GroupComponents-dialog"
      actionId="GroupComponents-save"
      cancelId="GroupComponents-cancel"
      handleAction={handleAction}
      sx={{ zIndex: 1402, '& .MuiDialog-paper': { maxWidth: 720 } }}
      isActionButtonDisabled={
        JSON.stringify(initialList) === JSON.stringify(right)
      }
    >
      {isSaveStarted && (
        <UpdateCategoryComponent
          initialList={initialList}
          elementIds={right.map(({ id }) => id)}
          categoryName={category.name}
          handleCloseUpdate={handleCloseUpdate}
        />
      )}
      <TransferModalContent>
        <Stack sx={{ flex: 1 }}>
          <SubTitle>Uncategorized ({left.length})</SubTitle>
          <CustomList
            items={left}
            handleToggle={handleToggle}
            checkedItems={checked}
            type="Uncategorized"
          />
        </Stack>
        <Stack sx={{ gap: 1, justifyContent: 'center' }}>
          <MuiTooltip title={`Move to ${category.name}`}>
            <IconButton
              className="outlined"
              color="secondary"
              onClick={handleCheckedRight}
              disabled={
                !checked.some((sel) => left.some((v) => v.id === sel.id))
              }
              aria-label="move to group"
              id={`GroupComponents-move_to_${category.name}`}
            >
              <ArrowForwardRounded />
            </IconButton>
          </MuiTooltip>
          <MuiTooltip title="Move to uncategorized">
            <IconButton
              className="outlined"
              color="secondary"
              onClick={handleCheckedLeft}
              disabled={
                !checked.some((sel) => right.some((v) => v.id === sel.id))
              }
              aria-label="move to uncategorized"
              id="GroupComponents-move_to_uncategorized"
            >
              <ArrowBackRounded />
            </IconButton>
          </MuiTooltip>
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <SubTitle>
            Components in {category.name} ({right.length})
          </SubTitle>
          <CustomList
            items={right}
            handleToggle={handleToggle}
            checkedItems={checked}
            type="GroupVisuals"
          />
        </Stack>
      </TransferModalContent>
    </DialogComponent>
  );
};

export default memo(GroupComponents);
