import React from 'react';
import { alpha, createTheme } from '@mui/material/styles';
import {
  CloseRounded,
  ErrorOutline,
  ExpandMoreRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material';
import { CheckboxIcon, CheckedIcon } from './icons';

declare module '@mui/material/styles' {
  interface Palette {
    offline: Palette['primary'];
    gradient: Palette['primary'];
    bg: Palette['primary'];
    backgroundSecondary: Palette['primary'];
  }
  interface PaletteOptions {
    offline: PaletteOptions['primary'];
    gradient: PaletteOptions['primary'];
    bg: PaletteOptions['primary'];
    backgroundSecondary: PaletteOptions['primary'];
  }

  interface PaletteColor {
    text?: string;
  }
  interface SimplePaletteColorOptions {
    text?: string;
  }
}

const color = {
  primary: '#702F8A',
  secondary: '#4C9FC8',
  info: '#4C9FC8',
  error: '#C51C1C',
  warning: '#F59E3B',
  warningDark: '#bc7622',
  success: '#10B981',
  divider: '#EBF4F9',
};

const radius = 5;

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: color.primary,
      dark: color.primary,
      text: '#111111',
    },
    secondary: {
      main: color.secondary,
      dark: color.secondary,
      contrastText: '#fff',
    },
    error: {
      main: color.error,
      dark: color.error,
    },
    warning: {
      main: color.warning,
      dark: color.warningDark,
    },
    info: {
      light: color.info,
      main: color.info,
      dark: color.info,
    },
    success: {
      main: color.success,
      dark: color.success,
      contrastText: '#fff',
    },
    text: {
      primary: '#111111',
      secondary: '#484848',
      disabled: '#A5B0BF',
    },
    offline: {
      main: '#707070',
      dark: '#606060',
    },
    background: {
      default: '#fcfdfe',
      paper: '#fff',
    },
    gradient: {
      main: '#fcfdfe',
      light: '#f3f8fb',
    },
    backgroundSecondary: {
      main: alpha(color.secondary, 0.15),
    },
    bg: {
      main: 'rgba(255, 255, 255, 1)',
      dark: 'rgba(112, 47, 138, 0.15)',
      light: '#fff',
    },
    divider: color.divider,
  },
  shape: {
    borderRadius: radius,
  },
  spacing: (factor) => [0, '0.5rem', '1rem', '1.5rem', '2rem', '3rem'][factor],
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      color: '#111111',
    },
    subtitle1: {
      fontSize: '1.5rem',
      color: '#111111',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '1.125rem',
      color: '#111111',
      fontWeight: 600,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflow: 'hidden',
          backgroundColor: '#fcfdfe',
          '& .react-flow .react-flow__controls': {
            backgroundColor: '#fcfdfe',
            borderRadius: radius,
          },
          '& .react-flow .react-flow__controls-button': {
            backgroundColor: '#fff',
            borderColor: color.divider,
          },
          '& .leaflet-left .leaflet-control': {
            backgroundClip: 'inherit',
            border: `1px solid ${color.divider}`,
            backgroundColor: '#fcfdfe',
          },
          '& .leaflet-bar a': {
            borderColor: color.divider,
            backgroundColor: '#fcfdfe',
            color: '#484848',
          },
          '& .fullscreen.fullscreen-enabled': {
            '@media(min-width: 1536px)': {
              '& .react-flow .react-flow__controls-button': {
                width: '4.5rem !important',
                height: '4.5rem !important',
                '& svg': {
                  fontSize: '30px',
                },
                '& .fullscreen-icon': {
                  fontSize: '36px',
                },
              },
            },
            '@media(max-width:900px)': {
              '& .MuiSnackbar-root': {
                bottom: 12,
              },
            },
          },
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#d8e1e5 !important',
          border: '2px solid #d8e1e5 !important',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '3rem',
          padding: 0,
          '&.Mui-expanded': {
            minHeight: '3rem',
          },
        },
        content: {
          margin: '0',
          '&.Mui-expanded': {
            margin: '0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '3rem',
          height: '3rem',
          border: `1px solid ${color.divider}`,
          textDecoration: 'none',
        },
        colorDefault: {
          color: '#748297',
          backgroundColor: '#F7FAFC',
          borderColor: color.divider,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMoreRounded />,
        ChipProps: {
          size: 'small',
          color: 'primary',
          deleteIcon: <CloseRounded />,
        },
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            paddingTop: '2px',
            paddingBottom: '2px',
            borderRadius: radius,
            height: 'auto',
            '& .MuiAutocomplete-endAdornment': {
              right: '6px',
            },
          },
          '& .MuiAutocomplete-popupIndicator': {
            width: '28px',
            height: '28px',
            color: '#748297',
          },
          '& .MuiAutocomplete-clearIndicator': {
            width: '28px',
            height: '28px',
          },
          '& .MuiAutocomplete-input': {
            height: '1rem',
            paddingTop: '9.5px !important',
            paddingBottom: '9.5px !important',
            color: '#111',
          },
        },
        paper: {
          borderRadius: radius,
          boxShadow: '0px 6px 16px rgba(74, 85, 104, 0.08)',
          border: `1px solid ${color.divider}`,
        },
        popper: {
          zIndex: 9999,
        },
        listbox: {
          padding: '6px',
        },
        option: {
          borderRadius: radius,
          paddingLeft: '12px !important',
          '&:hover': {
            backgroundColor: '#F7FAFC !important',
          },
          '& .MuiCheckbox-root': {
            width: '28px',
            height: '28px',
            marginRight: 8,
          },
          '&[aria-selected="true"]': {
            backgroundColor: '#fff !important',
            '&.Mui-focused': {
              backgroundColor: '#F7FAFC !important',
            },
          },
          '&.Mui-focused': {
            backgroundColor: '#F7FAFC',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(39, 42, 68, 0.5)',
        },
        invisible: {
          opacity: '0 !important',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        anchorOriginTopRightRectangular: {
          transform: 'scale(1) translate(32%, -32%)',
          width: '20px',
          height: '20px',
          minWidth: '20px',
          border: '2px solid #334254',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: radius,
          fontSize: '1.125rem',
          minHeight: '36px',
          padding: '4px 16px',
          minWidth: 'auto',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          fontWeight: 600,
          '&:hover': {
            boxShadow: 'none !important',
          },
        },
        contained: {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
          transition: 'all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1)',
          color: '#fff',
          '&:hover': {
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16)',
            backgroundPosition: 'right center',
          },
          '& svg': {
            color: '#fff',
          },
          '&.MuiButton-containedSuccess': {
            background: `linear-gradient(to right, #2dbf87 0%, #18a871 100%)`,
            '&:hover': {
              background: `linear-gradient(to right, #31d093 0%, #18a871 100%)`,
            },
          },
          '&.MuiButton-containedError': {
            background: `linear-gradient(to right, #f55b59 0%, #d53a38 100%)`,
            '&:hover': {
              background: `linear-gradient(to right, #fc7b79 0%, #d53a38 100%)`,
            },
          },
          '&.Mui-disabled': {
            background: '#c9c9c9',
            color: '#fff',
            '&:hover': {
              background: '#c9c9c9',
            },
          },
        },
        containedPrimary: {
          // boxShadow: 'none',
          background: `linear-gradient(to right, #9c4dbc 0%, ${color.primary} 100%)`,
          '&:hover': {
            // boxShadow: 'none',
            background: `linear-gradient(to right, #6C3283 0%, #6C3283 100%)`,
          },
        },
        containedSecondary: {
          background: `linear-gradient(to right, #68bbe6 0%, #4C9FC8 100%)`,
          '&:hover': {
            // boxShadow: 'none',
            background: `linear-gradient(to right, #4B95BB 0%, #4B95BB 100%)`,
          },
        },
        outlinedPrimary: {
          borderColor: '#823B9F',
          color: '#823B9F',
          '&:hover': {
            color: '#fff',
            background: `linear-gradient(to right, #9c4dbc 0%, ${color.primary} 100%)`,
          },
        },
        outlinedSecondary: {
          '&:hover': {
            color: '#fff',
            background: `linear-gradient(to right, #68bbe6 0%, #4C9FC8 100%)`,
          },
        },
        outlinedInherit: {
          borderColor: color.divider,
          color: color.primary,
          '&:hover': {
            borderColor: color.primary,
            backgroundColor: alpha(color.primary, 0.1),
          },
        },
        textPrimary: {
          backgroundColor: 'rgba(112, 47, 138, .15)',
          color: 'rgb(112, 47, 138)',
          fontWeight: 600,
          '&:hover': {
            backgroundColor: 'rgba(112, 47, 138, .15)',
          },
        },
        sizeSmall: {
          minHeight: '2rem',
        },
        sizeLarge: {
          fontSize: '1.5rem',
          minHeight: '3.5rem',
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <KeyboardArrowRightRounded fontSize="small" />,
      },
      styleOverrides: {
        root: {
          color: '#111111',
          fontSize: '1.125rem',
          fontWeight: 600,
          '& p': {
            color: '#111111',
            fontSize: '1.125rem',
            fontWeight: 600,
          },
          '& a': {
            color: '#111111',
            fontSize: '1.125rem',
            fontWeight: 600,
            display: 'flex',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          '& span': {
            fontWeight: 600,
          },
        },
        separator: {
          color: 'rgba(76, 159, 200, 0.6)',
          marginLeft: 4,
          marginRight: 4,
          '& svg': {
            color: 'rgba(76, 159, 200, 0.6)',
            fontSize: '1.5rem',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckedIcon />,
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          padding: 0,
          width: '2rem',
          height: '2rem',
          '& svg': {
            color: 'currentColor',
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseRounded />,
      },
      styleOverrides: {
        root: {
          height: '2.25rem',
          borderRadius: radius,
          '& .MuiChip-avatar': {
            marginLeft: '0 !important',
            borderRadius: radius,
            height: '2.1rem',
            width: '2.1rem',
          },
        },
        sizeSmall: {
          height: '1.75rem',
          fontSize: '1rem',
        },
        outlinedSecondary: {
          backgroundColor: alpha(color.secondary, 0.06),
        },
        label: {
          fontSize: '1rem',
          paddingLeft: '6px',
          paddingRight: '6px',
          fontWeight: 600,
        },
        labelSmall: {
          fontSize: '0.9rem',
        },
        deleteIcon: {
          width: '1.25rem',
          height: '1.25rem',
        },
        deleteIconSmall: {
          width: '1.125rem',
          height: '1.125rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1300,
        },
        paper: {
          margin: '1.75rem',
          boxShadow: 'none',
          borderRadius: radius,
        },
        paperFullScreen: {
          width: '100% !important',
          height: '100%',
          margin: 0,
          borderRadius: 0,
          '& .MuiDialogActions-root > button': {
            flex: 1,
          },
          '& .MuiDialogActions-root > a': {
            flex: 1,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1rem 2rem',
          '@media(max-width: 600px)': {
            padding: '1rem 1.5rem',
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '2rem 2rem 1rem 2rem',
          fontSize: '1.75rem',
          fontWeight: 600,
          color: '#484848',
          '@media(max-width: 600px)': {
            padding: '1.5rem 1.5rem 1rem 1.5rem',
            fontSize: '1.5rem',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1rem 2rem 2rem 2rem',
          '@media(max-width: 600px)': {
            padding: '1rem 1.5rem 1.5rem 1.5rem',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: color.divider,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '1.5rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: '#484848',
          fontSize: '1.125rem',
        },
        label: {
          color: '#484848',
          fontSize: '1.125rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#484848',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#A5B0BF',
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#A5B0BF',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderRadius: radius,
          padding: 0,
          width: '36px',
          height: '36px',
          color: '#484848',
          '&.Mui-disabled': {
            color: '#CBD5E0',
          },
          '&:hover': {
            backgroundColor: 'rgba(76, 159, 200, .02)',
            '@media(max-width: 900px)': {
              backgroundColor: '#fff',
            },
          },
          '& .MuiSvgIcon-root': {
            color: 'currentColor',
            fontSize: 'inherit',
          },
          '&.outlined': {
            border: `1px solid ${color.divider}`,
            backgroundColor: '#fff',
          },
        },
        colorSecondary: {
          color: color.secondary,
        },
        sizeSmall: {
          fontSize: '1.25rem',
          width: 24,
          height: 24,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          lineHeight: '1.75rem',
          '&.Mui-disabled': {
            backgroundColor: '#f4f4f4',
            '&:hover': {
              borderColor: color.divider,
            },
          },
          '&:hover': {
            borderColor: '#CBD5E0',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#484848',
          fontSize: '1.125rem',
          fontWeight: 400,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          '& .light': {
            color: '#A5B0BF',
          },
        },
        shrink: {
          transform: 'translate(0, -2px) scale(1)',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          padding: '6px',
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: radius,
        },
        button: {
          '&:hover': {
            backgroundColor: '#FAFBFC',
          },
        },
        gutters: {
          paddingLeft: '6px',
          paddingRight: '6px',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: '#484848',
          textTransform: 'uppercase',
          fontSize: '1.125rem',
          lineHeight: '32px',
          outline: 0,
          fontWeight: 600,
        },
        gutters: {
          paddingLeft: '6px',
          paddingRight: '6px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 28,
          color: 'currentColor',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '1.23rem',
          color: '#484848',
        },
        secondary: {
          fontSize: '1.07rem',
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: radius,
          boxShadow: '0px 6px 16px rgba(74, 85, 104, 0.08)',
          border: `1px solid ${color.divider}`,
          transition: 'none !important',
          '& .MuiMenuItem-root': {
            fontSize: '1.125rem',
          },
          '& .MuiListItemText-primmary': {
            fontSize: '1.125rem',
          },
          '& .MuiListItemText-secondary': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: '#484848',
          minHeight: '1.5rem',
          fontSize: '1.125rem',
          display: 'flex !important',
          borderRadius: radius,
          '&:hover': {
            backgroundColor: '#F7FAFC',
          },
          '&.Mui-selected': {
            backgroundColor: '#EDF2F7',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#EDF2F7',
          },
          '& .MuiListItemIcon-root ': {
            minWidth: 28,
          },
        },
      },
    },
    // MuiPickersArrowSwitcher: {
    //   styleOverrides: {
    //     spacer: {
    //       width: '0.5rem',
    //     },
    //   },
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        adornedEnd: {
          paddingRight: 4,
        },
        root: {
          borderRadius: radius,
          fontWeight: 400,
          height: '36px',
          backgroundColor: '#fff',
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#CBD5E0',
            },
            '&.Mui-disabled': {
              // opacity: 0.75,
              '& $notchedOutline': {
                borderColor: color.divider,
              },
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#C1CFE0 !important',
              borderWidth: '1px !important',
            },
          },
          '& legend': {
            fontSize: '1.13rem',
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.divider,
            },
          },
        },
        input: {
          paddingTop: '0.9rem !important',
          paddingBottom: '0.9rem !important',
          fontSize: '1.125rem',
          borderRadius: radius,
          color: '#484848',
          fontWeight: 400,
          '&.Mui-disabled': {
            color: '#748297',
          },
          '&::placeholder': {
            color: '#A5B0BF',
            opacity: '1',
            fontWeight: 400,
          },
        },
        multiline: {
          paddingTop: '1.125rem',
          paddingBottom: '1.125rem',
        },
        notchedOutline: {
          borderColor: color.divider,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 99999999,
        },
        paper: {
          boxShadow: '0px 6px 16px rgba(74, 85, 104, 0.08)',
          border: `1px solid ${color.divider}`,
          borderRadius: radius,
          marginTop: '0.75rem',
        },
      },
    },
    MuiRipple: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreRounded,
        size: 'small',
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0,
          },
        },
        select: {
          lineHeight: '1',
          minHeight: '0.5rem',
          '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: '38px',
            marginLeft: '-38px',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
          '&.Mui-outlined': {
            paddingRight: '48px !important',
          },
          '& svg': {
            right: '0.125rem',
          },
        },

        icon: {
          right: '0.84rem',
          top: '50%',
          transform: 'translateY(-50%)',
          fontSize: '1.5rem',
          color: '#748297',
        },
        iconOpen: {
          transform: 'translateY(-50%) rotate(180deg)',
        },
        iconOutlined: {
          right: '0.5rem',
          fontSize: '1.5rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: 'black',
        },
        message: {
          marginLeft: '2rem',
          marginRight: '2rem',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {},
      styleOverrides: {
        root: {
          width: 32,
          height: 20,
          padding: 0,
          display: 'flex',
          overflow: 'visible',
        },
        switchBase: {
          padding: 2,
          color: '#FFFFFF',
          width: 'auto',
          height: 'auto',
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff !important',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#4C9FC8 !important',
              borderColor: '#4C9FC8 !important',
            },
          },
        },
        thumb: {
          width: 16,
          height: 16,
          boxShadow: 'none',
        },
        track: {
          border: `1px solid ${color.divider}`,
          borderRadius: 20 / 2,
          opacity: 1,
          backgroundColor: color.divider,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
      },
      styleOverrides: {
        root: {
          minHeight: '2.8rem',
          borderBottom: `1px solid ${color.divider}`,
        },
        indicator: {
          borderRadius: '3px 3px 0px 0px',
        },
        scroller: {
          '&::-webkit-scrollbar': {
            display: 'inherit !important',
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          minWidth: '3rem !important',
          fontSize: '0.88rem !important',
          minHeight: '2.8rem',
        },
        textColorInherit: {
          color: '#748297',
          opacity: 1,
          fontWeight: 400,
          '&$selected': {
            color: '#484848',
            fontWeight: 600,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: color.divider,
          color: '#484848',
          fontSize: '1.125rem',
          paddingTop: 8,
          paddingBottom: 8,
        },
        head: {
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          fontSize: '.9rem',
          backgroundColor: '#0e1724',
          borderRadius: radius,
        },
        popper: {
          zIndex: 999999991,
          '&[data-popper-placement*="bottom"] .MuiTooltip-arrow': {
            marginTop: '-0.66em',
          },
          '&[data-popper-placement*="top"] .MuiTooltip-arrow': {
            marginBottom: '-0.66em',
          },
          '&[data-popper-placement*="left"] .MuiTooltip-arrow': {
            marginRight: '-0.66em',
          },
          '&[data-popper-placement*="right"] .MuiTooltip-arrow': {
            marginLeft: '-0.66em',
          },
        },
        arrow: {
          zIndex: -9,
          '&:before': {
            color: '#0e1724',
            borderRadius: '1.5px',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontSize: '1rem',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: '0.92rem',
          backgroundColor: '#fff',
          '&.Mui-selected': {
            backgroundColor: '#4C9FC8 !important',
          },
        },
        today: {
          '&:not(.Mui-selected)': {
            borderColor: color.secondary,
          },
        },
      },
    },
    PrivatePickersYear: {
      styleOverrides: {
        button: {
          '&.Mui-selected': {
            backgroundColor: '#4C9FC8 !important',
          },
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        pin: {
          backgroundColor: color.secondary,
        },
        clock: {
          backgroundColor: '#fcfdfe',
          border: '1px solid #EBF4F9',
        },
      },
    },
    MuiClockPointer: {
      styleOverrides: {
        root: {
          backgroundColor: color.secondary,
        },
        thumb: {
          backgroundColor: color.secondary,
          borderColor: color.secondary,
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          warning: <ErrorOutline />,
        },
      },
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          padding: '2px 16px',
          border: '1px solid rgba(0, 0, 0, 0.05)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '.MuiAlert-message': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
          },
        },
        standardWarning: {
          backgroundColor: alpha(color.warning, 0.1),
          color: '#A56921',

          '.MuiAlert-icon': {
            color: '#A56921',
          },

          button: {
            backgroundColor: 'transparent',
            color: '#A56920',
            border: '1px solid',
            borderColor: '#A56920',
            height: '27px',
            padding: '4px, 16px, 4px, 16px',
            fontSize: '13px',
          },
        },
        standardError: {
          backgroundColor: alpha(color.error, 0.1),
          color: color.error,
          border: `1px solid ${color.error}`,

          button: {
            backgroundColor: 'transparent',
            color: color.error,
          },
        },
        action: {
          paddingTop: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: color.primary,
        },
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        content: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 6px 16px rgba(74, 85, 104, 0.08)',
          border: `1px solid ${color.divider}`,
          borderRadius: radius,
          backgroundColor: '#fff',
        },
      },
    },
  },
});

export default theme;
