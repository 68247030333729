import { LoadingButton } from '@mui/lab';
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogContent,
  Select,
  Autocomplete,
  styled,
  IconButton,
  Typography,
  ListItem,
} from '@mui/material';
import { Flex } from '../styles';

export const Modal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '480px',
    },
  },
}));

export const ModalContent = styled(DialogContent)(({ theme }) => ({
  overflowX: 'hidden',

  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
}));

export const CancelBtn = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '50%',
    '&.fullscreen-disabled': {
      width: 'auto',
    },
  },
}));

export const SaveBtn = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '50%',
    '&.fullscreen-disabled': {
      width: 'auto',
    },
  },
}));

export const LoadingBtn = styled(LoadingButton)(({ theme }) => ({
  '&.MuiLoadingButton-loading': {
    paddingLeft: 36,
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  marginTop: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  '& .MuiOutlinedInput-input .MuiIconButton-root': {
    display: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline legend': {
    display: 'none',
  },
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  marginTop: theme.spacing(4),
  '& .MuiTextField-root': {
    marginBottom: 0,
  },
  '& .MuiAutocomplete-paper': {
    maxHeight: '200px',
  },
}));

export const DropPlaceholder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.background.default, 0.75),
  zIndex: 99,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const UploadWrap = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: '0.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  fontSize: '1.25rem',
  cursor: 'pointer',
  '& svg': {
    marginRight: theme.spacing(1),
  },
  '&:hover': {
    borderColor:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : alpha(theme.palette.text.primary, 0.37),
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.primary.main, 0.07)
        : alpha(theme.palette.text.primary, 0.15),
  },
}));

export const PlaceholderWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  border: '4px solid #748297',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#4A5568',
  boxSizing: 'border-box',
}));

export const RightContent = styled(IconButton)({});
export const RightContentBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.5rem 1rem',
});

export const ModalHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ItemContainer = styled(Flex)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: `0 ${theme.spacing(1)}`,
  cursor: 'pointer',
  minHeight: 24,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.15)
        : 'rgba(255, 255, 255, 0.08)',
  },
  '& p': {
    fontSize: '1.125rem',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: alpha(theme.palette.text.secondary, 0.8),
  marginBottom: theme.spacing(1),
}));
