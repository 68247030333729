import { HomeRounded } from '@mui/icons-material';
import { Box, styled } from '@mui/material';

// Home icon to use on all breadcrumbs
export const Home = styled(HomeRounded)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.secondary.main : '#fff',
}));

export const Flex = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
