import { createActions } from 'redux-actions';
import { identity } from '../../utils';

export const {
  userCleanout,
  setUser,
  updateUser,
  setPasswordExpirationInDays,
  setSelectedProjectId,
  setSelectedSystemId,
  setWasTokenRenewed,
} = createActions({
  USER_CLEANOUT: identity,
  SET_USER: identity,
  UPDATE_USER: identity,
  SET_PASSWORD_EXPIRATION_IN_DAYS: identity,
  SET_SELECTED_PROJECT_ID: identity,
  SET_SELECTED_SYSTEM_ID: identity,
  SET_WAS_TOKEN_RENEWED: identity,
});
