import { apiBaseUrlV1 } from '../../../env';
import { rootApi } from '..';

const BASE_URL = apiBaseUrlV1('control/v1/schemaRecords');

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    validateAttributes: builder.mutation<any, any>({
      query: (data) => ({
        url: `${BASE_URL}?mode=validate`,
        method: 'POST',
        body: data,
      }),
    }),
    createAttributes: builder.mutation<any, any>({
      query: (data) => ({
        url: `${BASE_URL}?mode=createMany`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useValidateAttributesMutation, useCreateAttributesMutation } =
  extendedApi;
