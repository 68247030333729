import React, { useState } from 'react';
import cn from 'classnames';
import { Link, NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import {
  DashboardCustomizeRounded,
  ExpandMoreRounded,
  HomeRounded,
} from '@mui/icons-material';
import { Button, Drawer, styled, alpha, useTheme } from '@mui/material';
import { ROUTES_PATH } from '../../constants';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import { ProjectIcon, SystemIcon } from '../../icons';
import {
  envLogoSrc,
  useGetMetadataQuery,
} from '../../redux/services/environment/api';
import MoreDropdown from './MoreDropdown';
//@ts-ignore
import LightIcon from '../../icons/light-icon.svg';
import { useAppSelector } from '../../redux/store';

interface Props {
  isOpen: boolean;
  isDark: boolean;
  onClose: () => void;
}

const NavItem = styled(Button)<NavLinkProps>(({ theme }) => ({
  margin: `0 ${theme.spacing(1)}`,
  minHeight: '2.5rem',
  color: theme.palette.text.primary,
  background: 'transparent',
  justifyContent: 'flex-start',
  marginBottom: 12,
  fontSize: '1.077rem',
  fontWeight: theme.palette.mode === 'dark' ? 400 : 600,
  '& .MuiButton-startIcon': {
    color: theme.palette.mode === 'dark' ? '#94A3B8' : '',
  },
  '&.active': {
    border: theme.palette.mode === 'dark' ? '1px solid' : 0,
    borderColor: alpha(theme.palette.text.primary, 0.18),
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.text.primary
        : theme.palette.primary.main,
    background:
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.08)
        : alpha(theme.palette.primary.main, 0.08),
    '& .MuiButton-startIcon': {
      color: theme.palette.mode === 'dark' ? '#fff' : '',
    },
  },
  '&:hover': {
    background:
      theme.palette.mode === 'dark'
        ? alpha('#d6e7ff', 0.12)
        : alpha(theme.palette.primary.main, 0.04),
  },
}));

const LogoWrap = styled(Link)({
  marginTop: 6,
  color: '#fff',
  padding: '18px 13px 18px 20px',
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: 9999,
  '& .MuiDrawer-paper': {
    width: 192,
    background:
      theme.palette.mode === 'dark' ? theme.palette.background.default : '',
    '& > *': {
      position: 'relative',
      zIndex: 1,
    },
  },
}));

function MobileDrawer(props: Props) {
  const { isOpen, onClose } = props;
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const location = useLocation();
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  const { data: envMetadata } = useGetMetadataQuery(undefined, {
    skip: isEdgeEnv,
  });

  const handleMoreOpen = (event: React.BaseSyntheticEvent) => {
    setMoreAnchorEl(event?.target);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  return (
    <>
      {[
        ROUTES_PATH.MAIN,
        ROUTES_PATH.LOGIN,
        ROUTES_PATH.RESET_PASSWORD,
        ROUTES_PATH.CHANGE_PASSWORD,
      ].includes(location.pathname) ? (
        ''
      ) : (
        <StyledDrawer open={isOpen} onClose={onClose}>
          <LogoWrap to={ROUTES_PATH.DASHBOARD}>
            <img
              src={envLogoSrc({ isDark })}
              alt={`${envMetadata?.environmentName || 'environment'} logo`}
              width="116"
              height="36"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src =
                  theme.palette.mode === 'dark'
                    ? '/images/procaaso-white.svg'
                    : '/images/procaaso.svg';
              }}
            />
          </LogoWrap>
          <NavItem
            component={NavLink}
            to={ROUTES_PATH.DASHBOARD}
            onClick={onClose}
            startIcon={<HomeRounded />}
            id="Navigation-home_mobile"
          >
            Home
          </NavItem>
          <NavItem
            component={NavLink}
            to={`${ROUTES_PATH.SYSTEMS}/list`}
            onClick={onClose}
            startIcon={<SystemIcon />}
            className={cn(
              location.pathname.includes(`${ROUTES_PATH.SYSTEMS}/map`) &&
                'active',
              location.pathname.includes(ROUTES_PATH.SYSTEM_DETAIL) && 'active',
            )}
            id="Navigation-systems_mobile"
          >
            Systems
          </NavItem>
          <NavItem
            component={NavLink}
            to={`${ROUTES_PATH.PROJECTS}/ens`}
            onClick={onClose}
            startIcon={<ProjectIcon />}
            className={cn(
              location.pathname.includes(`${ROUTES_PATH.PROJECT_DETAIL}/`) &&
                'active',
              location.pathname.includes(`${ROUTES_PATH.CONFIG}/`) && 'active',
              location.pathname.includes(ROUTES_PATH.CREATE_CONFIG) && 'active',
            )}
            id="Navigation-projects_mobile"
          >
            Projects
          </NavItem>
          {!isEdgeEnv && (
            <>
              <NavItem
                component={Button}
                to={ROUTES_PATH.MORE}
                startIcon={<DashboardCustomizeRounded />}
                endIcon={<ExpandMoreRounded />}
                disableRipple
                onClick={handleMoreOpen}
                id="Navigation-more_mobile"
              >
                More
              </NavItem>
              <MoreDropdown
                anchorEl={moreAnchorEl}
                handleClose={handleMoreClose}
              />
            </>
          )}
        </StyledDrawer>
      )}
    </>
  );
}

export default MobileDrawer;
