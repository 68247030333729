import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  MenuRounded,
  AddRounded,
  SearchRounded,
  SwapVertRounded,
} from '@mui/icons-material';
import {
  AddBtn,
  InnerHead,
  ModalHeadRoot,
  ModalTitleHead,
  SearchBtn,
} from '../styles';
import Search from '../../../Search';
import { SwitchBtn, SwitchWrap } from '../../../styles/switch';
import { AppsIcon, ListIcon } from '../../../../icons';
import ImportExport from './ImportExport';

interface Props {
  isTablet: boolean;
  isListView: boolean;
  toggleDrawer: (newState: boolean) => void;
  selectedVisuals: any[];
  searchValue: string;
  handleSearchInput: (ev: React.ChangeEvent<HTMLInputElement> | string) => void;
  handleInnerModalOpen: () => void;
  toggleView: (val: boolean) => void;
}

interface ExportButtonProps {
  setIsImportExportOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExportButton = ({ setIsImportExportOpen }: ExportButtonProps) => (
  <Tooltip title="Import/Export">
    <IconButton
      onClick={() => setIsImportExportOpen(true)}
      name="import or export"
      aria-label="import or export"
      className="outlined"
      color="secondary"
      sx={{ ml: 1 }}
    >
      <SwapVertRounded />
    </IconButton>
  </Tooltip>
);

const ModalHeader = (props: Props) => {
  const {
    isTablet,
    toggleDrawer,
    isListView,
    selectedVisuals,
    searchValue,
    handleSearchInput,
    handleInnerModalOpen,
    toggleView,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [isImportExportOpen, setIsImportExportOpen] = useState<boolean>(false);

  const handleExpandSearch = () => {
    setIsSearchExpanded(true);
  };

  const handleCollapseSearch = () => {
    setIsSearchExpanded(false);
    handleSearchInput('');
  };

  return (
    <ModalHeadRoot>
      {isTablet && (
        <IconButton
          onClick={() => {
            toggleDrawer(true);
          }}
          sx={{ ml: -1 }}
        >
          <MenuRounded />
        </IconButton>
      )}
      {!isSearchExpanded && (
        <ModalTitleHead id="element-library-title">
          Element library{' '}
          {isListView &&
            selectedVisuals.length > 0 &&
            `(${selectedVisuals.length})`}
        </ModalTitleHead>
      )}
      <InnerHead>
        {isSearchExpanded ? (
          <Search
            placeholder="Search"
            value={searchValue}
            onSearch={handleSearchInput}
            onCancel={handleCollapseSearch}
            autoFocus={isSearchExpanded}
            sx={{ mr: 1 }}
          />
        ) : (
          <Tooltip title="Search">
            <SearchBtn
              onClick={handleExpandSearch}
              className={isSearchExpanded ? 'active' : ''}
              name="search"
              aria-label="search"
            >
              <SearchRounded />
            </SearchBtn>
          </Tooltip>
        )}
        {isMobile ? (
          <Tooltip title="Create element">
            <AddBtn
              onClick={() => {
                handleInnerModalOpen();
              }}
              name="add-visual"
              aria-label="add visual"
            >
              <AddRounded />
            </AddBtn>
          </Tooltip>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              handleInnerModalOpen();
            }}
            name="add-visual"
            aria-label="add visual"
            sx={{ marginRight: 1, minWidth: 'max-content' }}
          >
            <AddRounded />
            Create element
          </Button>
        )}

        <SwitchWrap sx={{ ml: 0 }}>
          <Tooltip title="Grid view">
            <SwitchBtn
              onClick={() => {
                toggleView(false);
              }}
              className={!isListView ? 'active' : ''}
              color="inherit"
            >
              <AppsIcon />
            </SwitchBtn>
          </Tooltip>
          <Tooltip title="List view">
            <SwitchBtn
              onClick={() => toggleView(true)}
              className={isListView ? 'active' : ''}
              color="inherit"
            >
              <ListIcon />
            </SwitchBtn>
          </Tooltip>
        </SwitchWrap>
        <ExportButton setIsImportExportOpen={setIsImportExportOpen} />
      </InnerHead>
      <ImportExport
        open={isImportExportOpen}
        onClose={() => setIsImportExportOpen(false)}
      />
    </ModalHeadRoot>
  );
};

export default ModalHeader;
