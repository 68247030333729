import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DeleteBtn } from './styles';

interface Props {
  open: boolean;
  name?: string;
  onClose: () => void;
  handleDelete: () => void;
}

const ConfirmModal = (props: Props) => {
  const { open, name, onClose, handleDelete } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete element {name}</DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <DeleteBtn onClick={handleDelete}>Delete</DeleteBtn>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  name: '',
};

export default ConfirmModal;
