import { InputBase, styled } from '@mui/material';

const Input = styled(InputBase)(({ theme }) => ({
  padding: 0,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '&.Mui-focused': {
    boxShadow: 'none',
    borderColor:
      theme.palette.mode === 'dark'
        ? theme.palette.text.disabled
        : theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
  '&.Mui-readOnly': {
    borderColor: theme.palette.divider,
  },
  'label + &': {
    marginTop: theme.spacing(4),
  },
  '&.Mui-error .MuiInputBase-input': {
    borderColor: theme.palette.error.main,
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '1.1876em',
    width: '100%',
    padding: '6.5px 12px',
    transition: theme.transitions.create(['border-color']),
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
    '&.Mui-disabled': {
      textFillColor: 'inherit',
    },
    '&::placeholder': {
      color: theme.palette.text.disabled,
      opacity: 1,
    },
  },
  '& .MuiInputAdornment-root': {
    marginRight: theme.spacing(1),
  },
}));

export default Input;
