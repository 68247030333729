import {
  useOidc,
  useOidcAccessToken,
  useOidcIdToken,
  useOidcUser,
} from '@axa-fr/react-oidc';
import { environment } from '../env';
import { LogoutState, logoutStorage } from '../utils/oidcConfig';

const env = environment();
const useOidcCloud = () => {
  if (env === 'edge') {
    const logout = () => {
      localStorage.setItem(logoutStorage, LogoutState.true);
      const storageEvent = new StorageEvent('storage', {
        key: logoutStorage,
        newValue: LogoutState.true,
      });
      window.dispatchEvent(storageEvent);
    };
    return {
      login: undefined,
      logout,
      renewTokens: undefined,
      isAuthenticated: undefined,
      accessToken: undefined,
      idToken: undefined,
      oidcUser: undefined,
      oidcLogout: undefined,
    };
  }

  const { logout: logoutOidc } = useOidc();
  const { idToken } = useOidcIdToken();

  const logout = () => {
    localStorage.setItem(logoutStorage, LogoutState.true);
    logoutOidc(undefined, {
      id_token_hint: idToken,
      post_logout_redirect_uri: undefined,
    }).then(() => {
      localStorage.setItem(logoutStorage, LogoutState.done);
    });
  };

  return {
    ...useOidc(),
    ...useOidcAccessToken(),
    ...useOidcIdToken(),
    ...useOidcUser(),
    logout,
  };
};

export default useOidcCloud;
