import React, { ChangeEvent, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import yaml from 'js-yaml';
import { saveAs } from 'file-saver';
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AddRounded,
  DescriptionRounded,
  IosShareOutlined,
  MoreVertRounded,
} from '@mui/icons-material';
import Modal from '../../../common/Diagram/library/Modal';
import QuickAccessButton from '../../../common/QuickAccessButton';
import { RedoIcon, TrashIcon, UndoIcon } from '../../../icons';
import { REDIRECT_URI, ROUTES_PATH } from '../../../constants';
import {
  useCreateAttributesMutation,
  useValidateAttributesMutation,
} from '../../../redux/services/schemaRecords/api';
import withBreadcrumbs from '../../../hoc/withBreadcrumbs';
import useYAMLTemplateEditorState from '../../../hooks/useYAMLTemplateEditorState';
import useYAMLEditor from '../../../hooks/useYAMLEditor';
import { Actions } from './styles';
import DeleteConfigTemplate from '../../../common/Dialogs/DeleteConfigTemplate';

const findAllInstruments = (obj: any) => {
  if (!obj) return null;
  return Object.entries(obj).map(([key, value]) => {
    if (!value) return null;
    if (key === 'instruments' && typeof value === 'object') return value;
    if (key !== 'instruments' && typeof value === 'object')
      return findAllInstruments(value);
    return null;
  });
};

interface Props {
  handleExit: () => void;
  isUpdated: boolean;
  isEditable: boolean;
}

const ConfigrationActions = (props: Props) => {
  const { handleExit, isUpdated, isEditable } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { id: configID } = useParams<{ id: string }>();

  const [isDeleteWindowOpen, setIsDeleteWindowOpen] = useState<boolean>(false);
  const [isLibraryOpened, setIsLibraryOpened] = useState<boolean>(false);
  const [morePopupElement, setMorePopupElement] = useState<null | HTMLElement>(
    null,
  );

  const {
    name,
    text,
    handleNodeAdd,
    handleFileUpload,
    handleSaveAction,
    handleDeleteDocument,
  } = useYAMLTemplateEditorState({ id: configID });
  const { yamlInstance } = useYAMLEditor({ isSetMarkers: false });

  const onUndo = () => {
    if (yamlInstance) {
      yamlInstance.trigger('source', 'undo', undefined);
    }
  };
  const onRedo = () => {
    if (yamlInstance) {
      yamlInstance.trigger('source', 'redo', undefined);
    }
  };

  const [
    validateAttributes,
    { isLoading: isValidateAttributesMutationLoading },
  ] = useValidateAttributesMutation();
  const [
    createAttributesMany,
    { isLoading: isCreateAttributesMutationLoading },
  ] = useCreateAttributesMutation();

  const handleLibOpen = () => {
    setIsLibraryOpened(true);
  };

  const handleLibClose = () => {
    setIsLibraryOpened(false);
  };

  const buttonDisabledByRequest = useMemo(
    () =>
      isValidateAttributesMutationLoading || isCreateAttributesMutationLoading,
    [isValidateAttributesMutationLoading, isCreateAttributesMutationLoading],
  );

  const handleValidateTemplate = async () => {
    const data = yaml.load(text);
    const attributes = findAllInstruments(data)
      .flat(20)
      .filter((v) => v)
      .filter((v) => Object.keys(v).length > 0)
      .map((v) =>
        Object.values(v).map((ins) =>
          // @ts-ignore
          Object.values(ins?.attributes).map((vInner) => vInner),
        ),
      )
      .flat(20);

    const resultValidation = await validateAttributes(attributes);
    await createAttributesMany({
      // @ts-ignore
      validatedResults: resultValidation.data,
    });
  };

  const handleSaveConfigTemplate = async () => {
    await handleValidateTemplate();

    handleSaveAction();
  };

  const handleMorePopup = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setMorePopupElement(ev.currentTarget);
  };

  const handleFileUploading = async (event: ChangeEvent<HTMLInputElement>) => {
    const [file] = event.currentTarget.files;
    const innerData = await file.text();

    handleFileUpload(innerData);
    if (event?.currentTarget?.value) event.currentTarget.value = null;
  };

  const handleSaveYaml = () => {
    setMorePopupElement(null);
    const blob = new Blob([text]);
    saveAs(blob, `${name}.yaml`);
  };

  const handlePopupClose = () => {
    setMorePopupElement(null);
  };

  const handleDeleteConfigWindow = () => {
    handlePopupClose();
    setIsDeleteWindowOpen((prev) => !prev);
  };

  return (
    <>
      <Actions>
        {!isMobile && (
          <>
            {name?.length !== 0 && (
              <Button
                variant="contained"
                onClick={handleSaveConfigTemplate}
                sx={{ marginRight: 2 }}
                disabled={
                  name?.length === 0 || buttonDisabledByRequest || !name
                }
                id="CreateConfig-save_changes"
              >
                Save changes
              </Button>
            )}
            <Tooltip title="Undo">
              <IconButton
                onClick={onUndo}
                className="outlined"
                disabled={
                  // @ts-ignore
                  !yamlInstance ? !yamlInstance?.getModel()?.canUndo() : true
                }
              >
                <UndoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Redo">
              <IconButton
                onClick={onRedo}
                sx={{ mx: 2 }}
                className="outlined"
                disabled={
                  // @ts-ignore
                  !yamlInstance ? !yamlInstance?.getModel()?.canRedo() : true
                }
              >
                <RedoIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Button
          onClick={handleLibOpen}
          color="secondary"
          variant="contained"
          sx={{
            minWidth: 36,
            ml: { xs: 0, sm: 'auto' },
            px: { xs: 1, md: 2 },
          }}
          startIcon={isMobile ? null : <AddRounded />}
          disabled={!isEditable}
          id="CreateConfig-add_visual"
        >
          {isMobile ? <AddRounded /> : 'Add visual element'}
        </Button>
        {isMobile &&
          name?.length !== 0 &&
          !isUpdated &&
          !sessionStorage.getItem(REDIRECT_URI) && (
            <Button
              variant="contained"
              onClick={handleSaveConfigTemplate}
              sx={{ ml: 2 }}
              disabled={
                name?.length === 0 ||
                buttonDisabledByRequest ||
                !name ||
                !isEditable
              }
              id="CreateConfig-save_changes"
            >
              Save
            </Button>
          )}
        <Modal
          open={isLibraryOpened}
          onClose={handleLibClose}
          pasteHandler={handleNodeAdd}
        />
        {!isMobile && (
          <Button
            variant="outlined"
            component="label"
            sx={{ ml: 2, px: { xs: 1, md: 2 } }}
            disabled={!isEditable}
            id="EditorView_Button-ImportFromFile"
          >
            Import from file
            <input
              type="file"
              hidden
              onChange={handleFileUploading}
              accept=".yaml"
            />
          </Button>
        )}
        <Box sx={{ ml: { xs: 'auto', sm: 2 }, mr: 2 }}>
          <Button
            id="CreateConfig-exit_button"
            variant="contained"
            onClick={handleExit}
          >
            Exit
          </Button>
        </Box>
        {!isMobile && configID && (
          <QuickAccessButton
            className="favBtn"
            id="ProjectConfig-QuickAccess_button"
          />
        )}
        <Tooltip title="More">
          <IconButton
            id="EditConfig-more_action_button"
            className="outlined"
            onClick={handleMorePopup}
          >
            <MoreVertRounded />
          </IconButton>
        </Tooltip>
      </Actions>
      <Menu
        id="user-action-menu"
        anchorEl={morePopupElement}
        keepMounted
        open={Boolean(morePopupElement)}
        onClose={() => setMorePopupElement(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiList-root': {
            minWidth: isMobile && '230px',
          },
        }}
      >
        {isMobile && (
          <>
            <MenuItem
              // @ts-ignore
              disabled={!yamlInstance?.getModel()?.canUndo()}
              onClick={onUndo}
              id="EditConfig-undo-mobile"
            >
              <ListItemIcon>
                <UndoIcon />
              </ListItemIcon>
              Undo
            </MenuItem>
            <MenuItem
              // @ts-ignore
              disabled={!yamlInstance?.getModel()?.canRedo()}
              onClick={onRedo}
              id="EditConfig-redo-mobile"
            >
              <ListItemIcon>
                <RedoIcon />
              </ListItemIcon>
              Redo
            </MenuItem>
          </>
        )}
        <MenuItem onClick={handleSaveYaml} id="EditConfig-save_as_yaml">
          <ListItemIcon>
            <IosShareOutlined />
          </ListItemIcon>
          Save as YAML
        </MenuItem>
        {isMobile && (
          <>
            {configID && (
              <QuickAccessButton
                asListItem
                id="ProjectConfig-QuickAccess_button_mobile"
              />
            )}
            <MenuItem
              onClick={() => setMorePopupElement(null)}
              component="label"
              disabled={!isEditable}
              id="EditorView_Button-ImportFromFile"
            >
              <ListItemIcon>
                <DescriptionRounded />
              </ListItemIcon>
              Import from file
              <input
                type="file"
                hidden
                onChange={handleFileUploading}
                accept=".yaml"
              />
            </MenuItem>
          </>
        )}
        {configID ? (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={handleDeleteConfigWindow}
            id="EditConfig-delete_button"
          >
            <ListItemIcon>
              <TrashIcon />
            </ListItemIcon>
            Delete
          </MenuItem>
        ) : (
          <MenuItem
            sx={{ color: 'error.main' }}
            component={Link}
            to={`${ROUTES_PATH.PROJECTS}/ens`}
            id="CreateConfig-delete_button"
          >
            <ListItemIcon>
              <TrashIcon />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
      </Menu>
      {configID && (
        <DeleteConfigTemplate
          entity={{ name }}
          open={isDeleteWindowOpen}
          onClose={handleDeleteConfigWindow}
          handleAction={handleDeleteDocument}
        />
      )}
    </>
  );
};

export default withBreadcrumbs(ConfigrationActions);
