import React from 'react';
import BaseFlair from './BaseFlair';

interface Props {
  className?: string;
  data?: any;
  selected?: boolean;
}

const defaultMinWidth = 10;
const defaultMinHeight = 10;

function BooleanIndicator(props: Props) {
  const { className, data, selected } = props;

  return (
    <BaseFlair
      className={className}
      data={data}
      selected={selected}
      resizeRatio={1}
      lockAspectRatio
      defaultMinWidth={defaultMinWidth}
      defaultMinHeight={defaultMinHeight}
      content={
        <svg width="10" height="10" viewBox="0 0 10 10">
          <rect width="10" height="10" />
        </svg>
      }
    />
  );
}

BooleanIndicator.defaultProps = {
  className: '',
  data: {},
  selected: false,
};

export default BooleanIndicator;
