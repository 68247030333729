import React, { Dispatch, SetStateAction } from 'react';
import { Tooltip, styled } from '@mui/material';
import { Controls, ReactFlowInstance } from 'react-flow-renderer';
import {
  AddRounded,
  CenterFocusWeakRounded,
  GridOffRounded,
  GridOnRounded,
  RemoveRounded,
} from '@mui/icons-material';
import { ControlBtn } from '../styles';
import { ButtonStateProps } from '../../../components/system/overview/RunButton';

interface PropsControlsButtons {
  toggleGrid: () => void;
  onFullscreenEnter?: () => void;
  onFullscreenExit?: () => void;
  showGrid: boolean;
  isEditable: boolean;
  withFullscreen: boolean;
  isFullscreen: boolean;
  withGrid: boolean;
  reactFlowInstance: ReactFlowInstance;
  currentNameTypes?: boolean;
  // handleCurrentNamesTypes: (boolean) => void;
  system: any;
  onModalClose?: any;
  button: any;
  setButton: Dispatch<SetStateAction<ButtonStateProps>>;
}

const IconWrap = styled('span')({
  '& svg': {
    fontSize: '22px',
  },
});

export const ControlsButtons = (props: PropsControlsButtons) => {
  const { showGrid, withGrid, toggleGrid, reactFlowInstance } = props;

  return (
    <Controls showZoom={false} showInteractive={false} showFitView={false}>
      {withGrid && (
        <ControlBtn onClick={toggleGrid}>
          {showGrid ? (
            <Tooltip
              PopperProps={{
                container: () => document.querySelector('.fullscreen'),
              }}
              title="Hide grid"
              placement="right"
            >
              <IconWrap role="img">
                <GridOffRounded />
              </IconWrap>
            </Tooltip>
          ) : (
            <Tooltip
              PopperProps={{
                container: () => document.querySelector('.fullscreen'),
              }}
              title="Show grid"
              placement="right"
            >
              <IconWrap role="img">
                <GridOnRounded />
              </IconWrap>
            </Tooltip>
          )}
        </ControlBtn>
      )}

      <ControlBtn
        onClick={() => {
          reactFlowInstance.zoomIn();
        }}
        id="SystemDiagram-zoom_in"
      >
        <Tooltip
          PopperProps={{
            container: () => document.querySelector('.fullscreen'),
          }}
          title="Zoom in"
          placement="right"
          aria-label="zoomin"
        >
          <IconWrap role="img">
            <AddRounded />
          </IconWrap>
        </Tooltip>
      </ControlBtn>
      <ControlBtn
        onClick={() => {
          reactFlowInstance.zoomOut();
        }}
        id="SystemDiagram-zoom_out"
      >
        <Tooltip
          PopperProps={{
            container: () => document.querySelector('.fullscreen'),
          }}
          title="Zoom out"
          placement="right"
          aria-label="zoomout"
        >
          <IconWrap role="img">
            <RemoveRounded />
          </IconWrap>
        </Tooltip>
      </ControlBtn>
      <ControlBtn
        onClick={() => {
          reactFlowInstance.fitView({ padding: 0.4 });
        }}
        id="SystemDiagram-Fit_to_screen"
      >
        <Tooltip
          PopperProps={{
            container: () => document.querySelector('.fullscreen'),
          }}
          title="Fit to screen"
          placement="right"
          aria-label="fitview"
        >
          <IconWrap role="img">
            <CenterFocusWeakRounded />
          </IconWrap>
        </Tooltip>
      </ControlBtn>
    </Controls>
  );
};

ControlsButtons.defaultProps = {
  onModalClose: () => {},
  onFullscreenEnter: () => {},
  onFullscreenExit: () => {},
  currentNameTypes: false,
};

export default ControlsButtons;
