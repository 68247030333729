/* eslint-disable no-nested-ternary */
/* eslint-disable react/style-prop-object */
import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { Resizable } from 're-resizable';
import { Handle, Position } from 'react-flow-renderer';
import { styled, Box } from '@mui/material';
import Progress from './Progress';
import { useGetPortsQuery } from '../../../../redux/services/ports/api';
import { OldComponentData } from '../../../../typescript/interfaces/oldComponentData.interface';

const Element = styled('svg')({
  width: '100%',
  height: '100%',
});

const Label = styled('span')(({ theme }) => ({
  position: 'absolute',
  zIndex: 99,
  left: '14%',
  right: '14%',
  bottom: '48%',
  textAlign: 'center',
  color: '#484848',
  height: '44px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& span': {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '1.5',
  },
  '& svg': {
    width: '80%',
  },
}));

interface FitProps {
  children: string;
}

function TextFit(props: FitProps) {
  const { children } = props;
  const ifChildrenLengthLessThirtyTwo = children.length <= 32 ? '16px' : '14px';

  const ifChildrenLengthLessTwentySix =
    children.length <= 26 ? '20px' : ifChildrenLengthLessThirtyTwo;

  return (
    <Box
      component="span"
      sx={{
        fontSize:
          children.length <= 20 ? '24px' : ifChildrenLengthLessTwentySix,
      }}
    >
      {children}
    </Box>
  );
}

interface Props {
  id: string;
  data?: OldComponentData;
  currentNameTypes?: boolean;
  systemName?: string;
  display?: boolean;
  isTemplate?: boolean;
}

function BufferConcentrate(props: Props) {
  const { id, data, systemName, isTemplate: propsIsTemplate } = props;
  const { size } = data;
  const { width, height } = size || {};
  const defaultSize = { width: width || 320, height: height || 324 };
  const isTemplate = Boolean(propsIsTemplate || data.isTemplate);
  const progress = 0;
  const { id: entityId } = useParams<{ id: string }>();

  const MAX_VALUE = 10; // TBD MAX Kg FOR BufferConcentrate

  const currentNameTypes = useMemo(
    () => data?.label?.props?.currentNameTypes || undefined,
    [data],
  );

  const { data: ports } = useGetPortsQuery(entityId, { skip: !entityId });

  const name = useMemo(() => {
    if (isTemplate) return id;
    return 'Buffer Concentrate';
  }, [currentNameTypes, systemName, data]);

  const [port, setPort] = useState([]);
  useEffect(() => {
    if (ports) {
      setPort(ports.filter(({ parentAggregate }) => parentAggregate === id));
    }
  }, [ports]);

  return (
    <Box
      component={Resizable}
      className={!isTemplate && 'nodrag'}
      defaultSize={defaultSize}
      lockAspectRatio
      resizeRatio={4}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .point': {
          opacity: '1',
        },
        '& .resize-handle': {
          width: '25% !important',
          height: '25% !important',
          right: '0 !important',
          bottom: '0 !important',
          display: 'none',
        },
      }}
    >
      <div>
        <span className="transparent" />
        {data?.label?.props?.display && !isTemplate && (
          <Progress
            // eslint-disable-next-line react/destructuring-assignment
            progress={progress}
            maxValue={MAX_VALUE}
          />
        )}
        <Label
          // ref={textRef}
          className={currentNameTypes !== undefined ? 'label-system' : ''}
        >
          <TextFit>{name}</TextFit>
        </Label>
        {port.length ? (
          port.map((p) => (
            <Handle
              key={p._id}
              className="point"
              type={p.name === 'Outlet' ? 'target' : 'source'}
              position={p.name === 'Inlet' ? Position.Right : Position.Left}
              id={p._id}
              style={p.name === 'Inlet' ? { right: '9%' } : { left: '9%' }}
            />
          ))
        ) : (
          <>
            <Handle
              className="point"
              type="target"
              position={Position.Right}
              id="t-right"
              style={{ right: '35.5%', top: '32.5%' }}
            />
            <Handle
              className="point"
              type="target"
              position={Position.Right}
              id="Feed"
              style={{ right: '35.5%', top: '32.5%' }}
            />
            <Handle
              className="point"
              type="target"
              id="t-left"
              position={Position.Left}
              style={{ left: '35.5%', top: '32.5%' }}
            />
          </>
        )}

        <Element
          id="Слой_1"
          data-name="Слой 1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1500 1500"
          // width="330"
          // height="324"
        >
          <defs>
            <linearGradient
              id="buffer-concerntrate_41"
              x1="750"
              y1="135.64"
              x2="750"
              y2="63.55"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#6e6e6e" />
              <stop offset="0.03" stopColor="#787878" />
              <stop offset="0.12" stopColor="#8e8e8e" />
              <stop offset="0.22" stopColor="#9f9f9f" />
              <stop offset="0.34" stopColor="#aaa" />
              <stop offset="0.52" stopColor="#b1b1b1" />
              <stop offset="1" stopColor="#b3b3b3" />
            </linearGradient>
            <linearGradient
              id="buffer-concerntrate_20"
              x1="750"
              y1="250.94"
              x2="750"
              y2="190.48"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#6e6e6e" />
              <stop offset="0.12" stopColor="#7d7d7d" />
              <stop offset="0.36" stopColor="#a3a3a3" />
              <stop offset="0.63" stopColor="#d5d4d4" />
              <stop offset="1" stopColor="#878583" />
            </linearGradient>
            <linearGradient
              id="buffer-concerntrate_40"
              x1="403.79"
              y1="141.64"
              x2="403.79"
              y2="257.92"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#b3b3b3" />
              <stop offset="0.48" stopColor="#b1b1b1" />
              <stop offset="0.66" stopColor="#aaa" />
              <stop offset="0.78" stopColor="#9f9f9f" />
              <stop offset="0.88" stopColor="#8e8e8e" />
              <stop offset="0.97" stopColor="#787878" />
              <stop offset="1" stopColor="#6e6e6e" />
            </linearGradient>
            <linearGradient
              id="buffer-concerntrate_40-2"
              x1="1096.21"
              y1="141.64"
              x2="1096.21"
              y2="257.92"
              xlinkHref="#buffer-concerntrate_40"
            />
            <linearGradient
              id="buffer-concerntrate_40-3"
              x1="750"
              y1="141.64"
              x2="750"
              y2="257.92"
              xlinkHref="#buffer-concerntrate_40"
            />
            <linearGradient
              id="buffer-concerntrate_33"
              x1="615.52"
              y1="1306.55"
              x2="884.48"
              y2="1306.55"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#d8d8d8" stopOpacity="0.3" />
              <stop offset="0.07" stopColor="#dbdbdb" stopOpacity="0.35" />
              <stop offset="0.22" stopColor="#e3e3e3" stopOpacity="0.5" />
              <stop offset="0.42" stopColor="#f0f0f0" stopOpacity="0.73" />
              <stop offset="0.63" stopColor="#fff" />
              <stop offset="1" stopColor="#878583" stopOpacity="0.1" />
            </linearGradient>
            <linearGradient
              id="buffer-concerntrate_33-2"
              x1="682.25"
              y1="1348.5"
              x2="817.75"
              y2="1348.5"
              xlinkHref="#buffer-concerntrate_33"
            />
            <linearGradient
              id="buffer-concerntrate_37"
              x1="656.24"
              y1="1403.92"
              x2="843.76"
              y2="1403.92"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#f1f1f1" />
              <stop offset="0.37" stopColor="#fff" />
              <stop offset="0.58" stopColor="#f0f0f0" stopOpacity="0.73" />
              <stop offset="0.78" stopColor="#e3e3e3" stopOpacity="0.5" />
              <stop offset="0.93" stopColor="#dbdbdb" stopOpacity="0.35" />
              <stop offset="1" stopColor="#d8d8d8" stopOpacity="0.3" />
            </linearGradient>
          </defs>
          <path
            d="M364,135.64h772.1V124.36a60.81,60.81,0,0,0-60.82-60.81H424.77A60.81,60.81,0,0,0,364,124.36Z"
            style={{
              stroke: '#000',
              strokeMiterlimit: 10,
              fill: 'url(#buffer-concerntrate_41)',
            }}
          />
          <rect
            x="170.45"
            y="190.48"
            width="1159.1"
            height="60.47"
            style={{
              stroke: '#000',
              strokeMiterlimit: 10,
              fill: 'url(#buffer-concerntrate_20)',
            }}
          />
          <rect
            x="338.67"
            y="250.94"
            width="130.23"
            height="13.95"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            x="684.88"
            y="250.94"
            width="130.23"
            height="13.95"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            x="1031.09"
            y="250.94"
            width="130.23"
            height="13.95"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            x="354.95"
            y="141.64"
            width="97.67"
            height="116.28"
            style={{
              stroke: '#000',
              strokeMiterlimit: 10,
              fill: 'url(#buffer-concerntrate_40)',
            }}
          />
          <rect
            x="1047.37"
            y="141.64"
            width="97.67"
            height="116.28"
            style={{
              stroke: '#000',
              strokeMiterlimit: 10,
              fill: 'url(#buffer-concerntrate_40-2)',
            }}
          />
          <rect
            x="701.16"
            y="141.64"
            width="97.67"
            height="116.28"
            style={{
              stroke: '#000',
              strokeMiterlimit: 10,
              fill: 'url(#buffer-concerntrate_40-3)',
            }}
          />
          <polygon
            points="354.95 141.64 363.95 135.64 1136.05 135.64 1145.05 141.64 354.95 141.64"
            style={{
              fill: '#8c8c8c',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            x="743.24"
            y="77.63"
            width="13.51"
            height="43.92"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <polygon
            points="170.45 250.94 338.67 250.94 338.67 264.89 468.91 264.89 468.91 250.94 684.88 250.94 684.88 264.89 815.12 264.89 815.12 250.94 1031.09 250.94 1031.09 264.89 1161.33 264.89 1161.33 250.94 1329.55 250.94 1329.55 330.1 170.45 330.1 170.45 250.94"
            style={{
              fill: '#d8d8d8',
              stroke: '#000',
              strokeMiterlimit: 10,
              opacity: 0.5,
            }}
          />
          <rect
            x="170.45"
            y="330.1"
            width="1159.1"
            height="512.3"
            style={{
              fill: '#e2e2e2',
              stroke: '#000',
              strokeMiterlimit: 10,
              opacity: 0.5,
            }}
          />
          <rect
            x="615.52"
            y="1297.26"
            width="268.95"
            height="18.58"
            style={{
              stroke: '#000',
              strokeMiterlimit: 10,
              fill: 'url(#buffer-concerntrate_33)',
            }}
          />
          <polygon
            points="170.45 842.4 1329.55 842.4 1047.37 1306.55 452.63 1306.55 170.45 842.4"
            style={{
              fill: '#e2e2e2',
              stroke: '#000',
              strokeMiterlimit: 10,
              opacity: 0.5,
            }}
          />
          <rect
            x="682.25"
            y="1315.84"
            width="135.5"
            height="65.32"
            style={{
              stroke: '#000',
              strokeMiterlimit: 10,
              fill: 'url(#buffer-concerntrate_33-2)',
            }}
          />
          <path
            d="M666,1392.25v-2.53h0c-15.29-8.26-26.09-20.79-34.16-36.19l-1-2.67h-4a2.79,2.79,0,0,0-2.1,1h0a63.89,63.89,0,0,0,0,83.68h0a2.81,2.81,0,0,0,2.1,1h4l1.17-4.22c7.88-15.69,18.9-28.43,34-37.35h0Z"
            style={{ fill: '#f4f4f4', stroke: '#000', strokeMiterlimit: 10 }}
          />
          <line
            x1="666.03"
            y1="1389.55"
            x2="609.19"
            y2="1389.55"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
              strokeWidth: '0.5px',
            }}
          />
          <line
            x1="666.03"
            y1="1395.27"
            x2="609.19"
            y2="1395.27"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
              strokeWidth: '0.5px',
            }}
          />
          <rect
            x="656.24"
            y="1387.16"
            width="187.52"
            height="33.51"
            style={{
              stroke: '#000',
              strokeMiterlimit: 10,
              fill: 'url(#buffer-concerntrate_37)',
            }}
          />
          <path
            d="M656.47,1387.15l26-6h135.5l25.57,5.9a.05.05,0,0,1,0,.1h-187S656.46,1387.15,656.47,1387.15Z"
            style={{
              fill: '#f4f4f4',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="550.5"
            cy="491.7"
            r="56.88"
            style={{
              fill: '#eaeaea',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <path
            d="M535.82,470.56l12.34-24.82a2.71,2.71,0,0,1,4.86,0l12.19,24.89,27.41,4.07a2.7,2.7,0,0,1,1.48,4.62l-19.9,19.29L578.8,526a2.71,2.71,0,0,1-3.94,2.85l-24.49-13L525.8,528.65a2.71,2.71,0,0,1-3.92-2.87l4.77-27.3-19.79-19.4a2.71,2.71,0,0,1,1.52-4.62Z"
            style={{
              fill: '#e2e2e2',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="550.5"
            cy="492.02"
            r="14.78"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="550.5"
            cy="491.7"
            r="6.95"
            style={{
              stroke: '#fff',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="750"
            cy="491.7"
            r="56.88"
            style={{
              fill: '#eaeaea',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <path
            d="M735.32,470.56l12.34-24.82a2.71,2.71,0,0,1,4.86,0l12.19,24.89,27.41,4.07a2.71,2.71,0,0,1,1.49,4.62l-19.9,19.29,4.6,27.32a2.72,2.72,0,0,1-3.95,2.85l-24.48-13-24.57,12.82a2.72,2.72,0,0,1-3.93-2.87l4.77-27.3-19.78-19.4a2.71,2.71,0,0,1,1.51-4.62Z"
            style={{
              fill: '#e2e2e2',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="750"
            cy="492.02"
            r="14.78"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="750"
            cy="491.7"
            r="6.95"
            style={{
              stroke: '#fff',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="949.5"
            cy="491.7"
            r="56.88"
            style={{
              fill: '#eaeaea',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <path
            d="M934.82,470.56l12.34-24.82a2.72,2.72,0,0,1,4.87,0l12.18,24.89,27.41,4.07a2.7,2.7,0,0,1,1.49,4.62l-19.9,19.29,4.6,27.32a2.71,2.71,0,0,1-3.94,2.85l-24.49-13-24.57,12.82a2.71,2.71,0,0,1-3.92-2.87l4.77-27.3-19.79-19.4a2.71,2.71,0,0,1,1.52-4.62Z"
            style={{
              fill: '#e2e2e2',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="949.5"
            cy="492.02"
            r="14.78"
            style={{
              fill: '#fff',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <circle
            cx="949.5"
            cy="491.7"
            r="6.95"
            style={{
              stroke: '#fff',
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1="354.95"
            y1="220.71"
            x2="452.63"
            y2="220.71"
            style={{
              fill: '#e2e2e2',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1="701.16"
            y1="220.71"
            x2="798.84"
            y2="220.71"
            style={{
              fill: '#e2e2e2',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
          <line
            x1="1047.37"
            y1="220.71"
            x2="1145.05"
            y2="220.71"
            style={{
              fill: '#e2e2e2',
              stroke: '#000',
              strokeMiterlimit: 10,
            }}
          />
        </Element>
      </div>
    </Box>
  );
}
BufferConcentrate.defaultProps = {
  data: {},
  currentNameTypes: false,
  systemName: undefined,
  display: true,
  isTemplate: false,
};

export default BufferConcentrate;
