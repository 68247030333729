import { Box, Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  '&.opened': {
    overflow: 'hidden',
    '& > div:first-of-type': {
      maxHeight: 'calc(100vh - 380px)',
      [theme.breakpoints.down('md')]: {
        maxHeight: 'calc(100vh - 480px)',
      },
    },
  },
  '& .monaco-editor, .overflow-guard': {
    height: '100% !important',
  },
}));

export const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'relative',
  backgroundColor: '#fff',
  '& .react-flow__node-default': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
    '&.selectable:hover': {
      boxShadow: 'none',
    },
    '&.selectable.selected': {
      boxShadow: 'none',
    },
  },
  '& .point.target': {
    backgroundColor: theme.palette.success.main,
  },
  '& .point.source': {
    backgroundColor: theme.palette.error.main,
  },
  '& .point': {
    opacity: 1,
  },
  '&.no-edit': {
    cursor: 'move',
    '& .point': {
      opacity: 0,
    },
    '& .edit-btn': {
      display: 'none',
    },
    '& .resize-handle': {
      display: 'none',
    },
  },
}));
