import React from 'react';
import { Resizable } from 're-resizable';
import { Box } from '@mui/material';

interface Props {
  id: string;
  data?: any;
}

const ProCaaSo = (props: Props) => {
  const { data } = props;
  const { size } = data || {};
  const { width, height } = size || {};
  const defaultSize = { width: width || 255, height: height || 91 };

  return (
    <Box
      component={Resizable}
      defaultSize={defaultSize}
      lockAspectRatio
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        backgroundImage: `url('/images/library/paracloud.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '& .resize-handle': {
          width: '12% !important',
          height: '30% !important',
          right: '-6% !important',
          bottom: '20% !important',
          display: 'none',
        },
      }}
    />
  );
};

ProCaaSo.defaultProps = {
  data: {},
};

export default ProCaaSo;
