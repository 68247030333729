import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import { IInstrument } from '../../../typescript/interfaces/instrument.interface';

const BASE_URL = `${apiBaseUrlV1('structure/v1')}/instruments`;

interface IInstrumentQueryParams {
  id?: string;
  parentAggregate?: string;
}

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstruments: builder.query<IInstrument[], string>({
      query: (parentAggregate) =>
        `${BASE_URL}?parentAggregate=${parentAggregate}`,
      providesTags: ['Instruments'],
    }),
    getInstrumentById: builder.query<IInstrument, IInstrumentQueryParams>({
      query: ({ id, parentAggregate }) => {
        const linkId = `/${id}`;
        return `${BASE_URL}${id ? linkId : ''}${
          parentAggregate ? `?parentAggregate=${parentAggregate}` : ''
        }`;
      },
      providesTags: ['Instrument'],
    }),
    getInstrumentsByAggregate: builder.query<IInstrument[], string>({
      query: (id) => `${BASE_URL}?rootAggregate=${id}`,
      providesTags: ['InstrumentsAggr'],
    }),
    getInstrumentsByParent: builder.query<IInstrument[], string>({
      query: (id) => `${BASE_URL}?parentAggregate=${id}`,
      providesTags: ['InstrumentsAggr'],
    }),
  }),
});

export const {
  useGetInstrumentsQuery,
  useGetInstrumentByIdQuery,
  useGetInstrumentsByAggregateQuery,
  useGetInstrumentsByParentQuery,
} = extendedApi;
