import { styled, Tabs as MuiTabs, Tab as MuiTab, alpha } from '@mui/material';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderBottom: 0,
  minHeight: '3.1rem',
  display: 'inline-flex',
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.secondary.main, 0.1)
      : theme.palette.background.default,
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '2px 12px',
  fontSize: '1.125rem !important',
  '&.hidden': {
    opacity: 0,
    width: 0,
    height: 0,
    minWidth: '0 !important',
    padding: 0,
    flex: 0,
  },
  '&.Mui-selected': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.background.default
        : theme.palette.bg.dark,
    borderRadius: theme.shape.borderRadius,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.secondary.main
        : theme.palette.text.primary,
    border:
      theme.palette.mode === 'light' ? 0 : `1px solid ${theme.palette.divider}`,
    opacity: 1,
    fontWeight: 600,
  },
}));
