import { useEffect, useState } from 'react';
import { useGetCurrentUserQuery } from '../redux/services/atreus/api';
import { useGetRolesQuery } from '../redux/services/roles/api';
import useOidcCloud from './useOidcCloud';

interface Props {
  permissionsPath: string;
}

const useGetPermissions = ({ permissionsPath }: Props) => {
  const [isActionGranted, setIsActionGranted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPermissionsAccesible, setIsPermissionsAccessible] = useState(false);
  const { accessToken } = useOidcCloud();

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    isSuccess: isCurrentUserSuccess,
  } = useGetCurrentUserQuery(accessToken);
  const {
    data: role,
    isLoading: isRoleLoading,
    isSuccess: isRoleSuccess,
  } = useGetRolesQuery(undefined, {
    skip: !currentUser || currentUser?.permissions?.length > 0,
    selectFromResult: (res) => ({
      ...res,
      data: res?.data?.find(
        ({ id }) => id === currentUser?.metadata_public?.roles[0],
      ),
    }),
  });

  useEffect(() => {
    setIsLoading(isCurrentUserLoading || isRoleLoading);
  }, [isCurrentUserLoading, isRoleLoading]);

  useEffect(() => {
    setIsPermissionsAccessible(isCurrentUserSuccess && isRoleSuccess);
  }, [isCurrentUserSuccess, isRoleSuccess]);

  useEffect(() => {
    if (role) {
      setIsActionGranted(
        !!role?.permissions?.find((v) => v === permissionsPath),
      );
    }
    if (currentUser?.permissions?.length > 0) {
      setIsPermissionsAccessible(true);
      setIsActionGranted(
        !!currentUser.permissions.find((v) => v === permissionsPath),
      );
    }
  }, [role, currentUser]);

  return { isActionGranted, isLoading, isPermissionsAccesible };
};

export default useGetPermissions;
