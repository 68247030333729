import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g
        id="Artboard_2"
        data-name="Artboard – 2"
        clipPath="url(#clip-Artboard_2)"
      >
        <g id="Group_1783" data-name="Group 1783" transform="translate(2 2)">
          <rect id="Checkbox" width="20" height="20" rx="4" />
          <path
            id="check"
            d="M16.473,7.187a.785.785,0,0,0-1.189,0L9.048,14.056l-2.62-2.891a.8.8,0,0,0-1.21.023,1.009,1.009,0,0,0,.021,1.331l3.215,3.536a.785.785,0,0,0,1.189,0L16.473,8.54a.985.985,0,0,0,0-1.354Z"
            transform="translate(-0.86 -1.62)"
            fill="#fff"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CheckedIcon;
