import React, { useState, useMemo } from 'react';
import { Box, Grid, Tooltip as RegularTooltip } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { groupBy } from 'lodash';
import Search from '../../Search';
import { useGetVisualsQuery } from '../../../redux/services/visuals/api';
import { useGetVisualCategoriesQuery } from '../../../redux/services/visualCategory/api';
import { apiBaseUrlV1 } from '../../../env';
import Groups from './Groups';
import { CloseBtn, Root } from './styles';
import VisualItem from './VisualItem';
import { isForbiddenError } from '../../../redux/utils';
import EmptyVisualElement from './EmptyVisualElement';
import { sortArrayByProperty } from '../../../utils/stringUtils';

interface Props {
  onHide: () => void;
}

const getVisualAssetUrl = (id: string, version: string | number) =>
  `${apiBaseUrlV1('structure/v1')}/visuals/${id}/asset/${version}`;

const Sidebar = (props: Props) => {
  const { onHide } = props;

  const [searchValue, setSearchValue] = useState('');
  const [activeVisual, setActiveVisual] = useState('');
  const [expandedGroup, setExpandedGroup] = useState<string | false>('');
  const [expandedSubgroup, setExpandedSubgroup] = useState<string | false>('');

  const { data: visuals = [], error: visualsError } = useGetVisualsQuery();
  const { data: visualCategories = [], error: visualCategoryError } =
    useGetVisualCategoriesQuery();

  const localVisuals = useMemo(
    () =>
      groupBy(
        visuals
          .map((visual) => ({
            ...visual,
            image: getVisualAssetUrl(visual.id, visual.activeAssetVersion),
          }))
          .filter(
            (visual) =>
              !searchValue ||
              visual?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) >
                -1,
          ),
        'category',
      ),
    [searchValue, visuals],
  );

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleActive = (value: string) => {
    setActiveVisual(value);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const handleGroupChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedGroup(newExpanded ? panel : false);
    };

  const handleSubgroupChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedSubgroup(newExpanded ? panel : false);
    };

  return (
    <Root>
      <RegularTooltip title="Close" placement="left">
        <CloseBtn className="outlined" onClick={onHide}>
          <KeyboardArrowRight />
        </CloseBtn>
      </RegularTooltip>
      <Box sx={{ p: 2 }}>
        <Search
          value={searchValue}
          onCancel={handleClear}
          onSearch={handleSearchChange}
          defaultExpanded
          placeholder="Search visuals"
        />
      </Box>
      {visualsError ? (
        <Box
          sx={{
            flex: 1,
            alignContent: 'center',
          }}
        >
          <EmptyVisualElement
            error={visualsError}
            isListView
            searchValue={searchValue}
          />
        </Box>
      ) : (
        <Box sx={{ flex: 1, overflow: 'auto', px: 2 }}>
          {isForbiddenError(visualCategoryError) ? (
            <Grid container spacing={1} mt={1}>
              {visuals?.map((v, index) => (
                <VisualItem
                  key={v.id}
                  visual={v}
                  index={`${index}`}
                  activeVisual={activeVisual}
                  handleActive={handleActive}
                  onDragStart={onDragStart}
                />
              ))}
            </Grid>
          ) : (
            <>
              <Groups
                title="Groups"
                id="groups"
                expanded={expandedGroup}
                handleChange={handleGroupChange}
                count={visualCategories.length}
              >
                <>
                  {sortArrayByProperty(visualCategories, 'name').map(
                    ({ name, id }) => (
                      <Groups
                        title={name}
                        id={id}
                        key={id}
                        expanded={expandedSubgroup}
                        handleChange={handleSubgroupChange}
                        count={localVisuals?.[name]?.length || 0}
                      >
                        <Grid container spacing={1} mt={1}>
                          {localVisuals?.[name]?.map((v, index) => (
                            <VisualItem
                              key={v.id}
                              visual={v}
                              index={`${localVisuals?.[name]}_${index}`}
                              activeVisual={activeVisual}
                              handleActive={handleActive}
                              onDragStart={onDragStart}
                            />
                          ))}
                        </Grid>
                      </Groups>
                    ),
                  )}
                </>
              </Groups>
              <Groups
                title="Uncategorized"
                id="Uncategorized"
                expanded={expandedGroup}
                handleChange={handleGroupChange}
                count={localVisuals?.null?.length || 0}
              >
                <Grid container spacing={1} mt={1}>
                  {localVisuals?.null?.map((v, index) => (
                    <VisualItem
                      key={v.id}
                      visual={v}
                      index={`Uncategorized_${index}`}
                      activeVisual={activeVisual}
                      handleActive={handleActive}
                      onDragStart={onDragStart}
                    />
                  ))}
                </Grid>
              </Groups>
            </>
          )}
        </Box>
      )}
    </Root>
  );
};

export default Sidebar;
