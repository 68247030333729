import React from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import { SettingsOutlined, ExtensionOffOutlined } from '@mui/icons-material';
import ManageApps from './ManageApps';
import { customUIMoreLinks } from '../../constants';
import { useGetControlSystemFeedQuery } from '../../redux/services/systems/controlApi';
import { useAppSelector } from '../../redux/store';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import { withHidden } from '../../hoc';

type EmptyMenuProps = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
};

const EmptyMenu = (props: EmptyMenuProps): JSX.Element => {
  const { anchorEl, handleClose } = props;

  return (
    <Menu
      id="more-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        '& .MuiMenu-paper': {
          padding: '12px',
          maxHeight: 'calc(100% - 32px)',
        },
        '& ul': {
          display: 'flex',
          gap: '12px',
        },
      }}
    >
      <ExtensionOffOutlined />
      No FNS apps installed
    </Menu>
  );
};

const Icon = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: theme.shape.borderRadius,
  '&:not(.MuiAvatar-colorDefault)': {
    backgroundColor: '#fff',
  },
}));

const IconWrap = styled(ListItemAvatar)({
  minWidth: 36,
});

const SvgIconWrap = styled(Box)({
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

interface ManageAppsItemProps {
  handleManageOpen: () => void;
}

const ManageAppsItem = ({ handleManageOpen }: ManageAppsItemProps) => (
  <>
    <Box sx={{ px: '6px', my: 1 }}>
      <Divider />
    </Box>
    <MenuItem
      onClick={handleManageOpen}
      id="MoreMenu-manage-app"
      sx={{ p: '6px' }}
    >
      <IconWrap>
        <SvgIconWrap>
          <SettingsOutlined />
        </SvgIconWrap>
      </IconWrap>
      <ListItemText primary="Manage apps" />
    </MenuItem>
  </>
);

const ManageAppsItemHidden = withHidden(ManageAppsItem);

const MoreDropdown = (props: Props) => {
  const { anchorEl, handleClose } = props;
  const [openManage, setOpenManage] = React.useState(false);
  const isDemo = useAppSelector((state: IAppState) => state.isDemo);

  const { data } = useGetControlSystemFeedQuery();

  const handleManageOpen = () => {
    handleClose();
    setOpenManage(true);
  };

  const handleManageClose = () => {
    setOpenManage(false);
  };

  if (!data && !isDemo) {
    return <EmptyMenu anchorEl={anchorEl} handleClose={handleClose} />;
  }

  return (
    <>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            maxHeight: 'calc(100% - 32px)',
          },
        }}
      >
        {data !== undefined
          ? data.map((customUI) => (
              <MenuItem
                key={customUI.name}
                onClick={handleClose}
                component={Link}
                to={`/more/${customUI.name}`}
                sx={{ p: '6px' }}
                disabled={!customUI.link}
              >
                <IconWrap>
                  <Icon src="/" alt={customUI.view.description} />
                </IconWrap>
                <ListItemText primary={customUI.view.description} />
              </MenuItem>
            ))
          : null}
        {isDemo
          ? customUIMoreLinks.map((customUI) => (
              <MenuItem
                key={customUI.name}
                onClick={handleClose}
                component={Link}
                to={`${customUI.link}`}
                sx={{ p: '6px' }}
                disabled={!customUI.link}
              >
                <IconWrap>
                  <Icon src={customUI.image ?? '/'} alt={customUI.name} />
                </IconWrap>
                <ListItemText primary={customUI.name} />
              </MenuItem>
            ))
          : null}
        <ManageAppsItemHidden handleManageOpen={handleManageOpen} />
      </Menu>
      <ManageApps open={openManage} onClose={handleManageClose} />
    </>
  );
};

export default MoreDropdown;
