/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const identity = (data: any) => data;

export const isFetchBaseQueryErrorType = (
  error: any,
): error is FetchBaseQueryError => {
  if (typeof error === 'boolean') return false;
  return error && 'status' in error;
};

export const isSerializedError = (error: any): error is SerializedError => {
  if (typeof error === 'boolean') return false;
  return error && 'data' in error;
};

export const isForbiddenError = (error: any): boolean =>
  isFetchBaseQueryErrorType(error) && error.status === 403;

export const isBadGatewayError = (error: any): boolean =>
  isFetchBaseQueryErrorType(error) &&
  (error.status === 502 ||
    ('originalStatus' in error && error.originalStatus === 502));

export const isUnauthorizedError = (error: any): boolean =>
  isFetchBaseQueryErrorType(error) &&
  (error.status === 401 ||
    ('originalStatus' in error && error.originalStatus === 401));

export const tryGetErrorMessageOrNull = (error: any): string | null => {
  if (
    !error ||
    !isSerializedError(error) ||
    !('data' in error) ||
    typeof error.data !== 'object' ||
    !('message' in error.data) ||
    typeof error.data.message !== 'string'
  )
    return null;
  return error.data.message;
};
