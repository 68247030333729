import React from 'react';
import { styled, Box } from '@mui/material';
import { a11yProps } from '../../../utils/helper';
import { Tabs, Tab } from '../../../common/styles/Tabs';

interface Props {
  onChange: any;
  value: string;
}

const TabsWrap = styled(Box)(({ theme }) => ({
  bottom: 0,
  backgroundColor: theme.palette.background.default,
  left: 0,
  position: 'fixed',
  right: 0,
  zIndex: 99,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  border:
    theme.palette.mode === 'light' ? 0 : `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: '4px',
  marginBottom: 0,
  [theme.breakpoints.down('md')]: {
    borderBottom: 0,
    borderLeft: 0,
    borderRadius: 0,
    borderRight: 0,
    padding: 0,
    width: '100%',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      bottom: 'auto',
      top: 0,
      display: 'inline',
    },
  },
  '& .MuiTabs-scroller': {
    overflow: 'auto !important',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '2.5rem',
  [theme.breakpoints.down('md')]: {
    minHeight: '3.1rem',
    flexGrow: 1,
    '&.Mui-selected': {
      borderRadius: 0,
    },
  },
}));

const ConfigTabs = (props: Props) => {
  const { onChange, value } = props;
  return (
    <TabsWrap>
      <StyledTabs
        sx={{ display: 'flex' }}
        value={value}
        onChange={onChange}
        aria-label="project config tabs"
        variant="fullWidth"
      >
        <StyledTab label="Editor" {...a11yProps('0')} />
        <StyledTab label="Diagram" {...a11yProps('1')} />
      </StyledTabs>
    </TabsWrap>
  );
};

export default ConfigTabs;
