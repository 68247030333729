import { alpha, Box, Button, styled } from '@mui/material';

export const SwitchWrap = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  minHeight: '36px',
  padding: '0 2px',
  display: 'inline-flex',
  marginLeft: theme.spacing(2),
}));

export const SwitchBtn = styled(Button)(({ theme }) => ({
  height: '30px',
  minWidth: '30px',
  minHeight: '30px',
  padding: '4px 6px',
  marginTop: '2px',
  fontWeight: 400,
  color:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.secondary.main, 0.5)
      : alpha(theme.palette.text.primary, 0.5),
  '& svg': {
    color:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.5)
        : alpha(theme.palette.text.primary, 0.5),
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '1rem',
    minHeight: '30px',
    height: '30px',
  },
  '&.active': {
    fontWeight: 600,
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.background.default
        : theme.palette.bg.dark,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.secondary.main
        : theme.palette.text.primary,
    '& svg': {
      color:
        theme.palette.mode === 'light' ? theme.palette.secondary.main : '#fff',
    },
  },
  '& .icon': {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));
