import React from 'react';
import { Resizable } from 're-resizable';
import { Box } from '@mui/material';

interface Props {
  id: string;
  data: any;
}

const Alphinity = (props: Props) => {
  const { data } = props;
  const { size } = data || {};
  const { width, height } = size || {};
  const defaultSize = { width: width || 302, height: height || 120 };

  return (
    <Box
      component={Resizable}
      defaultSize={defaultSize}
      lockAspectRatio
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        backgroundImage: `url('/images/library/secondary_logo.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '& .resize-handle': {
          width: '20% !important',
          height: '20% !important',
          right: '0 !important',
          bottom: '10% !important',
          display: 'none',
        },
        '& .edit-btn': {
          width: '9%',
          height: '25%',
          minWidth: '1.5rem',
          minHeight: '1.5rem',
          position: 'absolute',
          right: '-15px',
          top: '0',
          opacity: 0,
          '& svg': {
            width: '60%',
            height: '60%',
          },
        },
      }}
    />
  );
};

export default Alphinity;
