import React, { useState, useEffect } from 'react';
import { Resizable } from 're-resizable';
import { Handle, Position } from 'react-flow-renderer';
import { styled, Box } from '@mui/material';
import { useGetPortsQuery } from '../../../../redux/services/ports/api';

const SVG = styled('svg')({
  width: '100%',
  height: '100%',
});

interface Props {
  id: string;
  data?: any;
}

function Motor(props: Props) {
  const { id, data: propsData } = props;
  const { size } = propsData || {};
  const { width, height } = size || {};
  const defaultSize = { width: width || 80, height: height || 80 };

  const { data } = useGetPortsQuery(undefined);

  const [ports, setPorts] = useState([]);
  useEffect(() => {
    if (ports) {
      setPorts(data.filter(({ parentAggregate }) => parentAggregate === id));
    }
  }, [ports]);

  return (
    <Box
      component={Resizable}
      defaultSize={defaultSize}
      lockAspectRatio
      resizeRatio={1}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .resize-handle': {
          width: '35% !important',
          height: '35% !important',
          right: '0 !important',
          bottom: '-60% !important',
          display: 'none',
        },
      }}
    >
      <div>
        {ports.length ? (
          ports.map((p, i) => (
            <Handle
              key={p._id}
              className="point"
              type={p.name === 'Outlet' ? 'target' : 'source'}
              position={
                [Object.values(Position)[0], Object.values(Position)[2]][i]
              }
              style={
                [
                  { left: '-2%', top: '70%' },
                  { right: '24%', top: '70%' },
                ][i]
              }
            />
          ))
        ) : (
          <>
            <Handle
              className="point"
              type="target"
              position={Position.Left}
              style={{ left: '-2%', top: '70%' }}
            />
            <Handle
              className="point"
              type="source"
              position={Position.Right}
              id="a"
              style={{ right: '24%', top: '70%' }}
            />
          </>
        )}

        <SVG
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="80"
          // height="80"
          viewBox="0 0 101.758 137.438"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              y1="64.364"
              x2="1"
              y2="64.364"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#ccc" />
              <stop offset="0.16" stopColor="#c6c6c6" />
              <stop offset="0.36" stopColor="#b7b7b7" />
              <stop offset="0.4" stopColor="#b3b3b3" />
              <stop offset="0.6" stopColor="#c1c1c1" />
              <stop offset="0.99" stopColor="#e5e5e5" />
              <stop offset="1" stopColor="#e6e6e6" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-2"
              y1="13.121"
              x2="1"
              y2="13.121"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#b3b3b3" />
              <stop offset="0.2" stopColor="#b1b1b1" />
              <stop offset="0.28" stopColor="#aaa" />
              <stop offset="0.33" stopColor="#9e9e9e" />
              <stop offset="0.37" stopColor="#8e8e8e" />
              <stop offset="0.4" stopColor="gray" />
              <stop offset="0.41" stopColor="#828282" />
              <stop offset="0.57" stopColor="#a2a2a2" />
              <stop offset="0.73" stopColor="#b9b9b9" />
              <stop offset="0.87" stopColor="#c7c7c7" />
              <stop offset="1" stopColor="#ccc" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-3"
              y1="-19.987"
              x2="1"
              y2="-19.987"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#b3b3b3" />
              <stop offset="0.1" stopColor="#adadad" />
              <stop offset="0.23" stopColor="#9e9e9e" />
              <stop offset="0.38" stopColor="#848484" />
              <stop offset="0.4" stopColor="gray" />
              <stop offset="0.54" stopColor="#8e8e8e" />
              <stop offset="0.82" stopColor="#b2b2b2" />
              <stop offset="1" stopColor="#ccc" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-4"
              x1="0"
              y1="0.002"
              x2="1"
              y2="0.002"
              xlinkHref="#linear-gradient-3"
            />
            <linearGradient
              id="linear-gradient-5"
              y1="8.629"
              x2="1"
              y2="8.629"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#b3b3b3" />
              <stop offset="0.4" stopColor="#b3b3b3" />
              <stop offset="0.57" stopColor="#c1c1c1" />
              <stop offset="0.89" stopColor="#e5e5e5" />
              <stop offset="1" stopColor="#f2f2f2" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-6"
              x1="0.074"
              y1="7.655"
              x2="0.957"
              y2="5.245"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#ccc" />
              <stop offset="0.07" stopColor="#c9c9c9" />
              <stop offset="0.12" stopColor="silver" />
              <stop offset="0.17" stopColor="#b0b0b0" />
              <stop offset="0.21" stopColor="#999" />
              <stop offset="0.31" stopColor="#b3b3b3" />
              <stop offset="0.44" stopColor="#cfcfcf" />
              <stop offset="0.57" stopColor="#e2e2e2" />
              <stop offset="0.7" stopColor="#eee" />
              <stop offset="0.84" stopColor="#f2f2f2" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-7"
              x1="0.498"
              y1="-6.324"
              x2="0.498"
              y2="-5.324"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0.04" stopColor="#b3b3b3" />
              <stop offset="0.55" stopColor="#e6e6e6" />
              <stop offset="0.56" stopColor="#e5e5e5" />
              <stop offset="0.85" stopColor="#c1c1c1" />
              <stop offset="1" stopColor="#b3b3b3" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-8"
              x1="0.5"
              y1="-6.33"
              x2="0.5"
              y2="-5.329"
              xlinkHref="#linear-gradient-7"
            />
            <linearGradient
              id="linear-gradient-9"
              x1="0.502"
              y1="-6.923"
              x2="0.502"
              y2="-5.923"
              xlinkHref="#linear-gradient-7"
            />
            <linearGradient
              id="linear-gradient-10"
              x1="0.5"
              y1="-15.839"
              x2="0.5"
              y2="-14.84"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#e6e6e6" />
              <stop offset="0.18" stopColor="#e1e1e1" />
              <stop offset="0.4" stopColor="#d3d3d3" />
              <stop offset="0.64" stopColor="#bbb" />
              <stop offset="0.7" stopColor="#b3b3b3" />
              <stop offset="1" stopColor="#e6e6e6" />
            </linearGradient>
          </defs>
          <g
            id="motor_2"
            data-name="motor 2"
            transform="translate(-436.229 -412.285)"
          >
            <rect
              id="Rectangle_448"
              data-name="Rectangle 448"
              width="48.528"
              height="1.663"
              transform="translate(449.764 412.7)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="0.83"
              fill="url(#linear-gradient)"
            />
            <rect
              id="Rectangle_449"
              data-name="Rectangle 449"
              width="73.257"
              height="7.678"
              transform="translate(437.4 414.363)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-2)"
            />
            <rect
              id="Rectangle_450"
              data-name="Rectangle 450"
              width="73.257"
              height="7.678"
              transform="translate(437.4 541.459)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-3)"
            />
            <path
              id="Path_162"
              data-name="Path 162"
              d="M510.657,693.172H437.4V593.3h73.257v66.043l-2.351,2.543v16.843l2.351,2.319Z"
              transform="translate(0 -151.713)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-4)"
            />
            <rect
              id="Rectangle_451"
              data-name="Rectangle 451"
              width="58.621"
              height="9.773"
              transform="translate(444.71 422.041)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-5)"
            />
            <path
              id="Path_163"
              data-name="Path 163"
              d="M510.657,541.973H437.4l7.31-9.773h58.637Z"
              transform="translate(0 -100.386)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-6)"
            />
            <rect
              id="Rectangle_452"
              data-name="Rectangle 452"
              width="3.599"
              height="16.427"
              transform="translate(508.305 510.381)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-7)"
            />
            <path
              id="Path_164"
              data-name="Path 164"
              d="M908.094,1039.107l-4.894.7V1023.4l4.894.7Z"
              transform="translate(-391.296 -513.019)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-8)"
            />
            <rect
              id="Rectangle_453"
              data-name="Rectangle 453"
              width="3.279"
              height="15.003"
              transform="translate(516.799 511.085)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-9)"
            />
            <rect
              id="Rectangle_454"
              data-name="Rectangle 454"
              width="17.306"
              height="6.558"
              transform="translate(520.094 515.307)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
              fill="url(#linear-gradient-10)"
            />
            <line
              id="Line_3"
              data-name="Line 3"
              x2="62.06"
              transform="translate(442.998 434.117)"
              fill="none"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.174"
            />
          </g>
        </SVG>
      </div>
    </Box>
  );
}

Motor.defaultProps = {
  data: {},
};

export default Motor;
