import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { setActiveSites } from '../redux/modules/sites/slice';
import { useGetSitesQuery } from '../redux/services/sites/api';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { isForbiddenError } from '../redux/utils';

const ALL_SITES = 'ALLSITES';

interface Props {
  sx?: any;
  selection: string[];
  id?: string;
  setSelection: Dispatch<SetStateAction<string[]>>;
}

const Control = styled(FormControl)(({ theme }) => ({
  marginBottom: 0,
  marginLeft: theme.spacing(2),
  '& .MuiOutlinedInput-root, & .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: 'transparent',
  },
}));

const Label = styled(InputLabel)(({ theme }) => ({
  transform: 'translate(10px, -9.5px) scale(1)',
  backgroundColor: theme.palette.background.default,
  padding: '0 4px',
  width: 'auto',
}));

const Option = styled(MenuItem)({
  padding: '0 !important',
  display: 'flex',
  justifyContent: 'flex-start',
});

const SiteFilter = (props: Props) => {
  const { sx, selection, setSelection, id } = props;
  const { data: sites = [], error } = useGetSitesQuery();
  const dispatch = useAppDispatch();
  const activeSites = useAppSelector((state) => state.activeSites.activeSites);
  const isSiteDropdownDisabled = isForbiddenError(error);

  useEffect(() => {
    if (
      sites &&
      sites.length > 0 &&
      (!activeSites || activeSites.length === 0)
    ) {
      dispatch(
        setActiveSites({ activeSites: sites.map((it) => it.id), loaded: true }),
      );
    }
  }, [sites]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { value: newVal } = event.target;
    if (
      selection.length === 1 &&
      newVal.length === 0 &&
      selection[0] === ALL_SITES
    ) {
      return;
    }
    if (typeof newVal !== 'string') {
      if (
        (activeSites.length < sites.length && newVal.includes(ALL_SITES)) ||
        newVal.length > sites.length ||
        newVal.length === 0
      ) {
        setSelection([ALL_SITES]);
        dispatch(
          setActiveSites({
            activeSites: sites.map((it) => it.id),
            loaded: true,
          }),
        );
      } else {
        const withoutAllSites = newVal.filter((it) => it !== ALL_SITES);
        setSelection(withoutAllSites);
        dispatch(
          setActiveSites({ activeSites: withoutAllSites, loaded: true }),
        );
      }
    }
  };

  const allSitesActive = selection?.includes(ALL_SITES);

  return (
    <Control
      variant="outlined"
      sx={{ ...sx, minWidth: '122px' }}
      disabled={isSiteDropdownDisabled}
    >
      <Label id="site-label">Site</Label>
      <Select
        labelId="site-label"
        id={id || 'siteFilter'}
        multiple
        value={selection || [ALL_SITES]}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={() =>
          allSitesActive
            ? 'All sites'
            : sites
                .filter((it) => activeSites.includes(it.id))
                .map((it) => it.name)
                .join(' | ')
        }
      >
        <Option value={ALL_SITES} id="SiteSelector-all">
          <Checkbox checked={allSitesActive} />
          All sites
        </Option>
        {sites.map((site) => (
          <Option key={site.id} value={site.id} id={`SiteSelector-${site.id}`}>
            <Checkbox checked={selection?.includes(site.id)} />
            {site.name}
          </Option>
        ))}
      </Select>
    </Control>
  );
};

SiteFilter.defaultProps = {
  sx: {},
  id: '',
};

export default SiteFilter;
