import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { BrokenImageRounded } from '@mui/icons-material';
import { useGetVisualAssetQuery } from '../../../redux/services/visuals/api';
import { VisualResponse } from '../../../redux/services/visuals/types';
import { ImagePreview } from './styles';

interface TooltipProps {
  name: string;
  visual: VisualResponse;
}

const TooltipContent = (props: TooltipProps) => {
  const { name, visual } = props;
  const { id, version } = visual;
  const { data: imageText, isLoading } = useGetVisualAssetQuery(
    {
      id,
      version,
    },
    { skip: !id },
  );

  const image = useMemo(() => {
    if (!imageText) return null;
    const svg = new Blob([imageText], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svg);
    return url;
  }, [imageText]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          width: 100,
          height: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2,
          mx: 'auto',
        }}
      >
        {!image || isLoading ? (
          <BrokenImageRounded
            sx={{
              color: 'secondary.main',
              opacity: 0.75,
              fontSize: '3rem',
            }}
          />
        ) : (
          <ImagePreview
            src={image}
            alt={name}
            id={`FlairsTooltip-${name.replace(/\s+/g, '-').toLowerCase()}`}
          />
        )}
      </Box>
      <Typography>{name}</Typography>
    </Box>
  );
};

export default TooltipContent;
