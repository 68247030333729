import React, { useCallback, useMemo, useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  styled,
  Tooltip,
} from '@mui/material';
import { StarOutlineRounded, StarRounded } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import useSnackbar from '../hooks/useSnackbar';
import { setQuickAccess } from '../redux/modules/quickAccess';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import {
  useCreatePreferencesMutation,
  useGetPreferencesQuery,
  usePatchPreferencesMutation,
} from '../redux/services/preferences/api';

interface Props {
  asListItem?: boolean;
  className?: string;
  id?: string;
}

const FavBtn = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiSvgIcon-root': {
    fontSize: '1.75rem',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.text.secondary
        : theme.palette.secondary.main,
  },
}));

const QuickAccessButton = (props: Props) => {
  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );
  const { className, asListItem, id: tid } = props;
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { showSuccess } = useSnackbar();
  const { data: preferences, isError } = useGetPreferencesQuery();

  const [changePreference] = usePatchPreferencesMutation();
  const [createPreference] = useCreatePreferencesMutation();

  const path = useMemo(() => {
    const lastPathValue = location.pathname.split('/').at(-1);
    const ifProjectPage =
      lastPathValue === 'overview' ||
      lastPathValue === 'software' ||
      lastPathValue === 'systems';

    return ifProjectPage
      ? location.pathname.split('/').slice(0, -1).join('/')
      : location.pathname;
  }, [location.pathname]);

  const id = useMemo(
    () => params?.id || location?.state?.id,
    [params, location],
  );

  const quickAccessEdge = useAppSelector(
    (state: IAppState) => state.quickAccess,
  );
  const isExists = useMemo(() => {
    if (isEdgeEnv)
      return quickAccessEdge?.filter((item) => item === path).length === 1;
    return preferences?.preferences?.quickAccess
      ? preferences?.preferences?.quickAccess?.findIndex(
          (_id) => _id === path,
        ) !== -1
      : false;
  }, [preferences, id, quickAccessEdge]);

  const handleModify = () => {
    if (isEdgeEnv) {
      dispatch(setQuickAccess(path));
      return;
    }
    if (isError || !preferences?.preferences) {
      createPreference({
        preferences: {
          quickAccess: [path],
        },
      });
      return;
    }
    if (isExists) {
      changePreference({
        preferences: {
          quickAccess: [
            ...preferences.preferences.quickAccess.filter(
              (item) => item !== path,
            ),
          ],
        },
      });
    } else {
      changePreference({
        preferences: {
          quickAccess: preferences.preferences.quickAccess
            ? [...preferences.preferences.quickAccess, path]
            : [path],
        },
      });
    }
  };

  const handleSetFav = useCallback(() => {
    showSuccess(
      isExists ? 'Removed from quick access' : 'Added to quick access',
    );
    handleModify();
  }, [isExists]);

  return (
    <>
      {asListItem ? (
        <MenuItem id={tid} onClick={handleSetFav} className={className}>
          <ListItemIcon>
            {isExists ? (
              <StarRounded sx={{ mr: 1, ml: '-2px' }} />
            ) : (
              <StarOutlineRounded sx={{ mr: 1, ml: '-2px' }} />
            )}
          </ListItemIcon>
          {isExists ? 'Remove from quick access' : 'Add to quick access'}
        </MenuItem>
      ) : (
        <Tooltip
          title={isExists ? 'Remove from quick access' : 'Add to quick access'}
          PopperProps={{
            container: () => document.querySelector('.fullscreen'),
          }}
        >
          <FavBtn className={className} onClick={handleSetFav} id={tid}>
            {isExists ? <StarRounded /> : <StarOutlineRounded />}
          </FavBtn>
        </Tooltip>
      )}
    </>
  );
};

QuickAccessButton.defaultProps = {
  className: '',
  id: '',
  asListItem: false,
};

export default QuickAccessButton;
