import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function IOIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M48-61v-99h864v99H783v-25H545v25H415v-25H177v25H48Zm182-242q-19.75 0-33.375-13.625T183-350v-500q0-19.75 13.625-33.375T230-897h500q19.75 0 33.375 13.625T777-850v510q0 16.5-10.25 26.75T740-303H230Zm27-74h446v-446H257v446Zm90-261h266v-75H347v75Zm-90 261v-446 446Z" />
    </SvgIcon>
  );
}

export default IOIcon;
