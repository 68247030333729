import { handleActions } from 'redux-actions';
import { setRedirect, setRedirectEmpty } from './actions';

const initialState = {
  route: '',
  state: {},
};

const redirectReducer = handleActions(
  {
    [setRedirect]: (state, { payload }) => ({
      route: payload.route,
      state: payload.state,
    }),
    [setRedirectEmpty]: () => ({ route: '', state: {} }),
  },
  initialState,
);

export default redirectReducer;
