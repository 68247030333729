import React, { useEffect, useMemo, useState } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useDropzone } from 'react-dropzone';
import { Alert, IconButton, Tooltip, Typography } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { UploadWrap } from '../../../../Dialogs/styles';
import { Item } from './styles';
import {
  BulkImportItem,
  BulkImportResponse,
} from '../../../../../redux/services/visuals/api';
import { IError } from '../../../../../hooks/useSnackbar';
import { formatErrorMessage } from '../../../../../utils/helper';

interface Props {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadError: FetchBaseQueryError | IError | SerializedError;
  uploadMessage: BulkImportResponse;
  onParentClose: () => void;
  setIsResponseOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setMessagesParsed: React.Dispatch<
    React.SetStateAction<(BulkImportItem & { name: string })[]>
  >;
}

const ImportComponent = (props: Props) => {
  const {
    files,
    setFiles,
    uploadMessage,
    uploadError,
    onParentClose,
    setIsResponseOpened,
    setMessagesParsed,
  } = props;

  const [error, setError] = useState<string | undefined>(undefined);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    accept: '.tar.gz',
    maxFiles: 1,
  });

  const handleRemoveFile = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setFiles([]);
  };

  const messageParsed = useMemo(() => {
    if (!uploadMessage) return undefined;
    const arrayOfVisuals = Object.entries(uploadMessage).map(
      ([name, value]) => ({ name, ...value }),
    );

    setIsResponseOpened(true);
    onParentClose();

    return arrayOfVisuals;
  }, [uploadMessage]);

  useEffect(() => {
    setMessagesParsed(messageParsed);
  }, [messageParsed]);

  useEffect(() => {
    if (!uploadError) return;
    setError(formatErrorMessage(uploadError));
  }, [uploadError]);

  useEffect(() => {
    setError(undefined);
  }, [files]);

  return (
    <>
      {error && (
        <Alert sx={{ mb: 1 }} severity="error">
          {error}
        </Alert>
      )}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {files.length < 1 ? (
          <UploadWrap id="ImportExport-dropzone-div">
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag & drop a &quot;.tar.gz&quot; file or click to upload</p>
            )}
          </UploadWrap>
        ) : (
          files.map((file) => (
            <Item key={file.name}>
              <Typography>{file.name}</Typography>
              <Tooltip title="Remove" onClick={handleRemoveFile}>
                <IconButton size="small" sx={{ mr: '-4px', opacity: 0.6 }}>
                  <CloseRounded />
                </IconButton>
              </Tooltip>
            </Item>
          ))
        )}
      </div>
    </>
  );
};

export default ImportComponent;
