import React from 'react';
import { Resizable } from 're-resizable';
import { TextField, Box } from '@mui/material';

interface Props {
  id: string;
  data?: any;
}

const TextInputField = (props: Props) => {
  const { data } = props;
  const { size } = data || {};
  const { width, height } = size || {};
  const defaultSize = { width: width || 150, height: height || 60 };

  return (
    <Box
      component={Resizable}
      defaultSize={defaultSize}
      resizeRatio={1.25}
      minWidth="90px"
      minHeight="48px"
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '& .resize-handle': {
          width: '1.5rem !important',
          height: '1.5rem !important',
          right: '0 !important',
          bottom: '0 !important',
          minWidth: '1.5rem',
          minHeight: '1.5rem',
        },
      }}
    >
      <TextField
        multiline
        placeholder="Write something"
        defaultValue="Text field"
        sx={{
          marginBottom: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto',
          '& .MuiInputBase-multiline': {
            height: '100%',
            borderRadius: 0,
            '& textarea': {
              borderRadius: 0,
            },
          },
        }}
      />
    </Box>
  );
};

TextInputField.defaultProps = {
  data: {},
};

export default TextInputField;
