import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  DialogContent,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material';
import DialogComponent from '../../../Dialogs/DialogComponent';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import {
  BulkImportItem,
  useBulkImportMutation,
} from '../../../../redux/services/visuals/api';
import { apiBaseUrlV1 } from '../../../../env';
import { StyledLabel } from './ImportExportContent/styles';
import ImportComponent from './ImportExportContent/Import';
import ExportComponent from './ImportExportContent/Export';
import { VisualResponse } from '../../../../redux/services/visuals/types';
import ImportResultsComponent from './ImportExportContent/ImportResultsComponent';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const bulkExport = ({
  ids,
  categories,
  setLoading,
}: {
  ids?: VisualResponse[];
  categories?: string[];
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  let params = '?';
  if (ids?.length > 0)
    params = params.concat(ids.map(({ id }) => `ids=${id}`).join('&'));
  if (ids?.length > 0 && categories?.length > 0) params = params.concat('&');
  if (categories?.length > 0)
    params = params.concat(
      categories.map((id) => `categories=${id}`).join('&'),
    );
  if (setLoading) setLoading(true);
  axios
    .get(`${apiBaseUrlV1('structure/v1/visualsExport')}${params}`, {
      responseType: 'blob',
    })
    .then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `${categories?.[0] || ids?.[0]?.name}.tar.gz`,
      );
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .finally(() => {
      setLoading(false);
    });
};

const ImportExport = (props: Props) => {
  const { open, onClose } = props;
  const [selectedValue, setSelectedValue] = useState<'import' | 'export'>(
    'import',
  );
  const [groupName, setGroupName] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [isBulkExportLoading, setIsBulkExportLoading] =
    useState<boolean>(false);
  const [isResponseOpened, setIsResponseOpened] = useState<boolean>(false);
  const [importResult, setImportResult] = useState<
    (BulkImportItem & { name: string })[]
  >([]);

  const [
    bulkImport,
    {
      data: bulkImportData,
      isLoading: isBulkImportLoading,
      error: bulkImportError,
      reset: bulkImportReset,
    },
  ] = useBulkImportMutation();

  const handleGroupChange = (ev: SelectChangeEvent<string[] | unknown>) => {
    const { value } = ev.target;
    setGroupName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : (value as string[]),
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(
      (event.target as HTMLInputElement).value as 'import' | 'export',
    );
  };

  const handleAction = () => {
    if (selectedValue === 'import') {
      const formData = new FormData();
      formData.append('archive', files[0]);
      bulkImport(formData);
      return;
    }
    bulkExport({ categories: groupName, setLoading: setIsBulkExportLoading });
  };

  const closeActions = () => {
    bulkImportReset();
    setFiles([]);
    setGroupName([]);
    setSelectedValue('import');
  };

  const handleClose = () => {
    onClose();
    closeActions();
  };

  const handleCloseResult = () => {
    setIsResponseOpened(false);
    closeActions();
    setImportResult([]);
  };

  return (
    <>
      <DialogComponent
        isOpened={open}
        closeModal={handleClose}
        title="Import & Export"
        actionTitle={capitalizeFirstLetter(selectedValue)}
        handleAction={handleAction}
        isActionButtonDisabled={isBulkImportLoading || isBulkExportLoading}
        isButtonWithLoader
        isLoading={isBulkImportLoading || isBulkExportLoading}
      >
        <DialogContent>
          <RadioGroup
            row
            aria-labelledby="ImportExport-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChange}
            value={selectedValue}
            sx={{ mb: 4, gap: 2 }}
          >
            <StyledLabel
              id="ImportExport-radio-import"
              value="import"
              control={<Radio />}
              label="Import components"
              className={selectedValue === 'import' ? 'active' : ''}
            />
            <StyledLabel
              id="ImportExport-radio-export"
              value="export"
              control={<Radio />}
              label="Export components"
              className={selectedValue === 'export' ? 'active' : ''}
            />
          </RadioGroup>
          {selectedValue === 'import' ? (
            <ImportComponent
              files={files}
              setFiles={setFiles}
              uploadError={bulkImportError}
              uploadMessage={bulkImportData}
              onParentClose={onClose}
              setIsResponseOpened={setIsResponseOpened}
              setMessagesParsed={setImportResult}
            />
          ) : (
            <ExportComponent
              groupName={groupName}
              handleGroupChange={handleGroupChange}
            />
          )}
        </DialogContent>
      </DialogComponent>
      {isResponseOpened && (
        <ImportResultsComponent
          result={importResult}
          onClose={handleCloseResult}
        />
      )}
    </>
  );
};

export default ImportExport;
