import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const SOFTWARE_PACKAGE_TYPES = ['ENS', 'FNS'];

export enum SOFTWARE_PACKAGE_DESCRIPTION {
  ENS = 'Extremity Nervous System (ENS) are your projects that run on the EDGE and talk directly to hardware',
  FNS = 'Fusion Nervous System (FNS) are your projects for applications that run in the CLOUD and can see all systems',
}

type ProjectsState = {
  selectedProjectType: string;
  selectedFnsProjectId: string | undefined;
  selectedFnsPackageId: string | undefined;
};

const initialState = {
  selectedProjectType: SOFTWARE_PACKAGE_TYPES[0],
  selectedFnsProjectId: undefined,
  selectedFnsPackageId: undefined,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSelectedProjectType: (
      state: ProjectsState,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      selectedProjectType: action.payload,
    }),
    setSelectedFnsProjectId: (
      state: ProjectsState,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      selectedFnsProjectId: action.payload,
    }),
    setSelectedFnsPackageId: (
      state: ProjectsState,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      selectedFnsPackageId: action.payload,
    }),
  },
});

const { actions, reducer } = projectsSlice;
export const {
  setSelectedProjectType,
  setSelectedFnsProjectId,
  setSelectedFnsPackageId,
} = actions;
export default reducer;
