import React from 'react';
import { Checkbox, Grid } from '@mui/material';
import { XYPosition } from 'react-flow-renderer';
import { VisualResponse } from '../../../redux/services/visuals/types';
import { GridItem, ListItem } from './styles';
import EmptyVisualElement from './EmptyVisualElement';
import PreviewItemComponent from './PreviewItemComponent';

interface Props {
  visuals: VisualResponse[];
  expandedCategory: string | undefined;
  isListView: boolean;
  activeVisualId: string | undefined;
  handleActive: (val: string) => void;
  handleSelect: (val: string) => void;
  pasteHandler: ({
    nodeId,
    position,
  }: {
    nodeId: string;
    position?: XYPosition;
  }) => void;
  onClose: () => void;
  selectedVisuals: string[];
  searchValue: string;
}

const ElementsList = (props: Props) => {
  const {
    visuals,
    expandedCategory,
    isListView,
    activeVisualId,
    handleActive,
    handleSelect,
    pasteHandler,
    onClose,
    selectedVisuals,
    searchValue,
  } = props;

  const filteredVisuals =
    expandedCategory === undefined
      ? visuals
      : visuals.filter(({ category }) => {
          if (expandedCategory === 'Uncategorized') return category === null;
          return category === expandedCategory;
        });

  if (filteredVisuals.length < 1)
    return (
      <EmptyVisualElement
        searchValue={searchValue}
        isListView={isListView}
        error={undefined}
      />
    );

  return (
    <>
      {filteredVisuals?.map((visual, index) => (
        <React.Fragment key={visual.id}>
          {isListView ? (
            <Grid item xs={12}>
              <ListItem
                className={visual.id === activeVisualId ? 'active' : ''}
                onClick={() => handleActive(visual.id)}
                onDoubleClick={() => {
                  pasteHandler({ nodeId: visual.id });
                  onClose();
                }}
              >
                <Checkbox
                  sx={{ mr: 1, ml: '2px' }}
                  onClick={() => handleSelect(visual.id)}
                  checked={selectedVisuals.indexOf(visual.id) !== -1}
                />
                {visual.name}
              </ListItem>
            </Grid>
          ) : (
            <Grid item xs={6} sm={4}>
              <GridItem
                className={visual.id === activeVisualId ? 'active' : ''}
                onClick={() => handleActive(visual.id)}
                onDoubleClick={() => {
                  pasteHandler({ nodeId: visual.id });
                  onClose();
                }}
              >
                <PreviewItemComponent visual={visual} />
              </GridItem>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default ElementsList;
