import React, { useMemo } from 'react';
import BaseFlair from './BaseFlair';

interface Props {
  className?: string;
  data?: any;
  selected?: boolean;
}

const defaultMinWidth = 22;
const defaultMinHeight = 150;

const Bar = (props: Props) => {
  const { className, data, selected } = props;

  const { orientation } = data;

  const width = useMemo(
    () => (orientation === 'VERTICAL' ? defaultMinWidth : defaultMinHeight),
    [orientation],
  );
  const height = useMemo(
    () => (orientation === 'VERTICAL' ? defaultMinHeight : defaultMinWidth),
    [orientation],
  );

  return (
    <BaseFlair
      className={className}
      data={data}
      selected={selected}
      resizeRatio={height / width}
      lockAspectRatio
      defaultMinWidth={width}
      defaultMinHeight={height}
      content={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: '100%', height: '100%' }}
          key={`bar_${selected}_${width}_${height}`}
        >
          <rect
            style={{ width: '100%', height: '100%' }}
            rx="4"
            transform="translate(0 0)"
            fill="#4c9fc8"
            opacity="0.15"
          />
          <rect
            style={{ width: '100%', height: 0 }}
            rx="4"
            transform="translate(0 0) rotate(180 11 75)"
            fill="#4c9fc8"
          />
        </svg>
      }
    />
  );
};

Bar.defaultProps = {
  className: '',
  data: {},
  selected: false,
};

export default Bar;
