import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AlertMediumIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        id="Background"
        d="M7.472,3.968a1.846,1.846,0,0,1,3.395,0l6.092,11.905a1.966,1.966,0,0,1-1.844,2.8H3.2a1.966,1.966,0,0,1-1.844-2.8Z"
        transform="translate(2.842 1.328)"
        fill="#1e293b"
      />
      <path
        id="Background-2"
        data-name="Background"
        d="M0,0H24V24H0Z"
        fill="rgba(255,255,255,0)"
        opacity="0"
      />
      <path
        id="Path_172"
        data-name="Path 172"
        d="M-12458.469-14489.882h-12.274a1.824,1.824,0,0,1-1.6-.994,2.142,2.142,0,0,1-.021-2l6.138-11.969a1.816,1.816,0,0,1,1.625-1.038,1.819,1.819,0,0,1,1.625,1.038l6.14,11.969a2.144,2.144,0,0,1-.023,2A1.824,1.824,0,0,1-12458.469-14489.882Zm-6.137-4.91a.935.935,0,0,0-.927.942.937.937,0,0,0,.927.946.94.94,0,0,0,.931-.946A.938.938,0,0,0-12464.605-14494.792Zm0-7.178a.75.75,0,0,0-.741.756v4.533a.749.749,0,0,0,.741.755.751.751,0,0,0,.744-.755v-4.533A.752.752,0,0,0-12464.605-14501.97Z"
        transform="translate(12476.605 14509.882)"
        fill="#f5d93b"
      />
    </SvgIcon>
  );
}

export default AlertMediumIcon;
