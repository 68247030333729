import React from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import { useDispatch } from 'react-redux';
import DialogComponent from './DialogComponent';
import { setQuickAccess } from '../../redux/modules/quickAccess';

interface Props {
  systemName: string;
  open: boolean;
  onClose: () => void;
  handleDelete: () => void;
  id: string;
}

const DeleteSystem = (props: Props): JSX.Element => {
  const { systemName, open, onClose, handleDelete, id } = props;
  const dispatch = useDispatch();

  const getIdFromLink = (link) =>
    link.substr(link.split('').lastIndexOf('/') + 1);

  const removeFromFav = () => {
    const favEntinity = JSON.parse(localStorage.getItem('quickAccess'));
    const favEntinityId = favEntinity.map((e) => getIdFromLink(e));

    if (favEntinityId.find((item) => item === id)) {
      const newFavEntinity = favEntinity.filter(
        (item) => getIdFromLink(item) !== id,
      );

      dispatch(setQuickAccess(newFavEntinity));
    }
  };

  const deleteAction = () => {
    handleDelete();
    removeFromFav();
  };

  return (
    <DialogComponent
      isOpened={open}
      closeModal={onClose}
      title={`Delete ${systemName}`}
      actionTitle="Confirm"
      handleAction={deleteAction}
      cancelId="DeleteSystem-cancel"
      actionId="DeleteSystem-confirm"
    >
      <DialogContent>
        <DialogContentText>
          Please confirm if you want to delete <strong>{systemName}</strong>.
          <br />
          process is irreversible. All data for given system will be scrubbed
          and irretrievable.
        </DialogContentText>
      </DialogContent>
    </DialogComponent>
  );
};

DeleteSystem.defaultProps = {};
export default DeleteSystem;
