/* eslint-disable react/style-prop-object */
import React, { useMemo } from 'react';
import { Resizable } from 're-resizable';
import { Handle, Position } from 'react-flow-renderer';
import { Box, styled } from '@mui/material';
import { useGetComponentsQuery } from '../../../../redux/services/components/api';
import { OldComponentData } from '../../../../typescript/interfaces/oldComponentData.interface';

const SVG = styled('svg')({
  width: '100%',
  height: '100%',
});

const Label = styled('span')(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  zIndex: 99,
  left: '4%',
  right: '4%',
  top: '22%',
  lineHeight: '1.5',
  textAlign: 'center',
  color: '#484848',
  '& svg': {
    width: '100%',
  },
}));

interface Props {
  id: string;
  data?: OldComponentData;
}

function PhSensor(props: Props) {
  const { id, data } = props;
  const { size } = data || {};
  const { width, height } = size || {};
  const defaultSize = { width: width || 41.83, height: height || 84.87 };

  const { data: components } = useGetComponentsQuery(
    {
      systemId: data?.data?.systemId,
    },
    {
      skip: !data?.data?.systemId,
    },
  );

  const component = useMemo(
    () => components?.find((c: any) => c.id === id),
    [components, id],
  );
  const { name } = component || { name: 'PhSensor' };

  return (
    <Box
      component={Resizable}
      defaultSize={defaultSize}
      lockAspectRatio
      resizeRatio={1.25}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .resize-handle': {
          width: '40% !important',
          height: '40% !important',
          right: '0 !important',
          bottom: '-23% !important',
          display: 'none',
        },
      }}
    >
      <div>
        <Handle
          className="point"
          type="target"
          position={Position.Left}
          style={{ left: 0, top: '46%' }}
        />
        <Handle
          className="point"
          type="source"
          position={Position.Right}
          id="a"
          style={{ right: 0, top: '46%' }}
        />
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          // width="41.83"
          // height="84.87"
          viewBox="0 0 502.38 1018.47"
        >
          <g
            id="Layer_2"
            data-name="Layer 2"
            transform="translate(-498.81 -240.77)"
          >
            <path
              id="Path_162"
              data-name="Path 162"
              d="M983.28,735.4,852.43,865.53H645.94L516.56,735.37a40.39,40.39,0,0,1-11.75-28.49V279.3a32.52,32.52,0,0,1,32.53-32.53H962.66a32.52,32.52,0,0,1,32.53,32.53V706.75a40.45,40.45,0,0,1-11.91,28.65Z"
              fill="#eaeaea"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="12"
            />
            <path
              id="Path_163"
              data-name="Path 163"
              d="M656.84,812.46H841.53a10.9,10.9,0,0,1,10.9,10.9V1232.6a20.64,20.64,0,0,1-20.64,20.64H666.58a20.64,20.64,0,0,1-20.64-20.64V823.36a10.9,10.9,0,0,1,10.9-10.9Z"
              fill="#eaeaea"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="12"
            />
            <line
              id="Line_3"
              data-name="Line 3"
              x2="206.49"
              transform="translate(645.94 865.53)"
              fill="#eaeaea"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="12"
            />
            <circle
              id="Ellipse_62"
              data-name="Ellipse 62"
              cx="36.5"
              cy="36.5"
              r="36.5"
              transform="translate(572.94 326.02)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="12"
            />
            <circle
              id="Ellipse_63"
              data-name="Ellipse 63"
              cx="36.5"
              cy="36.5"
              r="36.5"
              transform="translate(713.5 1040.73)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="12"
            />
            <circle
              id="Ellipse_64"
              data-name="Ellipse 64"
              cx="36.5"
              cy="36.5"
              r="36.5"
              transform="translate(852.43 326.02)"
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="12"
            />
            <line
              id="Line_4"
              data-name="Line 4"
              x2="101.15"
              transform="translate(648.03 964.25)"
              fill="none"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="12"
            />
            <line
              id="Line_5"
              data-name="Line 5"
              x2="102.43"
              transform="translate(750 1181.17)"
              fill="none"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="12"
            />
            {/* <text
              id="PH"
              transform="translate(637.16 630.95)"
              fontSize="190.5"
              fontFamily="Helvetica"
            >
              <tspan x="0" y="0">
                {name}
              </tspan>
            </text> */}
          </g>
        </SVG>
        <Label>
          <svg viewBox="0 0 38 18">
            <text
              fontFamily="Source Sans Pro', sans-serif"
              fill="#334155"
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {name}
            </text>
          </svg>
        </Label>
      </div>
    </Box>
  );
}
PhSensor.defaultProps = {
  data: {},
};

export default PhSensor;
