import { useState, useEffect, useMemo, useRef } from 'react';
import {
  EventSourcePolyfill,
  EventSourcePolyfillInit,
  MessageEvent,
} from 'event-source-polyfill';
import useOidcCloud from '../hooks/useOidcCloud';

const closedKey = 'schema.paracloud.ai/system/stream/session/sse/closed';

interface CustomMessageEvent extends Event {
  status: number;
  statusText: string;
  type: string;
  target: EventSourcePolyfill;
}

interface EventSourceComponentProps {
  urlProp: string;
  handleStreamData: any;
  eventKeyType: string;
  sourceChangeKey?: string;
  setSubscriptionError?: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenedEvent?: () => void;
}

const PhaseEventSourceComponent = (props: EventSourceComponentProps) => {
  const {
    urlProp,
    eventKeyType,
    sourceChangeKey,
    setSubscriptionError,
    handleStreamData,
    handleOpenedEvent,
  } = props;
  const defaultEventKey = useMemo(
    () => `schema.paracloud.ai${eventKeyType}`,
    [eventKeyType],
  );

  const eventSource = useRef<EventSourcePolyfill>();

  const [isRunSSE, setIsRunSSE] = useState(true);
  const [isCleared, setIsCleared] = useState(false);
  const { accessToken } = useOidcCloud();

  const onError = (event: CustomMessageEvent) => {
    setIsRunSSE(false);
    if (setSubscriptionError) {
      console.info('Subscription has no data. Setting error');
      setSubscriptionError(true);
    }
  };

  const onClosed = () => {
    setIsRunSSE(false);
  };

  const onOpen = (event: any) => {
    console.log('opened event');
    handleOpenedEvent();
  };

  const onMessage = (event: any) => {
    handleStreamData(event);
  };

  const onSourceChange = (event: MessageEvent) => {
    console.info('source change');
  };

  useEffect(() => {
    if (urlProp) {
      if (eventSource?.current !== undefined) {
        setIsRunSSE(true);
        eventSource?.current.close();
        eventSource.current = undefined;
      }
    }
  }, [urlProp]);

  useEffect(() => {
    if (isRunSSE && eventSource.current === undefined && urlProp) {
      const esInit: EventSourcePolyfillInit = {
        withCredentials: !!accessToken,
      };

      if (accessToken) {
        esInit.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }

      eventSource.current = new EventSourcePolyfill(urlProp.toString(), esInit);
      // @ts-ignore
      eventSource.current.onerror = onError;
      eventSource.current.onopen = onOpen;
      // @ts-ignore
      eventSource.current.addEventListener(defaultEventKey, onMessage);
      eventSource.current.addEventListener(closedKey, onClosed);
      if (sourceChangeKey)
        // @ts-ignore
        eventSource.current.addEventListener(sourceChangeKey, onSourceChange);
    }
  }, [isRunSSE, urlProp, eventSource]);

  useEffect(() => {
    if (!isRunSSE && eventSource?.current !== undefined) {
      eventSource.current.removeEventListener(defaultEventKey, onMessage);
      eventSource.current.removeEventListener(closedKey, onClosed);
      eventSource.current = undefined;
    }
  }, [isRunSSE, eventSource]);

  useEffect(() => {
    if (isCleared) {
      setIsCleared(false);
    }
  }, [isCleared]);

  useEffect(
    () => () => {
      if (eventSource?.current) {
        eventSource.current?.removeEventListener(defaultEventKey, onMessage);
        eventSource.current?.removeEventListener(closedKey, onClosed);
        eventSource.current?.close();
        eventSource.current = undefined;
      }
    },
    [],
  );

  return null;
};

PhaseEventSourceComponent.defaultProps = {
  sourceChangeKey: undefined,
  setSubscriptionError: undefined,
  handleOpenedEvent: () => {},
};

export default PhaseEventSourceComponent;
