import React, { memo, SetStateAction } from 'react';
import { Box, styled } from '@mui/material';
import { ReactFlowProvider } from 'react-flow-renderer';
import DiagramComponent from '../../common/Diagram';
import { ButtonStateProps } from './overview/RunButton';

interface Props {
  isEditable?: boolean;
  className?: string;
  system: string;
  isFullscreen?: boolean;
  onFullscreenExit: any;
  onFullscreenEnter: any;
  withFullscreen?: boolean;
  withGrid?: boolean;
  button: any;
  componentsCustomUI: string[];
}

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  position: 'relative',
  backgroundColor: '#fff',
  '& .react-flow__node-default': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
  },
  '& .point.target': {
    backgroundColor: theme.palette.success.main,
  },
  '& .point.source': {
    backgroundColor: theme.palette.error.main,
  },
  '&.no-edit': {
    cursor: 'move',
    '& .point': {
      opacity: 0,
    },
    '& .edit-btn': {
      display: 'none',
    },
    '& .resize-handle': {
      display: 'none',
    },
  },
  '& .point-component': {
    '& .react-flow__handle': {
      // display: 'none !important',
    },
  },
}));

const SystemDiagram = (props: Props) => {
  const {
    className,
    system,
    isFullscreen,
    onFullscreenExit,
    onFullscreenEnter,
    withFullscreen,
    withGrid,
    isEditable,
    button,
    componentsCustomUI,
  } = props;

  return (
    <Root className={className}>
      <ReactFlowProvider>
        <DiagramComponent
          isEditable={isEditable}
          withFullscreen={withFullscreen}
          withGrid={withGrid}
          system={system}
          isFullscreen={isFullscreen}
          onFullscreenEnter={onFullscreenEnter}
          onFullscreenExit={onFullscreenExit}
          button={button}
          setButton={function (value: SetStateAction<ButtonStateProps>): void {
            throw new Error('Function not implemented.');
          }}
          componentsCustomUI={componentsCustomUI}
        />
      </ReactFlowProvider>
    </Root>
  );
};

SystemDiagram.defaultProps = {
  isEditable: false,
  className: '',
  isFullscreen: false,
  withFullscreen: false,
  withGrid: false,
};

export default memo(SystemDiagram);
