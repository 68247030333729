import React, { useState } from 'react';
import { Alert, Button, styled, Typography } from '@mui/material';
import { useAppSelector } from '../redux/store';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import EditProfile from '../components/user-role/users/EditProfile';

const WarningText = styled(Typography)({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '13px',
  lineHeight: '18.5px',
  textAlign: 'center',
  fontWeight: 600,
});

const PasswordExpirationBar = () => {
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const passwordExpirationInDays = useAppSelector(
    (state: IAppState) => state.user?.passwordExpirationInDays,
  );

  if (
    isPasswordUpdated ||
    !passwordExpirationInDays ||
    passwordExpirationInDays > 14
  ) {
    return null;
  }

  return (
    <>
      <Alert severity="warning">
        <WarningText>
          Your password expires in {passwordExpirationInDays} days. Please
          change it soon to maintain account access.
        </WarningText>
        <Button
          onClick={() => {
            setIsEditProfileOpen(true);
          }}
        >
          Update now
        </Button>
      </Alert>
      <EditProfile
        isOpened={isEditProfileOpen}
        closeModal={() => {
          setIsEditProfileOpen(false);
        }}
        onPasswordUpdated={() => {
          setIsPasswordUpdated(true);
        }}
      />
    </>
  );
};

export default PasswordExpirationBar;
