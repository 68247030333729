import React, { useEffect } from 'react';
import Loader from '../Loader';
import { REDIRECT_URI, ROUTES_PATH } from '../../constants';

export const errorOIDCStorage = 'oidc-error-state';

const handleForward = () => {
  if (sessionStorage.getItem(REDIRECT_URI)) {
    window.location.href = `${window.location.origin}${sessionStorage.getItem(
      REDIRECT_URI,
    )}`;
  } else {
    window.location.href = `${window.location.origin}${ROUTES_PATH.DASHBOARD}`;
  }
};

const errorsToReload = [
  'State not valid',
  'Error from OIDC server: request_forbidden',
];

const AuthenticatingErrorComponent = () => {
  const error = sessionStorage.getItem(errorOIDCStorage);

  useEffect(() => {
    if (errorsToReload.some((e) => error.indexOf(e) > -1)) {
      sessionStorage.removeItem(errorOIDCStorage);
      handleForward();
    }
  }, [error]);

  if (errorsToReload.some((e) => error.indexOf(e) > -1)) return <Loader />;

  return (
    <div className="oidc-authenticating">
      <div className="oidc-authenticating__container">
        <h1 className="oidc-authenticating__title">Error authentication</h1>
        <p className="oidc-authenticating__content">
          An error occurred during authentication.
        </p>
        {error && <div>{error}</div>}
      </div>
    </div>
  );
};

export default AuthenticatingErrorComponent;
