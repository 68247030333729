import React from 'react';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow={false} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: '0px 6px 16px rgba(74, 85, 104, 0.08)',
  },
}));
