import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';

const BASE_URL = apiBaseUrlV1('ubiety/v1/env/');

type EnvMetadata = {
  environmentDomain: string;
  environmentName: string;
};

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMetadata: builder.query<EnvMetadata, void>({
      query: () => BASE_URL,
    }),
    getLogo: builder.query<string, { isDark: boolean }>({
      query: ({ isDark }) => ({
        url: `${BASE_URL}logo.svg?dark=${isDark}`,
        headers: {
          'Content-Type': 'image/svg+xml',
        },
        responseHandler: 'content-type',
      }),
      providesTags: ['Logo'],
    }),
    updateLogo: builder.mutation<void, { formData: FormData }>({
      query: ({ formData }) => ({
        url: `${BASE_URL}logo.svg`,
        method: 'PUT',
        body: formData,
        formData: true,
      }),
      invalidatesTags: ['Logo'],
    }),
  }),
});

export const { useGetMetadataQuery, useGetLogoQuery, useUpdateLogoMutation } =
  extendedApi;
export const envLogoSrc = ({ isDark }: { isDark: boolean }) =>
  `${BASE_URL}logo.svg?dark=${isDark}`;
