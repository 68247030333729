import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M480-71q-11 0-21.963-4.435Q447.073-79.87 438-88L88-438q-8.13-9.073-12.565-20.037Q71-469 71-480q0-11 4.435-22.341Q79.87-513.683 88-522l350-350q9.073-8.87 20.037-12.935Q469-889 480-889q11 0 22.341 4.065Q513.683-880.87 522-872l350 350q8.87 8.317 12.935 19.659Q889-491 889-480q0 11-4.065 21.963Q880.87-447.073 872-438L522-88q-8.317 8.13-19.659 12.565Q491-71 480-71Zm0-60 349-349-349-349-349 349 349 349Zm-30-309h60v-240h-60v240Zm30 110q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-150Z" />
    </SvgIcon>
  );
}

export default WarningIcon;
