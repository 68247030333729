import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import { VisualResponse } from '../../../redux/services/visuals/types';
import TooltipContent from './TooltipContent';
import { GridItem } from './styles';
import { PreviewItemComponent } from './PreviewItemComponent';

interface VisualItemProps {
  visual: VisualResponse;
  index: string;
  activeVisual: string;
  handleActive: (value: string) => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>, id: string) => void;
}

const VisualItem = (props: VisualItemProps) => {
  const { visual, index, activeVisual, handleActive, onDragStart } = props;
  const { id, name } = visual;

  return (
    <Grid key={id} item xs={3}>
      <Tooltip
        placement="left"
        title={<TooltipContent name={name} visual={visual} />}
        sx={{
          transform: 'none !important',
          top: '50% !important',
          right: '350px !important',
        }}
      >
        <GridItem
          className={index === activeVisual ? 'active' : ''}
          onClick={() => handleActive(index)}
          onDragStart={(event) => onDragStart(event, id)}
          draggable
        >
          <PreviewItemComponent visual={visual} />
        </GridItem>
      </Tooltip>
    </Grid>
  );
};

export default VisualItem;
