import { Alert, Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import KratosLoginForm from '../../components/auth/KratosLoginForm';
import { REDIRECT_URI, ROUTES_PATH } from '../../constants';
import { AuthBox, Container, Logo, LogoWrap } from './styles';
import { useAppSelector } from '../../redux/store';
import {
  envLogoSrc,
  useGetMetadataQuery,
} from '../../redux/services/environment/api';
import { ssoStorage } from '../../env';
import useOidcCloud from '../../hooks/useOidcCloud';
import { redirectToReturnPath, setTokensUtil } from '../../utils/helper';
import { useGetCurrentUserQuery } from '../../redux/services/atreus/api';
import { tokenParserStorage } from '../../hoc/utils';
import { logoutStorage } from '../../utils/oidcConfig';

const Home = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isEdgeEnv = useAppSelector((state) => state.environment === 'edge');

  const [isSignInClicked, setIsSignInClicked] = useState(false);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const [hasInternetAccess, setHasInternetAccess] = useState(true);
  const history = useHistory();

  const { login, accessToken } = useOidcCloud();

  const { data: envMetadata, error: envMetadataError } = useGetMetadataQuery();
  const { data: user } = useGetCurrentUserQuery(accessToken, {
    skip: !accessToken && !isEdgeEnv,
  });

  const { search } = useLocation();
  const authExpired = React.useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('authExpired') === 'true';
  }, [search]);

  const handleRedirect = () => {
    setIsSignInClicked(true);
    login(`${window.location}dashboard`);
  };

  useEffect(() => {
    const handleToken = (e) => {
      if (e.key === ssoStorage() && e.newValue !== null) {
        setTimeout(() => {
          if (accessToken) {
            setTokensUtil(
              tokenParserStorage({
                storage: localStorage,
                entityName: ssoStorage(),
                searchValue: 'tokens',
              }),
            );
          }

          const redirectUri = sessionStorage.getItem(REDIRECT_URI);
          if (redirectUri && redirectUri !== '/')
            window.location.href = `${window.location.origin}${redirectUri}`;
          window.location.href = ROUTES_PATH.DASHBOARD;
        }, 1000);
      }
    };
    window.addEventListener('storage', handleToken);
    return function cleanup() {
      window.removeEventListener('storage', handleToken);
    };
  }, []);

  useEffect(() => {
    if (accessToken) {
      setTokensUtil(
        tokenParserStorage({
          storage: localStorage,
          entityName: ssoStorage(),
          searchValue: 'tokens',
        }),
      );
    }
  }, [accessToken]);

  useEffect(() => {
    // This flow applies auto-sign in for edge environments
    if (user && isEdgeEnv) {
      history.push(ROUTES_PATH.DASHBOARD);
    }
    // This flow applies auto-sign in for cloud environments
    if (user && !isEdgeEnv) {
      setIsSignInClicked(true);
      redirectToReturnPath(history);
      const redirectUri = sessionStorage.getItem(REDIRECT_URI);
      if (redirectUri) {
        window.location.href = `${window.location.origin}${redirectUri}`;
        sessionStorage.removeItem(REDIRECT_URI);
      }

      window.location.href = `${window.location.origin}${ROUTES_PATH.DASHBOARD}`;
    }
  }, [user]);

  useEffect(() => {
    if (localStorage.getItem(logoutStorage)) {
      localStorage.removeItem(logoutStorage);
    }
  }, []);

  useEffect(() => {
    if (!isEdgeEnv || !envMetadataError) {
      return;
    }
    if (
      'originalStatus' in envMetadataError &&
      envMetadataError.originalStatus > 500
    ) {
      setHasInternetAccess(false);
    }
  }, [envMetadataError, isEdgeEnv]);

  return (
    <Container>
      <AuthBox>
        <LogoWrap>
          <Logo
            key="multiLogo"
            src={envLogoSrc({ isDark })}
            alt={`${envMetadata?.environmentName || 'environment'} logo`}
            onLoadCapture={(logo) => {
              setIsLogoLoaded(true);
              return logo;
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.style.display = 'none';
            }}
          />
          {isLogoLoaded ? (
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '1.15rem',
                  mt: '3px',
                  mr: '4px',
                  color: 'text.secondary',
                }}
              >
                powered by
              </Typography>
              <img
                src={
                  isDark
                    ? './images/procaaso-white.svg'
                    : './images/procaaso.svg'
                }
                alt="ProCaaSo"
                width="90"
              />
            </Box>
          ) : (
            <Logo
              key="singularLogo"
              src={
                isDark ? './images/procaaso-white.svg' : './images/procaaso.svg'
              }
              alt="ProCaaSo"
            />
          )}
        </LogoWrap>
        {isEdgeEnv ? (
          <KratosLoginForm hasInternetAccess={hasInternetAccess} />
        ) : (
          <Button
            id="sign-in"
            fullWidth
            variant="contained"
            onClick={handleRedirect}
            disabled={isSignInClicked}
          >
            Sign in
          </Button>
        )}
      </AuthBox>
      {authExpired && (
        <Alert
          severity="warning"
          sx={{
            width: '90%',
            px: '15px',
            maxWidth: { xs: '352px', sm: '436px' },
            justifyContent: 'center',
          }}
          id="auth-expired"
        >
          Your session has expired, please sign in again.
        </Alert>
      )}
      <Box
        sx={{ position: 'absolute', bottom: 32 }}
        component="img"
        src="/images/procaaso-product.svg"
        alt="ProCaaSo"
      />
    </Container>
  );
};

export default Home;
