import React, { useMemo, useEffect, useRef } from 'react';
import { Resizable } from 're-resizable';
import { Handle, Position } from 'react-flow-renderer';
import { Box, styled } from '@mui/material';
import Progress from './Progress';
import { useGetComponentsQuery } from '../../../../redux/services/components/api';
import { OldComponentData } from '../../../../typescript/interfaces/oldComponentData.interface';

const Label = styled('span')(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  zIndex: 99,
  left: '4%',
  right: '4%',
  bottom: '40%',
  lineHeight: '1.5',
  textAlign: 'center',
  color: '#484848',
  fontSize: '30px',
  height: '40px',
  top: '50%',
  '& svg': {
    width: '40%',
  },
  '& > div': {
    transform: 'translateY(-50%)',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
  },
}));

const SVG = styled('svg')({
  width: '100%',
  height: '100%',
});

interface Props {
  isLibrary?: boolean;
  id?: string;
  data?: OldComponentData;
  currentNameTypes?: boolean;
  systemName?: string;
  isTemplate?: boolean;
}

function Water(props: Props) {
  const {
    isLibrary,
    id,
    data,
    systemName,
    isTemplate: propsIsTemplate,
  } = props;
  const { size: dataSize } = data;
  const { width, height } = dataSize || {};
  const defaultSize = { width: width || 309, height: height || 252 };
  const isTemplate = Boolean(propsIsTemplate || data.isTemplate);

  const textRef = useRef(null);

  const progress = 0;

  const MAX_VALUE = 10; // TBD MAX Kg FOR Water

  const currentNameTypes = useMemo(
    () => data?.label?.props?.currentNameTypes || undefined,
    [data],
  );

  const { data: components } = useGetComponentsQuery(
    {
      systemId: data?.data?.systemId,
    },
    {
      skip: !data?.data?.systemId,
    },
  );

  const component = useMemo(
    () => components?.find((c: any) => c.id === id),
    [components, id],
  );

  const name = useMemo(() => {
    if (isTemplate) return id;
    if (component?.name) return component.name;
    return 'Water';
  }, [currentNameTypes, component, systemName, data]);

  useEffect(() => {
    const minSize = 12;
    const maxSize = 60;
    const maxBottom = 25;
    let size = maxSize;
    let bottom = maxBottom;
    do {
      size -= 0.1;
      bottom += 0.07;
      textRef.current.style.bottom = `${bottom}%`;
      textRef.current.style.fontSize = `${size}px`;
    } while (
      (textRef.current.clientWidth < textRef.current.scrollWidth ||
        textRef.current.clientHeight < textRef.current.scrollHeight) &&
      size > minSize
    );

    if (
      (textRef.current.clientWidth >= textRef.current.scrollWidth ||
        textRef.current.clientHeight >= textRef.current.scrollHeight) &&
      size.toFixed(0) === `${minSize}`
    ) {
      textRef.current.style.textOverflow = 'ellipsis';
      textRef.current.style.overflow = 'hidden';
      textRef.current.style.whiteSpace = 'nowrap';
      textRef.current.style.width = textRef.current.scrollWidth;
    }
  }, [name]);

  return (
    <Box
      component={Resizable}
      className={!isTemplate && 'nodrag'}
      // key={Date.now()}
      defaultSize={defaultSize}
      lockAspectRatio
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .resize-handle': {
          width: '25% !important',
          height: '25% !important',
          right: '-20% !important',
          bottom: '-20% !important',
          zIndex: '999 !important',
          display: 'none',
        },
        '& .progress': {
          left: '-12% !important',
        },
      }}
    >
      <div>
        {!isLibrary && !isTemplate && (
          <Progress
            // eslint-disable-next-line react/destructuring-assignment
            progress={progress}
            maxValue={MAX_VALUE}
            className="progress"
          />
        )}
        <Label
          ref={textRef}
          className={currentNameTypes !== undefined ? 'label-system' : ''}
        >
          {name}
        </Label>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          // width="309"
          // height="252"
          viewBox="0 0 257 209.859"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#ccc" />
              <stop offset="0.1" stopColor="#dfdfdf" />
              <stop offset="0.24" stopColor="#f1f1f1" />
              <stop offset="0.39" stopColor="#fbfbfb" />
              <stop offset="0.56" stopColor="#fff" />
              <stop offset="0.84" stopColor="#dbdbdb" />
              <stop offset="1" stopColor="#ccc" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-4"
              y1="0.5"
              x2="1"
              y2="0.5"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#6e6e6e" />
              <stop offset="0.03" stopColor="#787878" />
              <stop offset="0.12" stopColor="#8e8e8e" />
              <stop offset="0.22" stopColor="#9f9f9f" />
              <stop offset="0.34" stopColor="#aaa" />
              <stop offset="0.52" stopColor="#b1b1b1" />
              <stop offset="1" stopColor="#b3b3b3" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-6"
              x1="0.001"
              y1="0.5"
              x2="1.003"
              y2="0.5"
              xlinkHref="#linear-gradient"
            />
            <linearGradient
              id="linear-gradient-7"
              x1="0"
              y1="0.5"
              x2="1.003"
              y2="0.5"
              xlinkHref="#linear-gradient"
            />
            <linearGradient
              id="linear-gradient-9"
              x1="0.5"
              y1="0.996"
              x2="0.5"
              y2="-0.005"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stopColor="#666" />
              <stop offset="0.2" stopColor="#767676" />
              <stop offset="1" stopColor="#b3b3b3" />
            </linearGradient>
          </defs>
          <g id="watertank_1" transform="translate(-186.3 -331.2)">
            <rect
              id="Rectangle_25"
              data-name="Rectangle 25"
              width="256"
              height="186.926"
              transform="translate(186.8 348.338)"
              fill="#eaeaea"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <rect
              id="Rectangle_26"
              data-name="Rectangle 26"
              width="19.661"
              height="1.637"
              transform="translate(304.993 346.701)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient)"
            />
            <rect
              id="Rectangle_27"
              data-name="Rectangle 27"
              width="11.592"
              height="11.592"
              transform="translate(309.015 335.11)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient)"
            />
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="14.683"
              height="3.591"
              transform="translate(307.47 333.178)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient)"
            />
            <rect
              id="Rectangle_29"
              data-name="Rectangle 29"
              width="15.933"
              height="1.477"
              transform="translate(306.856 331.7)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient-4)"
            />
            <rect
              id="Rectangle_30"
              data-name="Rectangle 30"
              width="11.592"
              height="0.727"
              transform="translate(309.015 341.655)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient-4)"
            />
            <rect
              id="Rectangle_31"
              data-name="Rectangle 31"
              width="17.797"
              height="1.568"
              transform="translate(244.464 346.792)"
              fill="#ededed"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <rect
              id="Rectangle_32"
              data-name="Rectangle 32"
              width="5.114"
              height="3.068"
              transform="translate(250.374 343.724)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient-6)"
            />
            <rect
              id="Rectangle_33"
              data-name="Rectangle 33"
              width="3.228"
              height="2.523"
              transform="translate(251.329 338.905)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient-7)"
            />
            <rect
              id="Rectangle_34"
              data-name="Rectangle 34"
              width="3.523"
              height="1.046"
              transform="translate(251.192 337.86)"
              fill="#ededed"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path
              id="Path_20"
              data-name="Path 20"
              d="M631.9,1227.3l4.5,5.3h44.822l4.364-5.3Z"
              transform="translate(-343.932 -692.036)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_21"
              data-name="Path 21"
              d="M466.5,376.8l.955-2.3h3.228l.955,2.3Z"
              transform="translate(-216.126 -33.072)"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1"
              fill="url(#linear-gradient-9)"
            />
          </g>
        </SVG>
        <>
          <Handle
            className="point"
            type="source"
            position={Position.Right}
            id="t-right"
          />
          <Handle
            className="point"
            type="target"
            position={Position.Right}
            id="t-right"
          />
          <Handle
            className="point"
            type="source"
            position={Position.Left}
            id="t-left"
          />
          <Handle
            className="point"
            type="target"
            position={Position.Left}
            id="t-left"
          />
        </>
      </div>
    </Box>
  );
}
Water.defaultProps = {
  isLibrary: false,
  data: {},
  id: '',
  currentNameTypes: false,
  systemName: undefined,
  isTemplate: false,
};

export default Water;
