import {
  ListItem,
  Tab,
  Tabs,
  ListItemText,
  Typography,
  Box,
  List,
  Stack,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

export const Root = styled(Stack)({
  boxSizing: 'border-box',
  height: 'calc(100% - 60px)',
});

export const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(3),
  paddingBottom: 0,
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}));

export const HeadLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontSize: '2rem',
  fontWeight: 600,
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
}));

export const ProjectsWrap = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  padding: `0 ${theme.spacing(3)}`,
  [theme.breakpoints.down('md')]: {
    border: 0,
    borderRadius: 0,
    paddingTop: 0,
    overflow: 'auto',
    marginBottom: '8px',
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(2)}`,
    marginRight: 0,
  },
}));

export const TabsWrap = styled(Stack)(({ theme }) => ({
  flexGrow: 0,
  minWidth: '200px',
  maxWidth: '330px',
  flexBasis: '25%',
  marginTop: '6px',
  maxHeight: 'calc(100% - 1.5rem)',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    minWidth: 'auto',
    marginTop: 0,
  },
}));

export const TabsList = styled(List)({
  height: '100%',
  padding: 0,
  borderBottom: 0,
});

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  height: '100%',
  padding: 0,
  borderBottom: 0,
  '& .MuiTabs-scrollableY': {
    overflowX: 'hidden',
    paddingRight: theme.spacing(1),
    marginBottom: '0 !important',
  },
  '& .MuiTabs-scroller': { scrollbarWidth: 'auto' },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const TabListItem = styled(ListItem)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.shape.borderRadius,
  maxWidth: '100%',
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: '0.75rem',
  transition: 'none',
  background:
    theme.palette.mode === 'light'
      ? theme.palette.background.paper
      : theme.palette.gradient.main,
  alignItems: 'flex-start',
  '&:hover': {
    background:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.08)
        : 'rgba(255, 255, 255, 0.08)',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.shape.borderRadius,
  maxWidth: '100%',
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: '0.75rem',
  background:
    theme.palette.mode === 'light'
      ? theme.palette.background.paper
      : theme.palette.gradient.main,
  alignItems: 'flex-start',
  textAlign: 'left',
  '&:hover': {
    background:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.08)
        : 'rgba(255, 255, 255, 0.08)',
  },
  '&.Mui-selected': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.bg.dark
        : alpha(theme.palette.primary.main, 0.1),
    borderColor:
      theme.palette.mode === 'dark'
        ? '#676F87'
        : alpha(theme.palette.primary.main, 0.25),
  },
}));

export const ListText = styled(ListItemText)(({ theme }) => ({
  width: '100%',
  marginTop: 0,
  marginBottom: 0,
  '& .MuiListItemText-primary': {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    display: 'block',
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    display: 'block',
  },
}));
