import { Box, styled, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import ReactFlow, { ControlButton } from 'react-flow-renderer';
import { SURFACE } from '../../../colors';

export const ControlBtn = styled(ControlButton)(({ theme }) => ({
  width: '2.125rem !important',
  height: '2.125rem !important',
  color: theme.palette.text.primary,
  '& .fullscreen-icon': {
    fontSize: '26px',
  },
}));

export const ReactFlowStyled = styled(ReactFlow)({
  backgroundColor: SURFACE.main,
  '& .react-flow__attribution': {
    display: 'none',
  },
  '& .react-flow__handle': {
    '&.nodrag': {
      cursor: 'auto',
    },
  },
});

export const LoaderWrap = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 999,
  background: 'white',
});

export const ErrorWrap = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ErrorText = styled(Typography)(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.bg.light
      : theme.palette.text.disabled,
  fontSize: '1.125rem',
  lineHeight: '1.3rem',
  textAlign: 'center',
  marginTop: theme.spacing(1),
}));

export const ErrorIcon = styled(ErrorOutline)(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.bg.light
      : theme.palette.text.disabled,
  height: '24px',
  width: '24px',
}));
