import React, { useEffect, useState } from 'react';
import { List, Tooltip } from '@mui/material';
import {
  AddRounded,
  EditOutlined,
  KeyboardArrowDownRounded,
} from '@mui/icons-material';
import { VisualCategoryResponse } from '../../../redux/services/visualCategory/types';
import {
  CardHead,
  CardListItem,
  CardRoot,
  IconBtn,
  TinyPreviewWrap,
} from './styles';
import { Flex } from '../../styles';
import TrashIcon from '../../../icons/TrashIcon';
import PreviewItemComponent from './PreviewItemComponent';
import GroupComponents from './GroupComponents';
import CreateRenameGroup from './CreateRenameGroup';
import DeleteGroup from './DeleteGroup';
import { useGetVisualsQuery } from '../../../redux/services/visuals/api';
import { RemoveItemFromCategoryComponent } from './UpdateCategoryComponent';

interface CardProps {
  category: VisualCategoryResponse;
}

const Card = (props: CardProps) => {
  const { category } = props;
  const { name, id } = category;

  const [isCreateRenameOpen, setIsCreateRenameOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isGroupOpened, setIsGroupOpened] = useState<boolean>(false);
  const [removeComponentFromCategory, setRemoveComponentFromCategory] =
    useState<string | undefined>(undefined);

  const {
    data: visuals = [],
    error: visualsError,
    isLoading: isVisualsLoading,
  } = useGetVisualsQuery(undefined, {
    selectFromResult: (res) => ({
      data: res?.data?.filter((v) => v.category === name),
      error: res?.error,
      isLoading: res?.isLoading,
    }),
  });

  useEffect(() => {
    setRemoveComponentFromCategory(undefined);
  }, [visuals?.length]);

  const handleCloseModal = () => {
    setIsCreateRenameOpen(false);
    setIsDeleteOpen(false);
  };

  const handleGroupOpen = () => {
    setIsGroupOpened(true);
  };

  const handleGroupClose = () => {
    setIsGroupOpened(false);
  };

  const handleRenameOpen = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsCreateRenameOpen(true);
  };

  const handleDeleteOpen = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsDeleteOpen(true);
  };

  const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!(e.target as HTMLElement).closest('button')) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleRemoveVisualFromGroup = (visualId: string) => {
    setRemoveComponentFromCategory(visualId);
  };

  return (
    <CardRoot>
      <CardHead
        id={`ManageGroup-ListItem-${name.replace(/ /g, '_')}`}
        onClick={handleExpand}
      >
        {name}({visuals.length})
        <Flex>
          {isExpanded && (
            <>
              <Tooltip title="Add/remove elements for group">
                <IconBtn
                  onClick={handleGroupOpen}
                  disabled={Boolean(visualsError) || isVisualsLoading}
                >
                  <AddRounded />
                </IconBtn>
              </Tooltip>
              <Tooltip title="Rename group">
                <IconBtn onClick={handleRenameOpen}>
                  <EditOutlined />
                </IconBtn>
              </Tooltip>
              {visuals.length > 0 ? (
                <Tooltip title="Group contains visuals">
                  <span>
                    <IconBtn disabled>
                      <TrashIcon />
                    </IconBtn>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="Delete">
                  <IconBtn
                    onClick={handleDeleteOpen}
                    disabled={isVisualsLoading}
                  >
                    <TrashIcon />
                  </IconBtn>
                </Tooltip>
              )}
            </>
          )}
          <Tooltip title={isExpanded ? 'Collapse' : 'Expand'}>
            <KeyboardArrowDownRounded
              sx={{ transform: isExpanded ? 'rotate(180deg)' : 'none' }}
            />
          </Tooltip>
        </Flex>
      </CardHead>
      {removeComponentFromCategory ? (
        <RemoveItemFromCategoryComponent
          id={removeComponentFromCategory}
          categoryName={name}
          isSingle
        />
      ) : null}
      {isExpanded && (
        <List dense sx={{ p: 0 }}>
          {visuals.map((v) => (
            <CardListItem
              id={`ManageGroup-visual-${v.name.replace(/ /g, '_')}`}
              key={v.id}
            >
              <Flex sx={{ gap: '4px' }}>
                <TinyPreviewWrap>
                  <PreviewItemComponent visual={v} />
                </TinyPreviewWrap>
                {v.name}
              </Flex>
              <Tooltip title="Remove visual from group">
                <IconBtn onClick={() => handleRemoveVisualFromGroup(v.id)}>
                  <TrashIcon sx={{ fontSize: '1rem' }} />
                </IconBtn>
              </Tooltip>
            </CardListItem>
          ))}
        </List>
      )}
      {isGroupOpened ? (
        <GroupComponents
          category={category}
          open={isGroupOpened}
          onClose={handleGroupClose}
        />
      ) : null}
      {isCreateRenameOpen ? (
        <CreateRenameGroup
          open={isCreateRenameOpen}
          categoryId={id}
          onClose={handleCloseModal}
        />
      ) : null}
      {isDeleteOpen ? (
        <DeleteGroup
          name={name}
          open={isDeleteOpen}
          categoryId={id}
          onClose={handleCloseModal}
        />
      ) : null}
    </CardRoot>
  );
};

export default Card;
