import { createSlice } from '@reduxjs/toolkit';
import { ROUTES_HIERARCHY } from '../../../constants';

const storedState = JSON.parse(sessionStorage.getItem('breadcrumbs')) ?? [];

const initialState = storedState;

const payloadWithLayer = (payload) => {
  const payloadLayer = Object.entries(ROUTES_HIERARCHY).find(
    ([_, layerHierrarchy]) =>
      layerHierrarchy.find((layerUrl) => payload?.url.indexOf(layerUrl) > -1),
  );
  return { ...payload, layer: payloadLayer?.[0] };
};

const getNewProjectTitle = (url: string) => {
  const isDemo = JSON.parse(localStorage.getItem('isDemo') || 'false');
  if (!isDemo) return 'Projects';
  if (url.includes('PROJ')) return 'ENS Projects';
  if (url.includes('FPRO')) return 'FNS Projects';
  return 'Projects';
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumb(state, { payload }) {
      const newEntry = payloadWithLayer(payload[0]);
      if (!state) return [payload];
      if (state?.find(({ url }) => url === payload.url)) return state;

      const filteredState = state?.filter(
        ({ layer }) => layer < newEntry.layer,
      );

      const sortedState = [...filteredState, newEntry].sort((a, b) => {
        if (a.layer > b.layer) return 1;
        if (a.layer < b.layer) return -1;
        return 0;
      });

      if (sortedState[0].url !== '/projects') {
        return sortedState;
      }

      const [first, ...rest] = sortedState;
      return [{ ...first, name: getNewProjectTitle(rest[0].url) }, ...rest];
    },
    setBreadcrumbsInitialState(_state, { payload }) {
      if (payload?.length === 0) return payload;
      const newEntry = payloadWithLayer(payload[0]);

      return [newEntry];
    },
    setBreadcrumbsBasedOnLayer(state, { payload }) {
      const newState = state.filter(({ layer }) => layer <= payload);
      return newState;
    },
  },
});

const { actions, reducer } = breadcrumbsSlice;
export const {
  setBreadcrumb,
  setBreadcrumbsInitialState,
  setBreadcrumbsBasedOnLayer,
} = actions;
export default reducer;
