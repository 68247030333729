import { atreusApiUrl } from '../../../env';
import { rootApi } from '..';

const BASE_URL = atreusApiUrl();

export interface PostRoleRequest {
  name: string;
  description: string;
  permissions: string[];
}

export interface PutRoleRequest {
  id: string;
  body: {
    name?: string;
    description?: string;
    permissions?: string[];
  };
}

export interface GetRole {
  id: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  version: number;
  deleted: boolean;
  name: string;
  description: string;
  permissions: string[];
}

export interface GetRoleWithConstraints {
  id: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  version: number;
  deleted: boolean;
  name: string;
  description: string;
  permissions: {
    [key: string]: {
      aggregate_ids: string[];
    };
  };
}

export interface UpdateRoleWithConstraintsRequest {
  id: string;
  body: {
    name?: string;
    description?: string;
    permissions?: {
      [key: string]: {
        aggregate_ids: string[];
      };
    };
  };
}

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<GetRole[], void>({
      query: () => `${BASE_URL}/roles`,
      providesTags: ['Roles'],
    }),
    getRolesWithConstraints: builder.query<GetRoleWithConstraints[], void>({
      query: () => `${BASE_URL}/roles?withConstraints=true`,
      providesTags: ['RolesWithConstraints'],
    }),
    postRole: builder.mutation<GetRole, PostRoleRequest>({
      query: (body) => ({
        url: `${BASE_URL}/roles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Roles', 'RolesWithConstraints'],
    }),
    putRole: builder.mutation<GetRole, PutRoleRequest>({
      query: (request) => ({
        url: `${BASE_URL}/roles/${request.id}`,
        method: 'PUT',
        body: request.body,
      }),
      invalidatesTags: ['Roles', 'RolesWithConstraints'],
    }),
    updateRoleWithConstraints: builder.mutation<
      GetRole,
      UpdateRoleWithConstraintsRequest
    >({
      query: (request) => ({
        url: `${BASE_URL}/roles/${request.id}?withConstraints=true`,
        method: 'PUT',
        body: request.body,
      }),
      invalidatesTags: ['Roles', 'RolesWithConstraints'],
    }),
    deleteRole: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_URL}/roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Roles', 'RolesWithConstraints'],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRolesWithConstraintsQuery,
  usePostRoleMutation,
  usePutRoleMutation,
  useUpdateRoleWithConstraintsMutation,
  useDeleteRoleMutation,
} = extendedApi;
