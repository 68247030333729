import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import { IComponent } from '../../../typescript/interfaces/component.interface';
import { ISystem } from '../../../typescript/interfaces/system.interface';

const BASE_URL = `${apiBaseUrlV1('structure/v1')}/components`;
const BASE_URL_SYSTEMS = `${apiBaseUrlV1('structure/v1')}/systems`;

interface ComponentsAggregation {
  systemId?: string;
}

export const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getComponents: builder.query<IComponent[], ComponentsAggregation>({
      query: ({ systemId }) => `${BASE_URL_SYSTEMS}/${systemId}`,
      providesTags: ['Components'],
      transformResponse: (res: ISystem) => res.components,
    }),
    getComponentById: builder.query<IComponent, undefined>({
      query: (id) => `${BASE_URL}/${id}`,
      providesTags: ['Components'],
    }),
    createComponent: builder.mutation<IComponent[], undefined>({
      query: (data: IComponent) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Components'],
    }),
    updateComponent: builder.mutation<
      IComponent,
      { id: string; data: IComponent }
    >({
      query: ({ id, data }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Components'],
    }),
    deleteComponent: builder.mutation<IComponent, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Components'],
    }),
  }),
});

export const {
  useGetComponentsQuery,
  useGetComponentByIdQuery,
  useCreateComponentMutation,
  useUpdateComponentMutation,
  useDeleteComponentMutation,
} = extendedApi;
