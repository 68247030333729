import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function TemperatureIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M479.916-65.869q-89.134 0-151.59-62.425-62.457-62.426-62.457-151.969 0-51.867 22.717-98.563 22.718-46.696 68.283-70.392V-771q0-51.54 35.682-87.618 35.682-36.078 87.25-36.078 51.567 0 87.731 36.078Q603.696-822.54 603.696-771v321.782q45 23.851 68 70.526t23 98.692q0 89.28-62.823 151.706-62.823 62.425-151.957 62.425ZM436.087-513.87h87.826v-51.869H480v-43.522h43.913v-82.478H480v-43.022h43.913V-771q0-18.564-12.71-31.238-12.71-12.675-31.326-12.675t-31.203 12.675Q436.087-789.564 436.087-771v257.13Z" />
    </SvgIcon>
  );
}

export default TemperatureIcon;
