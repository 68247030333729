import React, { useState } from 'react';
import { XYPosition } from 'react-flow-renderer';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import ModalContent from './ModalContent';
import { ModalComponent } from '../styles';
import InnerModal from '../InnerModal';

interface Props {
  open: boolean;
  onClose: () => void;
  pasteHandler?: ({
    nodeId,
    position,
  }: {
    nodeId: string;
    position?: XYPosition;
  }) => void;
}

export const FORMDATA_ASSET = 'asset';
export const FORMDATA_DEFINITION = 'definition';

const Modal = (props: Props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { open, onClose, pasteHandler } = props;
  const [isListView, setIsListView] = useState<boolean>(false);
  const [isInnerModalOpened, setIsInnerModalOpened] = useState<boolean>(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedVisuals, setSelectedVisuals] = useState<string[]>([]);

  const toggleDrawer = (newOpenState: boolean) => {
    setIsDrawerOpened(newOpenState);
  };

  const handleInnerModalOpen = () => {
    setIsInnerModalOpened(true);
  };

  const handleInnerModalClose = () => {
    setIsInnerModalOpened(false);
  };

  const toggleListView = (val: boolean) => {
    setIsListView(val);
  };

  const handleSearchInput = (
    event: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    if (typeof event === 'string') {
      setSearchValue(event);
      return;
    }
    setSearchValue(event.target.value);
  };

  return (
    <ModalComponent
      aria-labelledby="element-library-title"
      aria-describedby="element-library-description"
      open={open}
      onClose={onClose}
      scroll="paper"
      fullScreen={isTablet}
    >
      <Stack
        sx={{
          width: '100%',
        }}
      >
        <ModalContent
          isListView={isListView}
          isTablet={isTablet}
          onClose={onClose}
          selectedVisuals={selectedVisuals}
          pasteHandler={pasteHandler}
          isDrawerOpened={isDrawerOpened}
          searchValue={searchValue}
          toggleDrawer={toggleDrawer}
          setSelectedVisuals={setSelectedVisuals}
          handleInnerModalOpen={handleInnerModalOpen}
          handleSearchInput={handleSearchInput}
          toggleListView={toggleListView}
        />
      </Stack>
      <InnerModal
        open={isInnerModalOpened}
        onClose={handleInnerModalClose}
        visual={undefined}
      />
    </ModalComponent>
  );
};

Modal.defaultProps = {
  pasteHandler: () => {},
};

export default Modal;
