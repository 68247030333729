import React from 'react';
import { styled, Chip, alpha } from '@mui/material';

const Count = styled(Chip)(({ theme }) => ({
  minWidth: 20,
  height: 20,
  borderRadius: '50%',
  color: theme.palette.error.main,
  backgroundColor: alpha(theme.palette.error.main, 0.15),
  marginLeft: '4px',
  marginRight: theme.spacing(1),
  '&.opened': {
    backgroundColor: 'transparent',
    padding: 0,
    minWidth: 0,
    color: theme.palette.text.primary,
  },
  '&.hint': {
    color: theme.palette.text.secondary,
    backgroundColor: alpha(theme.palette.text.primary, 0.2),
  },
  '&.warning': {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
  },
  '&.info': {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.15),
  },
}));

const ProblemsCount = ({
  problemsCount,
  className,
}: {
  problemsCount: number;
  className: string;
}) => <Count className={className} label={problemsCount} />;

export default ProblemsCount;
