import * as React from 'react';
import { alpha, Box } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

function Loader(props: any) {
  const { size, x, y, position, nonetransform } = props;
  const defaultSize = size || 40;
  const defaultX = x || x === 0 || 50;
  const defaultY = y || y === 0 || 50;
  const defaultPosition = position || 'absolute';

  return (
    <Box
      sx={{
        width: size,
        height: size,
        position: defaultPosition,
        top: `${defaultY}%`,
        left: `${defaultX}%`,
        transform: nonetransform !== 'true' && 'translate(-50%, -50%)',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.mode === 'light'
                ? alpha(theme.palette.primary.main, 0.25)
                : alpha(theme.palette.secondary.main, 0.25),
          }}
          size={defaultSize}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={defaultSize}
          thickness={4}
          {...props}
        />
      </Box>
    </Box>
  );
}

export default Loader;
