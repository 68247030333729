//@ts-nocheck
import { rootApi } from '.';
import { CreateFrameworkDeploymentCommand } from './packages/types';
import { apiBaseUrlV1 } from '../../env';
import { sortVersions } from '../../utils/helper';

const BASE_URL = `${apiBaseUrlV1('ubiety/v1')}`;

export type SystemStatus = {
  systemId: string;
  online: boolean;
};

export type SoftwareVersionsResponse = {
  charts: any[];
  createdAt: string;
  createdBy: string;
  description: string;
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  name: string;
  result: {
    error: string;
    installedCharts: any[];
    outcome: string;
    [key: string]: any;
  };
  status: string;
  version: number;
  targets?: string[];
};

export interface ISystemSettings {
  id: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: null | string;
  modifiedBy: null | string;
  version: number;
  deleted: boolean;
  remoteInput: boolean;
  noLoginTimeoutOnHmi: boolean;
  systemId: string | null;
}

export interface ISystemsSettingsPayload {
  remote_input?: boolean;
  no_login_timeout_on_hmi?: boolean;
}
interface ISystemSettingsPayload {
  id: string;
  data: ISystemsSettingsPayload;
}
const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystemStatus: builder.query<SystemStatus[], void>({
      query: () => `${BASE_URL}/heartbeats/latest`,
      transformResponse: (response: any) =>
        response
          .map(({ systemId, online }) => ({
            systemId,
            online,
          }))
          .sort((a, b) => {
            if (a.systemId > b.systemId) return -1;
            if (a.systemId < b.systemId) return 1;
            return 0;
          }),
    }),
    getFrameworkDeployments: builder.query<
      SoftwareVersionsResponse[],
      undefined
    >({
      query: () => `${BASE_URL}/frameworkDeployments`,
      providesTags: ['FrameworkChartsData'],
    }),
    getSoftwareVersions: builder.query<SoftwareVersionsResponse[], string>({
      query: (systemID) =>
        `${BASE_URL}/systems/${systemID}/frameworkDeployments`,
      providesTags: ['FrameworkChartsData'],
    }),
    getBindingToken: builder.mutation<any, string>({
      query: (id) => ({
        url: `${BASE_URL}/systems/${id}/binding`,
        method: 'POST',
      }),
    }),
    createFrameworkDeployment: builder.mutation<
      any,
      CreateFrameworkDeploymentCommand
    >({
      query: (command) => ({
        url: `${BASE_URL}/frameworkDeployments`,
        method: 'POST',
        body: command,
      }),
      invalidatesTags: ['FrameworkChartsData'],
    }),
    getPairingData: builder.query<any, string>({
      query: (id) => ({
        url: `${BASE_URL}/systems/${id}/heartbeats/latest`,
        method: 'GET',
      }),
    }),
    getFrameworkCharts: builder.query<string[], string[]>({
      query: (charts = []) => {
        const url = new URL(`${BASE_URL}/frameworkCharts`);
        charts.forEach((chart) => {
          url.searchParams.append('chart', chart);
        });
        return { url, method: 'GET' };
      },
      transformResponse: (res, _, charts) => {
        const sorted = charts.map((c: string) => ({
          [c]: sortVersions(res?.[c]),
        }));
        return Object.assign(...sorted);
      },
    }),
    getSystemsSettings: builder.query<ISystemSettings, void>({
      query: () => ({
        url: `${BASE_URL}/settings`,
        method: 'GET',
      }),
      providesTags: ['SystemSettings'],
    }),
    updateSystemsSettings: builder.mutation<
      ISystemSettings,
      ISystemsSettingsPayload
    >({
      query: (body) => ({
        url: `${BASE_URL}/settings`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SystemSettings, SystemsExceptions'],
    }),
    getSystemSettings: builder.query<
      ISystemSettings,
      ISystemSettingsPayload['id']
    >({
      query: (id) => ({
        url: `${BASE_URL}/settings?systemID=${id}`,
        method: 'GET',
      }),
      providesTags: ['SystemSpecificSettings'],
    }),
    getSystemsSettingsList: builder.query<ISystemSettings[], boolean>({
      query: (filter) => ({
        url: `${BASE_URL}/systems/settings?remoteInput=${filter}`,
        method: 'GET',
      }),
      providesTags: ['SystemsExceptions'],
    }),
    updateSystemSettings: builder.mutation<
      ISystemSettings,
      ISystemSettingsPayload
    >({
      query: ({ data, id }) => ({
        url: `${BASE_URL}/settings?systemID=${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['SystemsExceptions', 'SystemSpecificSettings'],
    }),
  }),
});

export const {
  useGetSystemStatusQuery,
  useGetSoftwareVersionsQuery,
  useGetFrameworkDeploymentsQuery,
  useGetBindingTokenMutation,
  useCreateFrameworkDeploymentMutation,
  useGetPairingDataQuery,
  useGetFrameworkChartsQuery,
  useGetSystemsSettingsQuery,
  useUpdateSystemsSettingsMutation,
  useUpdateSystemSettingsMutation,
  useGetSystemsSettingsListQuery,
  useGetSystemSettingsQuery,
} = extendedApi;
