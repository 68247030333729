import React from 'react';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

interface Props {
  path: string;
  handleClose: () => void;
}

const ManageUserButton = (props: Props) => {
  const { path, handleClose } = props;

  return (
    <MenuItem
      onClick={handleClose}
      component={Link}
      to={path}
      sx={{ p: '6px' }}
      id="UserMenu-manage-users"
    >
      <ListItemIcon>
        <AccountCircleOutlined />
      </ListItemIcon>
      Manage users & roles
    </MenuItem>
  );
};

export default ManageUserButton;
