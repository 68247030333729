/* eslint-disable react/style-prop-object */
import React from 'react';
import { Resizable } from 're-resizable';
import { Handle, Position } from 'react-flow-renderer';
import { Box } from '@mui/material';

interface Props {
  id: string;
  data?: any;
}

const ConductivitySensor = (props: Props) => {
  const { data } = props;
  const { size } = data || {};
  const { width, height } = size || {};
  const defaultSize = { width: width || 68, height: height || 89 };

  return (
    <Box
      component={Resizable}
      defaultSize={defaultSize}
      lockAspectRatio
      resizeRatio={1.25}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        '&:hover .edit-btn': {
          opacity: 1,
        },
        '&:hover .resize-handle': {
          display: 'inline-block',
        },
        '& .resize-handle': {
          width: '20% !important',
          height: '20% !important',
          right: '-5% !important',
          bottom: '-5% !important',
          display: 'none',
        },
      }}
    >
      <div>
        <Handle
          className="point"
          type="source"
          position={Position.Left}
          style={{ left: 0 }}
        />
        <Handle
          className="point"
          type="target"
          position={Position.Right}
          style={{ right: 0 }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 685.725 893.41"
          style={{ width: '100%', height: '100%' }}
        >
          <g id="conductivity_sensor" transform="translate(-407.135 -303.303)">
            <path
              id="Path_162"
              data-name="Path 162"
              d="M1084.33,849.11c-1-69.58-24.67-136.93-66.39-192.61L765.51,319.55a19.38,19.38,0,0,0-31,0L480.87,658.08c-30.86,41.2-52,89-60.56,139.76a336.006,336.006,0,0,0-4.64,60.72c2.52,182.91,152.86,330.43,335.79,329.65,184-.78,332.9-150.18,332.9-334.36Q1084.36,851.48,1084.33,849.11ZM750,960.28A112.63,112.63,0,1,1,862.63,847.65,112.63,112.63,0,0,1,750,960.28Z"
              fill="#eaeaea"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="17"
            />
            <line
              id="Line_3"
              data-name="Line 3"
              x2="130.78"
              transform="translate(750 735.02)"
              fill="#eaeaea"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="17"
            />
          </g>
        </svg>
      </div>
    </Box>
  );
};

ConductivitySensor.defaultProps = {
  data: {},
};

export default ConductivitySensor;
