import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function UploadIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z" />
    </SvgIcon>
  );
}

export default UploadIcon;
