import React from 'react';
import { useAppSelector } from '../../redux/store';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import { LoaderWrap } from '../dashboard/Schedule/styles';
import Loader from '../../common/Loader';
import SystemEmpty from './SystemEmpty';

const EmptyListComponent = ({
  handleCreateSystemOpen,
  searchValue,
}: {
  handleCreateSystemOpen: () => void;
  searchValue: string;
}) => {
  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );
  const machineStatusLoaded = useAppSelector(
    (state: IAppState) => state.view.machineStatusLoaded,
  );
  const controlStatusLoaded = useAppSelector(
    (state: IAppState) => state.view.controlStatusLoaded,
  );

  if (!machineStatusLoaded || !controlStatusLoaded) {
    if (isEdgeEnv) {
      return (
        <SystemEmpty
          isHiddenCreate
          height="100%"
          onCreate={handleCreateSystemOpen}
          title="System wasn't loaded."
          detail="Error happened during system loading."
        />
      );
    }
    return (
      <LoaderWrap sx={{ position: 'relative' }}>
        <Loader />
      </LoaderWrap>
    );
  }

  if (searchValue === '')
    return (
      <SystemEmpty
        height="100%"
        onCreate={handleCreateSystemOpen}
        title="No filtering result."
        detail="Please try another filtering term or create a new system."
      />
    );

  return (
    <SystemEmpty
      height="100%"
      onCreate={handleCreateSystemOpen}
      title="No search result."
      detail="Please try different term or create a new system."
    />
  );
};

export default EmptyListComponent;
