import React from 'react';
import { Accordion, AccordionDetails } from '@mui/material';
import { AddRounded, RemoveRounded } from '@mui/icons-material';
import { Head, Title } from './styles';

interface Props {
  expanded: string | boolean;
  handleChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  children?: React.JSX.Element;
  id: string;
  title: string;
  count: number;
}

const Groups = (props: Props) => {
  const { expanded, handleChange, children, id, title, count } = props;

  return (
    <Accordion
      expanded={expanded === id}
      onChange={count ? handleChange(id) : undefined}
    >
      <Head aria-controls={`panel${id}-content`} id={`panel${id}-header`}>
        {expanded === id ? (
          <RemoveRounded sx={{ visibility: count ? 'visible' : 'hidden' }} />
        ) : (
          <AddRounded sx={{ visibility: count ? 'visible' : 'hidden' }} />
        )}
        <Title>
          {title} ({count})
        </Title>
      </Head>
      <AccordionDetails sx={{ '& .MuiAccordion-root': { pl: 2 } }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

Groups.defaultProps = {
  children: null,
};

export default Groups;
