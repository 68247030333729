import { VisualCategoryResponse, BodyPayload } from './types';
import { apiBaseUrlV1 } from '../../../env';
import { rootApi } from '..';

const BASE_URL = apiBaseUrlV1('structure/v1/visualCategory');

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getVisualCategories: builder.query<VisualCategoryResponse[], void>({
      query: () => `${BASE_URL}`,
      providesTags: ['VisualCategories'],
    }),
    getVisualCategory: builder.query<VisualCategoryResponse, string>({
      query: (id) => `${BASE_URL}/${id}`,
    }),
    createVisualCategory: builder.mutation<VisualCategoryResponse, BodyPayload>(
      {
        query: (body) => ({
          url: BASE_URL,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['VisualCategories'],
      },
    ),
    updateVisualCategory: builder.mutation<
      VisualCategoryResponse,
      { id: string; body: BodyPayload }
    >({
      query: ({ id, body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['VisualCategories'],
    }),
    deleteVisualCategory: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['VisualCategories'],
    }),
  }),
});

export const {
  useGetVisualCategoriesQuery,
  useGetVisualCategoryQuery,
  useCreateVisualCategoryMutation,
  useUpdateVisualCategoryMutation,
  useDeleteVisualCategoryMutation,
} = extendedApi;
