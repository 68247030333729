import React, { useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  ListItemButton,
  Stack,
  Tooltip,
  styled,
} from '@mui/material';
import { EditNoteRounded } from '@mui/icons-material';
import { groupBy } from 'lodash';
import { Flex } from '../../styles';
import ManageGroups from './ManageGroups';
import { useGetVisualsQuery } from '../../../redux/services/visuals/api';
import { useGetVisualCategoriesQuery } from '../../../redux/services/visualCategory/api';
import { isForbiddenError } from '../../../redux/utils';
import { SubTitle } from './styles';
import { sortArrayByProperty } from '../../../utils/stringUtils';

interface Props {
  searchValue: string;
  hide?: boolean;
  expandedCategory: string;
  handleCategoryChange: (name: string | undefined) => void;
}

const Root = styled(Stack)({
  width: '206px',
  maxHeight: '100%',
});

const Item = styled(ListItemButton)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  fontSize: '1.125rem',
}));

const GroupPanel = (props: Props) => {
  const { searchValue, hide, expandedCategory, handleCategoryChange } = props;
  const [openManage, setOpenManage] = useState(false);

  const { data: visuals = [] } = useGetVisualsQuery();
  const { data: visualCategories = [], error: visualCategoryError } =
    useGetVisualCategoriesQuery();

  const localVisuals = useMemo(
    () =>
      groupBy(
        visuals.filter(
          (visual) =>
            !searchValue ||
            visual?.name?.toLowerCase().indexOf(searchValue?.toLowerCase()) >
              -1,
        ),
        'category',
      ),
    [searchValue, visuals],
  );

  if (isForbiddenError(visualCategoryError)) return null;

  return (
    <Root sx={{ display: hide ? 'none' : 'flex' }}>
      <Flex sx={{ pl: 3 }}>
        <SubTitle>Groups</SubTitle>
        <Tooltip title="Manage groups">
          <IconButton
            id="GroupPanel-manage_groups"
            onClick={() => setOpenManage(true)}
            sx={{ ml: 'auto' }}
          >
            <EditNoteRounded />
          </IconButton>
        </Tooltip>
      </Flex>
      <ManageGroups open={openManage} onClose={() => setOpenManage(false)} />
      <Box id="GroupPanel-list" sx={{ flex: 1, overflow: 'auto', px: 2 }}>
        <Item
          sx={{ pl: '6px' }}
          onClick={() => handleCategoryChange(undefined)}
          selected={expandedCategory === undefined}
          id="GroupPanel-list-all"
        >
          All ({visuals.length})
        </Item>
        {sortArrayByProperty(visualCategories, 'name').map((cat) => (
          <Item
            selected={expandedCategory === cat.name}
            sx={{ pl: '2rem' }}
            onClick={() => handleCategoryChange(cat.name)}
            key={cat.id}
            id={`GroupPanel-list-${cat.name.replace(/ /g, '_')}`}
          >
            {cat.name} ({localVisuals?.[cat.name]?.length || 0})
          </Item>
        ))}
        <Item
          selected={expandedCategory === 'Uncategorized'}
          sx={{ pl: '2rem' }}
          onClick={() => handleCategoryChange('Uncategorized')}
          id="GroupPanel-list-uncategorized"
        >
          Uncategorized ({localVisuals?.null?.length || 0})
        </Item>
      </Box>
    </Root>
  );
};

GroupPanel.defaultProps = {
  hide: false,
};

export default GroupPanel;
