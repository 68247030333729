import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import {
  useEdgesState,
  useNodesState,
  MiniMap,
  Node,
  useReactFlow,
  Background,
  NodeChange,
  applyNodeChanges,
  addEdge,
  Connection,
  EdgeChange,
} from 'react-flow-renderer';
import { useParams } from 'react-router';
import { Box, useTheme } from '@mui/material';
import Controls from './Controls';
import { ReactFlowStyled } from '../styles';
import { parseValue } from '../../../utils/DiagramUtils';
import { nodesTypes } from '../nodeTypes';
import CustomQEdge from '../nodeTypes/library/utils/CustomQEdge';
import HelperLines from './HelperLines';
import { Context } from './context';
import { getHelperLines } from './utils';
import { Root } from './styles';
import useYAMLTemplateEditorState from '../../../hooks/useYAMLTemplateEditorState';
import { colorToHex, hexToRGBA } from '../../../utils/stringUtils';

interface Props {
  onFullscreenEnter?: () => void;
  onFullscreenExit?: () => void;
  isEditable?: boolean;
  withFullscreen?: boolean;
  isFullscreen?: boolean;
}

const isDisabledHelperLines = true;

const CreateTemplateDiagram = (props: Props) => {
  const {
    isFullscreen,
    onFullscreenExit,
    onFullscreenEnter,
    withFullscreen,
    isEditable,
  } = props;

  const reactFlowWrapper = useRef(null);

  const { id: configID } = useParams<{ id: string }>();
  const theme = useTheme();
  const {
    json,
    handleNodeAdd,
    handleNodeRemove,
    handleNodeClick,
    handleDiagramChange,
    handleNodePosition,
    handleEdges,
  } = useYAMLTemplateEditorState({ id: configID });

  const [initPosition, setInitPosition] = useState({
    x: undefined,
    y: undefined,
  });

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [helperLineHorizontal, setHelperLineHorizontal] = useState<
    number | undefined
  >(undefined);
  const [helperLineVertical, setHelperLineVertical] = useState<
    number | undefined
  >(undefined);
  const [showGrid, setShowGrid] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoading, setIsLoading] = useState({});
  const [currentZoom, setCurrentZoom] = useState(1);
  const [bgColor, setBgColor] = useState<string>();

  const handleNodeLoading = (node: string): void => {
    setIsLoading((prev) => ({ ...prev, [node]: false }));
  };

  useEffect(() => {
    if (!json) return;
    const {
      nodes: nodesLocal,
      edges: edgesLocal,
      initialPosition,
    } = parseValue(json, initPosition);

    if (
      initialPosition?.x !== undefined &&
      initialPosition?.x !== initPosition?.x
    ) {
      setInitPosition(initialPosition);
    }

    setNodes((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(nodesLocal)) {
        if (nodesLocal.length !== prev.length)
          setIsLoading((prevLoadingState) =>
            Object.assign(
              {},
              ...nodesLocal
                .filter(({ id }) => !id.includes('edgeDot_'))
                .map(({ id }) => ({ [id]: prevLoadingState[id] !== false })),
            ),
          );
        return nodesLocal.map((n) => {
          if (!n.id.includes('edgeDot_')) {
            return {
              ...n,
              data: {
                ...n.data,
                handleNodeLoading,
                isLoading: isLoading[n.id],
              },
            };
          }
          const [, edgeId] = n.id.split('_');
          const allEdgeNodes = prev.filter((node) =>
            node.id.includes(`edgeDot_${edgeId}`),
          );
          const isHidden =
            allEdgeNodes.findIndex((node) => node?.data.isHidden === false) ===
            -1;

          return {
            ...n,
            data: {
              ...n.data,
              isHidden,
              handleNodeLoading,
              isLoading: isLoading[n.id],
            },
          };
        });
      }
      return prev;
    });
    setEdges((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(edgesLocal)) {
        return edgesLocal.map((c) => ({
          ...c,
          type: 'custom',
          data: {
            ...c.data,
            handleClick: (id) => handleDeleteEdge(id),
          },
        }));
      }
      return prev;
    });
  }, [json, initPosition]);

  const handleDeleteEdge = (id: string) => {
    setEdges((prev) => prev.filter((e) => e.id !== id));
    handleEdges({ type: 'remove', id });
  };

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange[], ns: Node[]): Node[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0].type === 'position' &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], ns);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      return applyNodeChanges(changes, ns);
    },
    [],
  );

  const handleNodesChange = (changes: NodeChange[]) => {
    if (changes?.[0]?.type === 'position' && changes?.[0]?.dragging === false) {
      const { id } = changes[0];
      setIsDraggingOver(true);
      const edgeId = changes[0]?.id.split('_')?.[1];

      if (id.includes(`edgeDot`) && id.includes('extra')) {
        setNodes((prev: any) => {
          const allNodes = prev.filter(
            (node) => !node.id.includes(`edgeDot_${edgeId}`),
          );
          const extraNodes = prev.filter(
            (node) =>
              node.id.includes(`edgeDot_${edgeId}`) &&
              node.id.includes('extra'),
          );
          const mainNodes = prev.filter(
            (node) =>
              node.id.includes(`edgeDot_${edgeId}`) &&
              !node.id.includes('extra'),
          );

          const foundNewMainPoint = extraNodes.find((node) => node.id === id);

          if (foundNewMainPoint) {
            const { prevDot, nextDot } = foundNewMainPoint.data;

            const foundIndexPrevDot = mainNodes.findIndex(
              (node) => node.id === prevDot,
            );
            const foundIndexNextDot = mainNodes.findIndex(
              (node) => node.id === nextDot,
            );

            const newMainPoint = {
              id: `edgeDot_${edgeId}_${mainNodes.length}`,
              position: foundNewMainPoint.position,
              type: 'edgeDot',
              data: { isHidden: false },
            };

            if (foundIndexPrevDot !== -1 && foundIndexNextDot === -1) {
              mainNodes.splice(foundIndexPrevDot + 1, 0, newMainPoint);
            } else if (foundIndexNextDot !== -1) {
              mainNodes.splice(foundIndexNextDot, 0, newMainPoint);
            } else if (foundIndexPrevDot === -1 && foundIndexNextDot === -1) {
              return [...allNodes, ...mainNodes, newMainPoint];
            }

            return [
              ...allNodes,
              ...mainNodes.map((node, i) => {
                const [, eId] = node.id.split('_');

                return {
                  ...node,
                  id: `edgeDot_${eId}_${i}`,
                };
              }),
            ];
          }

          return prev;
        });
      }
      if (!isDisabledHelperLines) {
        setNodes((prev) => customApplyNodeChanges(changes, prev));
      }
    }
    onNodesChange(changes);
  };

  const customApplyEdgeChanges = (edgeChanges: EdgeChange[]) => {
    if (edgeChanges?.[0].type === 'remove')
      handleEdges({ type: 'remove', id: edgeChanges[0].id });
    onEdgesChange(edgeChanges);
  };

  const handleNodesDelete = (nodesToDelete: Node[]) => {
    handleNodeRemove({
      label: nodesToDelete[0].id,
      visualId: nodesToDelete[0].data.renderProperties.link.id,
    });
  };

  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    if (!isDraggingOver) return;
    setIsDraggingOver(false);
    handleDiagramChange({ nodes });
  }, [nodes?.filter(({ id }) => id.startsWith('edgeDot_')), isDraggingOver]);

  const nodeTypes = useMemo(() => nodesTypes, []);
  const edgeTypes = useMemo(() => ({ custom: CustomQEdge }), []);

  useEffect(() => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView();
      }, 50);
    }
  }, [isFullscreen]);

  useEffect(() => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView();
      }, 50);
    }
  }, [
    JSON.stringify(
      Object.entries(isLoading)?.filter(([key]) => key.indexOf('edgeDot_')),
    ),
  ]);

  useEffect(() => {
    if (!json?.backgroundColor) return;
    if (theme.palette.mode === 'dark' && json?.backgroundColorDark) {
      const color = hexToRGBA(colorToHex(json.backgroundColorDark), 100);
      setBgColor(color);
      return;
    }
    const color = hexToRGBA(colorToHex(json.backgroundColor), 100);
    setBgColor(color);
  }, [json?.backgroundColor, json?.backgroundColorDark, theme?.palette?.mode]);

  const onMove = useCallback((_, { zoom }) => {
    setCurrentZoom(zoom);
  }, []);

  const contextValue = useMemo(
    () => ({ setNodes, zoom: currentZoom, nodesDraggable: true }),
    [setNodes, currentZoom],
  );

  const onPaneClick = () => {
    setNodes((prev: Node[]) =>
      prev.map((node) =>
        node.id.includes('edgeDot')
          ? { ...node, data: { ...node.data, isHidden: true } }
          : node,
      ),
    );
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const newVisualId = event.dataTransfer.getData('application/reactflow');

    // check if the dropped element is valid
    if (typeof newVisualId === 'undefined' || !newVisualId) {
      return;
    }

    const { x, y } = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    handleNodeAdd({
      nodeId: newVisualId,
      position: { x: Math.round(x), y: Math.round(y) },
    });
  };

  const handleConnect = useCallback(
    (params: Connection) =>
      setEdges((eds) => {
        handleEdges({ type: 'add', edge: params });
        return addEdge(params, eds);
      }),
    [setEdges, handleEdges],
  );

  return (
    <Root ref={reactFlowWrapper}>
      <Context.Provider value={contextValue}>
        <ReactFlowStyled
          style={{ backgroundColor: bgColor }}
          nodes={nodes}
          edges={edges}
          edgeTypes={edgeTypes}
          // @ts-ignore
          nodeTypes={nodeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodesChange={handleNodesChange}
          onNodesDelete={handleNodesDelete}
          onEdgesChange={customApplyEdgeChanges}
          onConnect={handleConnect}
          fitView
          attributionPosition="top-right"
          minZoom={0.2}
          maxZoom={10}
          onMove={onMove}
          nodesDraggable={isEditable}
          nodesConnectable={isEditable}
          onNodeClick={(_, node) => {
            handleNodeClick({ nodeId: node.id });
          }}
          onNodeDragStop={(_, node) => {
            handleNodePosition({ node });
          }}
          onPaneClick={onPaneClick}
          elevateEdgesOnSelect
          elementsSelectable
          multiSelectionKeyCode="0"
          zoomOnDoubleClick={false}
        >
          <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>
            <MiniMap nodeColor={() => '#4C9FC8'} nodeStrokeWidth={1} />
          </Box>
          {showGrid && <Background size={0.75} gap={16} color="#ba88ce" />}
          <Controls
            toggleGrid={() => setShowGrid(!showGrid)}
            isInteractive
            toggleInteractive={() => {}}
            showGrid={showGrid}
            isEditable={isEditable}
            withFullscreen={withFullscreen}
            isFullscreen={isFullscreen}
            withGrid={false}
            onFullscreenEnter={onFullscreenEnter}
            onFullscreenExit={onFullscreenExit}
            reactFlowInstance={reactFlowInstance}
          />
          {!isDisabledHelperLines && (
            <HelperLines
              horizontal={helperLineHorizontal}
              vertical={helperLineVertical}
            />
          )}
        </ReactFlowStyled>
      </Context.Provider>
    </Root>
  );
};

CreateTemplateDiagram.defaultProps = {
  isEditable: false,
  isFullscreen: false,
  withFullscreen: false,
  onFullscreenEnter: () => {},
  onFullscreenExit: () => {},
};

export default CreateTemplateDiagram;
