import React from 'react';
import { LockOutlined } from '@mui/icons-material';
import { Stack, Typography, styled } from '@mui/material';

const ErrorContainer = styled(Stack)({
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '1.125rem',
  lineHeight: '1.3rem',
  textAlign: 'center',
  marginTop: theme.spacing(1),
}));

const LockIcon = styled(LockOutlined)(({ theme }) => ({
  color: theme.palette.text.disabled,
  height: '24px',
  width: '24px',
}));

interface ErrorProps {
  isError: boolean;
  children: JSX.Element | JSX.Element[];
}

const UnauthorizedComponentErrorBoundary = ({
  children,
  isError,
}: ErrorProps): JSX.Element => {
  if (isError) {
    return (
      <ErrorContainer>
        <LockIcon />
        <ErrorText>You do not have access to this widget</ErrorText>
      </ErrorContainer>
    );
  }

  return <>{children}</>;
};

const UnauthorizedPageErrorBoundary = ({
  children,
  isError,
}: ErrorProps): JSX.Element => {
  if (isError) {
    return (
      <ErrorContainer>
        <LockIcon />
        <ErrorText>You do not have access to this page.</ErrorText>
        <ErrorText>
          Contact administrator of your organization for more details.
        </ErrorText>
      </ErrorContainer>
    );
  }

  return <>{children}</>;
};

export { UnauthorizedComponentErrorBoundary, UnauthorizedPageErrorBoundary };
