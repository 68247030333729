import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function MachineIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M240-360h80v-240h-80v240Zm200 0h80v-240h-80v240Zm200 0h80v-240h-80v240Zm-480 80h640v-400H160v400Zm0 0v-400 400Zm40 160v-80h-40q-33 0-56.5-23.5T80-280v-400q0-33 23.5-56.5T160-760h40v-80h80v80h160v-80h80v80h160v-80h80v80h40q33 0 56.5 23.5T880-680v400q0 33-23.5 56.5T800-200h-40v80h-80v-80H520v80h-80v-80H280v80h-80Z" />
    </SvgIcon>
  );
}

export default MachineIcon;
