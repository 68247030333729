import React, { useState } from 'react';
import { Drawer, Grid } from '@mui/material';
import { XYPosition } from 'react-flow-renderer';
import { CategoryTitle, ContentWrap, GridWrap } from '../styles';
import { Flex } from '../../../styles';
import GroupPanel from '../GroupPanel';
import ElementsList from '../ElementsList';
import { VisualResponse } from '../../../../redux/services/visuals/types';
import ModalHeader from './ModalHeader';

interface Props {
  isListView: boolean;
  isTablet: boolean;
  isDrawerOpened: boolean;
  toggleDrawer: (val: boolean) => void;
  searchValue: string;
  activeVisualId: string;
  onClose: () => void;
  pasteHandler: ({
    nodeId,
    position,
  }: {
    nodeId: string;
    position?: XYPosition;
  }) => void;
  visuals: VisualResponse[];
  selectedVisuals: string[];
  handleActive: (val: string) => void;
  handleSelect: (val: string) => void;
  handleSearchInput: (ev: React.ChangeEvent<HTMLInputElement> | string) => void;
  toggleListView: (val: boolean) => void;
  handleInnerModalOpen: () => void;
}

const ModalContentLeft = (props: Props) => {
  const {
    isListView,
    isTablet,
    isDrawerOpened,
    toggleDrawer,
    searchValue,
    activeVisualId,
    onClose,
    pasteHandler,
    selectedVisuals,
    visuals,
    handleActive,
    handleSelect,
    handleInnerModalOpen,
    handleSearchInput,
    toggleListView,
  } = props;

  const [expandedCategory, setExpandedCategory] = useState<string | undefined>(
    undefined,
  );

  const handleCategoryChange = (value: string | undefined) => {
    setExpandedCategory(value);
  };

  return (
    <ContentWrap
      sx={{
        flex: { xs: 1, sm: '1 1 60%' },
        maxHeight: '100%',
        overflow: { xs: 'auto', sm: 'inherit' },
      }}
    >
      <ModalHeader
        isListView={isListView}
        isTablet={isTablet}
        handleInnerModalOpen={handleInnerModalOpen}
        handleSearchInput={handleSearchInput}
        toggleView={toggleListView}
        searchValue={searchValue}
        toggleDrawer={toggleDrawer}
        selectedVisuals={selectedVisuals}
      />
      <Flex sx={{ overflow: 'hidden', alignItems: 'flex-start' }}>
        <GroupPanel
          hide={isTablet}
          searchValue={searchValue}
          expandedCategory={expandedCategory}
          handleCategoryChange={handleCategoryChange}
        />
        {isTablet && (
          <Drawer
            sx={{ zIndex: 1401 }}
            open={isDrawerOpened}
            onClose={() => {
              toggleDrawer(false);
            }}
          >
            <GroupPanel
              searchValue={searchValue}
              expandedCategory={expandedCategory}
              handleCategoryChange={handleCategoryChange}
            />
          </Drawer>
        )}
        <GridWrap
          sx={{
            maxHeight: '100%',
            p: isListView ? 0 : 3,
          }}
        >
          {isTablet && <CategoryTitle>{expandedCategory}</CategoryTitle>}
          <Grid container spacing={isListView ? 0 : 2}>
            <ElementsList
              searchValue={searchValue}
              activeVisualId={activeVisualId}
              expandedCategory={expandedCategory}
              handleActive={handleActive}
              handleSelect={handleSelect}
              isListView={isListView}
              onClose={onClose}
              pasteHandler={pasteHandler}
              selectedVisuals={selectedVisuals}
              visuals={visuals}
            />
          </Grid>
        </GridWrap>
      </Flex>
    </ContentWrap>
  );
};

export default ModalContentLeft;
