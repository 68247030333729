import React from 'react';
import Loader from '../../../Loader';

interface Props {
  width: number;
  height: number;
}

const PlaceholderSVGComponent = ({ height, width }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <rect fill="#ddd" width="300" height="150" />
    <text
      fill="rgba(0,0,0,0.5)"
      fontFamily="sans-serif"
      fontSize="30"
      dy="10.5"
      fontWeight="bold"
      x="50%"
      y="50%"
      textAnchor="middle"
    >
      <Loader />
    </text>
  </svg>
);

export default PlaceholderSVGComponent;
