import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { QuestionMark } from '@mui/icons-material';
import { Box, Button, Fab, Link, styled, Tooltip } from '@mui/material';
import Loader from '../../common/Loader';
import { ROUTES_PATH } from '../../constants';
import { useGetCurrentUserQuery } from '../../redux/services/atreus/api';
import { useGetEdgeLoginFlowQuery } from '../../redux/services/edge/api';

const TemporaryPasswordContainer = styled(Box)({
  marginTop: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
});

const SecondaryLink = styled(Link)(({ theme }) => ({
  color: `${theme.palette.text.secondary} !important`,
  fontSize: '12px',
  fontWeight: 'bold',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const TooltipBackground = styled(Fab)({
  background: '#F2E3F9',
  width: 20,
  height: 20,
  minHeight: 20,
  boxShadow: 'none',
});

const QuestionMarkIcon = styled(QuestionMark)({
  width: 12,
  height: 12,
});

const LOGIN_TOOLTIP_TEXT = `A temporary user password is a short living password, only valid on this HMI, that can be used even when internet connectivity is not available. Temporary passwords will be provisioned to users when signing in through the cloud single sign on system.`;

interface Props {
  hasInternetAccess: boolean;
}

const KratosLoginForm: React.FC<Props> = (props: Props) => {
  const { hasInternetAccess } = props;
  const history = useHistory();

  const { data: edgeLoginFlow, isLoading: isEdgeLoginFlowLoading } =
    useGetEdgeLoginFlowQuery();
  const { data: currentUser } = useGetCurrentUserQuery('');

  const oidcInputNode = useMemo(() => {
    if (edgeLoginFlow) {
      return edgeLoginFlow?.ui.nodes.find((it) => it.group === 'oidc');
    }
    return null;
  }, [edgeLoginFlow]);

  useEffect(() => {
    if (currentUser?.traits) history.push(ROUTES_PATH.DASHBOARD);
  }, [currentUser]);

  if (isEdgeLoginFlowLoading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Loader position="relative" x="0" y="0" nonetransform="true" />
      </Box>
    );

  return (
    <form action={edgeLoginFlow?.ui.action} method={edgeLoginFlow?.ui.method}>
      <Tooltip
        title={
          hasInternetAccess
            ? null
            : 'Cloud sign in through your SSO system is disabled as this system is offline.'
        }
      >
        <Box>
          <Button
            fullWidth
            variant="contained"
            name={oidcInputNode?.attributes.name}
            type="submit"
            value={oidcInputNode?.attributes.value}
            disabled={
              !hasInternetAccess ||
              !oidcInputNode ||
              oidcInputNode?.attributes.disabled
            }
          >
            Sign in
          </Button>
        </Box>
      </Tooltip>
      <TemporaryPasswordContainer>
        <SecondaryLink href={ROUTES_PATH.LOGIN_WITH_TEMPORARY_PASSWORD}>
          Sign In with Temporary Password
        </SecondaryLink>
        <Tooltip title={LOGIN_TOOLTIP_TEXT}>
          <TooltipBackground>
            <QuestionMarkIcon color="primary" />
          </TooltipBackground>
        </Tooltip>
      </TemporaryPasswordContainer>
    </form>
  );
};

export default KratosLoginForm;
