import React, { useEffect, useMemo, useState } from 'react';
import useSnackbar from '../../../hooks/useSnackbar';
import { FormProps } from '../../../redux/services/users/types';
import { SettingsForm } from '../../auth/ory';
import DialogComponent from '../../../common/Dialogs/DialogComponent';
import { ModalContent } from '../../../common/Dialogs/styles';
import Loader from '../../../common/Loader';
import {
  getErrorStatus,
  getPasswordPatternCheck,
  parseErrorMessage,
} from '../../../utils/helper';
import { useUpdateCurrentUserMutation } from '../../../redux/services/users/api';
import {
  useGetCurrentUserQuery,
  useGetPasswordSettingsQuery,
} from '../../../redux/services/atreus/api';
import useOidcCloud from '../../../hooks/useOidcCloud';
import { useAppSelector } from '../../../redux/store';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';

interface Props {
  isOpened: boolean;
  closeModal: () => void;
  onPasswordUpdated?: () => void;
}

const titleModal = 'Edit profile';

const initialFormState: FormProps = {
  email: '',
  firstName: '',
  lastName: '',
  password: undefined,
  passwordConfirmation: undefined,
};

const EditProfile = (props: Props) => {
  const { isOpened, closeModal, onPasswordUpdated } = props;
  const { accessToken } = useOidcCloud();
  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );
  const [state, setState] = useState<FormProps>(initialFormState);

  const { showError, showSuccess } = useSnackbar();
  const { data: currentUser, isLoading: isGetCurrentUserLoading } =
    useGetCurrentUserQuery(accessToken, {
      skip: !accessToken && !isEdgeEnv,
    });
  const { data: passwordSettings } = useGetPasswordSettingsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [
    updateCurrentUser,
    { isSuccess: isUpdateSuccess, error: updateError, originalArgs },
  ] = useUpdateCurrentUserMutation();

  const handleSave = () => {
    const newState = Object.keys(state)
      .filter((k) => state[k] != null)
      .reduce((a, k) => ({ ...a, [k]: state[k] }), {}) as FormProps;
    updateCurrentUser(newState);
  };

  useEffect(() => {
    if (currentUser) {
      setState({
        ...initialFormState,
        email: currentUser.traits.email,
        firstName: currentUser.traits.name.first,
        lastName: currentUser.traits.name.last,
      });
    }
  }, [currentUser, isOpened]);

  useEffect(() => {
    if (updateError) {
      if (originalArgs.password && getErrorStatus(updateError) === 400) {
        showError(`Password is too weak`);
        return;
      }
      if (getErrorStatus(updateError) === 403) {
        showError(parseErrorMessage(updateError, 'You do not have permission'));
        return;
      }
      if (getErrorStatus(updateError) === 410) {
        showError(
          parseErrorMessage(
            updateError,
            'The address of the request was changed or gone',
          ),
        );
        return;
      }
      if (originalArgs.password) {
        showError('Failed to save password');
      } else {
        showError('Failed to save profile');
      }
    }
  }, [updateError, originalArgs]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (originalArgs.password) {
        showSuccess('Saved password');
      } else {
        showSuccess('Saved profile');
      }
      if (originalArgs.password) onPasswordUpdated();
      closeModal();
    }
  }, [isUpdateSuccess, originalArgs]);

  const isUserDataChanged = useMemo(() => {
    if (!currentUser) return false;
    return (
      state.email !== currentUser.traits.email ||
      state.firstName !== currentUser.traits.name.first ||
      state.lastName !== currentUser.traits.name.last
    );
  }, [state, currentUser]);

  const patterns = useMemo(() => {
    if (!passwordSettings) return null;
    return getPasswordPatternCheck(state.password, passwordSettings);
  }, [passwordSettings, state.password]);

  const isAllPatternsPassed =
    patterns?.filter(({ isMatched }) => !isMatched)?.length < 1;
  const isConfirmPasswordError =
    (state.passwordConfirmation || state.password) &&
    (!isAllPatternsPassed || state.password !== state.passwordConfirmation);
  const isPasswordError =
    (state.password || state.passwordConfirmation) && !isAllPatternsPassed;
  const isSaveButtonDisabled = useMemo(() => {
    if (state.passwordConfirmation === state.password && isAllPatternsPassed) {
      return false;
    }
    if (isUserDataChanged && !isPasswordError && !isConfirmPasswordError)
      return false;

    return true;
  }, [isUserDataChanged, isAllPatternsPassed, state]);

  return (
    <DialogComponent
      isOpened={isOpened}
      closeModal={closeModal}
      title={titleModal}
      actionTitle="Save"
      handleAction={handleSave}
      cancelId="EditProfile-cancel"
      isActionButtonDisabled={isSaveButtonDisabled}
      actionId="EditProfile-Save-button"
    >
      <ModalContent sx={{ minHeight: { md: '240px', sm: 'inherit' } }}>
        {isGetCurrentUserLoading ? (
          <Loader />
        ) : (
          <SettingsForm
            state={state}
            setState={setState}
            isConfirmPasswordError={isConfirmPasswordError}
            isPasswordError={isPasswordError}
            patterns={patterns}
          />
        )}
      </ModalContent>
    </DialogComponent>
  );
};

EditProfile.defaultProps = {
  onPasswordUpdated: () => {},
};

export default EditProfile;
