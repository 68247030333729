import React, { useEffect, useState, useMemo } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { Box, styled } from '@mui/material';
import Progress from './Progress';
import { useGetPortsQuery } from '../../../../redux/services/ports/api';

const Root = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  '&:hover .edit-btn': {
    opacity: 1,
  },
  '& .progress': {
    top: -10,
    left: -56,
  },
  '& .edit-btn': {
    minWidth: '4rem',
    minHeight: '4rem',
    position: 'absolute',
    right: '-64px',
    top: 24,
    opacity: 0,
    '& svg': {
      fontSize: '3rem',
    },
  },
});

const Label = styled('span')(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  zIndex: 99,
  left: 4,
  right: 4,
  top: '35%',
  lineHeight: '1.5',
  textAlign: 'center',
  fontSize: '2rem',
  color: '#484848',
}));

interface Props {
  isLibrary?: boolean;
  progress?: number;
  id: string;
  data?: any;
}

function WaterV1(props: Props) {
  const { isLibrary, progress, id, data } = props;
  const { size } = data || {};
  const { width, height } = size || {};

  const { data: ports } = useGetPortsQuery(undefined);

  const name = useMemo(() => 'Water', []);

  const [port, setPort] = useState([]);
  useEffect(() => {
    if (ports) {
      setPort(ports.filter(({ parentAggregate }) => parentAggregate === id));
    }
  }, [ports]);

  return (
    <Root
      style={{
        width: width || '',
        height: height || '',
      }}
    >
      {!isLibrary && (
        <Progress progress={progress} className="progress" maxValue={10} />
      )}
      <Box sx={{ position: 'relative' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="309"
          height="252"
          viewBox="0 0 112 71.849"
        >
          <g id="Water" transform="translate(0.05 0.974)">
            <g
              id="Path_37"
              data-name="Path 37"
              transform="translate(-475.842 -291.727)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M475.791,290.753h112v34.976l-35.109,36.825s-41.363.108-41.771,0c-1.7-.45-35.12-36.825-35.12-36.825Z"
                stroke="none"
              />
              <path
                d="M 477.1915283203125 292.1529235839844 L 477.1915283203125 325.18310546875 C 478.8627319335938 326.9997253417969 485.947998046875 334.693115234375 493.2244262695312 342.47021484375 C 498.0872802734375 347.6676635742188 502.0818481445312 351.8840942382812 505.0972900390625 355.0024108886719 C 509.4388427734375 359.4920959472656 510.8762817382812 360.7938842773438 511.343505859375 361.1702575683594 C 512.12158203125 361.1847839355469 514.5921020507812 361.2019958496094 521.9222412109375 361.2019958496094 C 532.9078369140625 361.2019958496094 548.8173828125 361.1636657714844 552.0814819335938 361.1554870605469 L 586.3914794921875 325.1684875488281 L 586.3914794921875 292.1529235839844 L 477.1915283203125 292.1529235839844 M 475.79150390625 290.7529296875 L 587.79150390625 290.7529296875 L 587.79150390625 325.7289428710938 L 552.6824951171875 362.5539855957031 C 552.6824951171875 362.5539855957031 534.2989501953125 362.6019897460938 521.9222412109375 362.6019897460938 C 515.7327880859375 362.6019897460938 511.0473022460938 362.5899963378906 510.9111328125 362.5539855957031 C 509.2092895507812 362.1039123535156 475.79150390625 325.7289428710938 475.79150390625 325.7289428710938 L 475.79150390625 290.7529296875 Z"
                stroke="none"
                fill="#3b82f6"
              />
            </g>
          </g>
        </svg>
        <Label>{name}</Label>
        {port.length ? (
          port.map((p, i) => (
            <Handle
              key={p._id}
              className="point"
              type={p.name === 'Outlet' ? 'target' : 'source'}
              position={
                [Object.values(Position)[0], Object.values(Position)[2]][i]
              }
              id="a"
            />
          ))
        ) : (
          <>
            <Handle
              className="point"
              type="source"
              position={Position.Right}
              id="a"
            />
            <Handle
              className="point"
              type="target"
              position={Position.Left}
              id="a"
            />
          </>
        )}
      </Box>
    </Root>
  );
}
WaterV1.defaultProps = {
  data: {},
  isLibrary: false,
  progress: 0,
};

export default WaterV1;
