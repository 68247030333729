import React, { useRef } from 'react';
import { useParams } from 'react-router';
import {
  FormControl,
  OutlinedInput,
  Box,
  styled,
  Tooltip,
  InputAdornment,
  IconButton,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  CloseRounded,
  CodeRounded,
  FileDownloadOutlined,
  FileUploadOutlined,
  FullscreenRounded,
  IntegrationInstructionsOutlined,
} from '@mui/icons-material';
import { isIOS } from 'react-device-detect';
import { FullScreenHandle } from 'react-full-screen';
import { FullscreenBtn } from './styles';
import useYAMLVisualEditorState from '../../../hooks/useYAMLVisualEditorState';
import { YAML_LANGUAGE } from '../../../constants';
import useYAMLTemplateEditorState from '../../../hooks/useYAMLTemplateEditorState';
import { SwitchBtn, SwitchWrap } from '../../../common/styles/switch';
import { SystemIcon } from '../../../icons';

const Control = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: 0,
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '',
  },
}));

const IconBtn = styled(FullscreenBtn)(({ theme }) => ({
  width: 42,
  height: 42,
  color: theme.palette.text.secondary,
}));

const ControlBtn = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  color: theme.palette.text.secondary,
}));

interface Props {
  fullscreenLeft: boolean;
  handleLeft: FullScreenHandle;
  setFullscreenLeft: React.Dispatch<React.SetStateAction<boolean>>;
  isVisuals?: boolean;
  isEditor?: boolean | undefined;
  handleEditorMode?: () => void;
}

export interface FullscreenHandlerComponentProps {
  handle: FullScreenHandle;
  isFullscreen: boolean;
  setFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
  hasFile?: boolean;
}

const FullscreenHandlerComponent = ({
  handle,
  isFullscreen,
  setFullscreen,
  isVisuals,
  hasFile,
}: FullscreenHandlerComponentProps & { isVisuals: boolean }) => {
  const fileInputRef = useRef<HTMLInputElement>();

  const { id } = useParams<{ id: string }>();
  const { handleFileDownload, handleFileUploading } = useYAMLVisualEditorState({
    id,
  });

  if (isIOS)
    return (
      <>
        {!hasFile && (
          <Tooltip title="Upload YAML">
            <IconBtn component="label">
              <FileUploadOutlined />
              <input
                ref={fileInputRef}
                type="file"
                hidden
                onChange={(ev) =>
                  handleFileUploading(ev, fileInputRef, YAML_LANGUAGE)
                }
                accept=".yaml"
              />
            </IconBtn>
          </Tooltip>
        )}
        <Tooltip title="Download YAML">
          <IconBtn
            onClick={() => {
              handleFileDownload(YAML_LANGUAGE);
            }}
          >
            <FileDownloadOutlined />
          </IconBtn>
        </Tooltip>
        {isFullscreen ? (
          <Tooltip title="Close">
            <IconBtn className="active" onClick={() => setFullscreen(false)}>
              <CloseRounded />
            </IconBtn>
          </Tooltip>
        ) : (
          <Tooltip title="Fullscreen">
            <IconBtn onClick={() => setFullscreen(true)}>
              <FullscreenRounded />
            </IconBtn>
          </Tooltip>
        )}
      </>
    );

  return (
    <>
      {!hasFile && isVisuals && (
        <Tooltip title="Upload YAML">
          <IconBtn component="label">
            <FileUploadOutlined />
            <input
              ref={fileInputRef}
              type="file"
              hidden
              accept=".yaml"
              onChange={(ev) =>
                handleFileUploading(ev, fileInputRef, YAML_LANGUAGE)
              }
            />
          </IconBtn>
        </Tooltip>
      )}
      {isVisuals && (
        <Tooltip title="Download YAML">
          <IconBtn
            onClick={() => {
              handleFileDownload(YAML_LANGUAGE);
            }}
          >
            <FileDownloadOutlined />
          </IconBtn>
        </Tooltip>
      )}
      {handle.active ? (
        <Tooltip title="Fullscreen">
          <IconBtn
            className="active"
            onClick={handle.exit}
            id="EditorView_Button-ExitFullscreenView"
          >
            <CloseRounded />
          </IconBtn>
        </Tooltip>
      ) : (
        <Tooltip title="Fullscreen">
          <IconBtn
            onClick={handle.enter}
            id="EditorView_Button-EnterFullscreenView"
          >
            <FullscreenRounded />
          </IconBtn>
        </Tooltip>
      )}
    </>
  );
};

FullscreenHandlerComponent.defaultProps = {
  hasFile: false,
};

const LeftContentBlock = ({
  fullscreenLeft,
  handleLeft,
  setFullscreenLeft,
  isVisuals,
  isEditor,
  handleEditorMode,
}: Props): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { id } = useParams<{ id: string }>();
  const {
    name: visualsName,
    fileName: visualsFileName,
    handleNameChange: handleVisualsNameChange,
    handleFileName: handleVisualsFileNameChange,
    handleFileUploading: handleVisualsFileUploading,
  } = useYAMLVisualEditorState({ id });
  const { name: templateName, handleNameChange: handleTemplateNameChange } =
    useYAMLTemplateEditorState({ id });

  const name = isVisuals ? visualsName : templateName;
  const fileName = isVisuals ? visualsFileName : undefined;
  const handleNameChange = isVisuals
    ? handleVisualsNameChange
    : handleTemplateNameChange;
  const handleFileName = isVisuals ? handleVisualsFileNameChange : undefined;
  const handleFileUploading = isVisuals
    ? handleVisualsFileUploading
    : undefined;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Control>
          <OutlinedInput
            value={name}
            onChange={(event) => handleNameChange(`${event.target.value}`)}
            placeholder={`Enter ${isVisuals ? 'visual' : 'template'} name`}
            id="name"
            fullWidth
            sx={{ height: 42 }}
          />
        </Control>
        {isEditor !== undefined && (
          <SwitchWrap sx={{ height: '44px' }}>
            <Tooltip title="Editor view">
              <SwitchBtn
                onClick={handleEditorMode}
                className={isEditor && 'active'}
                color="inherit"
                sx={{ height: '38px !important', width: '38px' }}
              >
                <CodeRounded />
              </SwitchBtn>
            </Tooltip>
            <Tooltip title="Table view">
              <SwitchBtn
                onClick={handleEditorMode}
                className={!isEditor && 'active'}
                color="inherit"
                sx={{ height: '38px !important', width: '38px' }}
              >
                <SystemIcon />
              </SwitchBtn>
            </Tooltip>
          </SwitchWrap>
        )}
        {fileName && !isMobile && handleFileName && (
          <Control sx={{ ml: 2 }}>
            <OutlinedInput
              value={fileName}
              onChange={(event) => handleFileName(`${event.target.value}`)}
              placeholder="Enter file name"
              id="file-name"
              fullWidth
              sx={{ height: 42 }}
              startAdornment={
                <InputAdornment position="start">
                  <IntegrationInstructionsOutlined />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end" sx={{ height: 42 }}>
                  <Divider orientation="vertical" flexItem sx={{ mr: '4px' }} />
                  {fileName && (
                    <Tooltip
                      title="Upload YAML"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <ControlBtn>
                        <FileUploadOutlined />
                        <input
                          ref={fileInputRef}
                          type="file"
                          hidden
                          onChange={(ev) =>
                            handleFileUploading(ev, fileInputRef, YAML_LANGUAGE)
                          }
                          accept=".yaml"
                        />
                      </ControlBtn>
                    </Tooltip>
                  )}
                </InputAdornment>
              }
            />
          </Control>
        )}
        <FullscreenHandlerComponent
          handle={handleLeft}
          isFullscreen={fullscreenLeft}
          setFullscreen={setFullscreenLeft}
          hasFile={Boolean(fileName)}
          isVisuals={isVisuals}
        />
      </Box>
      {isMobile && (
        <Control sx={{ ml: { sm: 2, xs: 0 }, mb: { sm: 0, xs: 2 } }}>
          <OutlinedInput
            value={fileName}
            onChange={(event) => handleFileName(`${event.target.value}`)}
            placeholder="Enter file name"
            id="file-name"
            fullWidth
            readOnly
            sx={{ height: 42 }}
            startAdornment={
              <InputAdornment position="start">
                <IntegrationInstructionsOutlined />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end" sx={{ height: 42 }}>
                <Divider orientation="vertical" flexItem sx={{ mr: '4px' }} />
                <Tooltip
                  title="Upload YAML"
                  onClick={() => fileInputRef.current.click()}
                >
                  <ControlBtn>
                    <FileUploadOutlined />
                    <input
                      ref={fileInputRef}
                      type="file"
                      hidden
                      onChange={(ev) =>
                        handleFileUploading(ev, fileInputRef, YAML_LANGUAGE)
                      }
                      accept=".yaml"
                    />
                  </ControlBtn>
                </Tooltip>
              </InputAdornment>
            }
          />
        </Control>
      )}
    </>
  );
};

LeftContentBlock.defaultProps = {
  isVisuals: true,
  isEditor: undefined,
  handleEditorMode: undefined,
};

export default LeftContentBlock;
