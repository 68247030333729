import React, { useEffect } from 'react';
import { DialogContent } from '@mui/material';
import DialogComponent from '../../Dialogs/DialogComponent';
import { useDeleteVisualCategoryMutation } from '../../../redux/services/visualCategory/api';
import useSnackbar from '../../../hooks/useSnackbar';

interface Props {
  open: boolean;
  categoryId: string;
  onClose: () => void;
  name: string;
}

const DeleteGroup = (props: Props) => {
  const { open, categoryId, onClose, name } = props;

  const { showSuccess, showForbiddenError } = useSnackbar();

  const [
    deleteVisualCategory,
    { isSuccess: isDeleteVisualSuccess, error: deleteVisualError },
  ] = useDeleteVisualCategoryMutation();

  const handleDeleteGroup = async () => {
    deleteVisualCategory({ id: categoryId });
  };

  useEffect(() => {
    if (isDeleteVisualSuccess) {
      showSuccess(`${name} visual group has been successfully deleted`);

      onClose();
    }
  }, [isDeleteVisualSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: deleteVisualError,
      customForbiddenMessage:
        "You don't have enough permissions to delete visual group",
      customDefaultMessage:
        "Visual group hasn't been deleted. Something went wrong",
    });
  }, [deleteVisualError]);

  return (
    <DialogComponent
      isOpened={open}
      handleAction={handleDeleteGroup}
      closeModal={onClose}
      title="Delete group"
      actionTitle="Delete"
      id="DeleteGroup-dialog"
      actionId="DeleteGroup-delete"
      cancelId="DeleteGroup-cancel"
      sx={{ zIndex: 1403 }}
    >
      <DialogContent>
        Are you sure you want to delete <strong>{name}</strong> group?
      </DialogContent>
    </DialogComponent>
  );
};

export default DeleteGroup;
