import React, { useEffect, useState } from 'react';
import {
  useGetVisualDocumentQuery,
  useGetVisualsByCategoryQuery,
  useUpdateVisualMutation,
} from '../../../redux/services/visuals/api';
import { FORMDATA_DEFINITION } from './Modal';
import { createBlob, createFile } from '../../../utils/editorPureFn';
import useSnackbar from '../../../hooks/useSnackbar';

interface Props {
  elementIds: string[];
  categoryName: string;
  handleCloseUpdate: () => void;
}

interface ItemProps {
  id: string;
  categoryName: string;
}

interface ItemRemoveProps extends ItemProps {
  isSingle?: boolean;
}

export const RemoveItemFromCategoryComponent = (props: ItemRemoveProps) => {
  const { id, isSingle, categoryName } = props;

  const { showForbiddenError, showSuccess } = useSnackbar();

  const { data: visualDocument } = useGetVisualDocumentQuery(id);
  const [
    updateCategory,
    { error: itemUpdateError, isSuccess: isItemUpdateSuccess },
  ] = useUpdateVisualMutation();

  useEffect(() => {
    if (!visualDocument) return;
    const match = visualDocument.match(/category: .*?\n/gm);
    const name = visualDocument
      .match(/name: .*?\n/gm)?.[0]
      ?.replace('name: ', '')
      ?.replace('\n', '');
    const newDocument = visualDocument.replace(match[0], `category: null\n`);
    const formData = new FormData();
    formData.append(
      FORMDATA_DEFINITION,
      createFile(
        createBlob(newDocument),
        `${name || 'visual'}.yaml`,
        'text/yaml',
      ),
    );
    updateCategory({ id, form: formData });
  }, [visualDocument]);

  useEffect(() => {
    showForbiddenError({
      error: itemUpdateError,
      customForbiddenMessage: `You don't have enough permissions to update ${categoryName} visual group`,
      customDefaultMessage: `${categoryName} visual group hasn't been updated. Something went wrong`,
    });
  }, [itemUpdateError]);

  useEffect(() => {
    if (!isItemUpdateSuccess) return;
    if (!isSingle) return;
    showSuccess(`${categoryName} visual group has been successfully updated`);
  }, [isItemUpdateSuccess]);

  return null;
};

RemoveItemFromCategoryComponent.defaultProps = {
  isSingle: false,
};

const AddItemToCategoryComponent = (props: ItemProps) => {
  const { id, categoryName } = props;

  const { showForbiddenError } = useSnackbar();

  const { data: visualDocument } = useGetVisualDocumentQuery(id);
  const [updateCategory, { error: itemUpdateError }] =
    useUpdateVisualMutation();

  useEffect(() => {
    if (!visualDocument) return;
    const match = visualDocument.match(/category: .*?\n/gm);
    const name = visualDocument
      .match(/name: .*?\n/gm)?.[0]
      ?.replace('name: ', '')
      ?.replace('\n', '');
    const newDocument = visualDocument.replace(
      match[0],
      `category: ${categoryName}\n`,
    );

    const formData = new FormData();
    formData.append(
      FORMDATA_DEFINITION,
      createFile(
        createBlob(newDocument),
        `${name || 'visual'}.yaml`,
        'text/yaml',
      ),
    );
    updateCategory({ id, form: formData });
  }, [visualDocument]);

  useEffect(() => {
    showForbiddenError({
      error: itemUpdateError,
      customForbiddenMessage: `You don't have enough permissions to update ${categoryName} visual group`,
      customDefaultMessage: `${categoryName} visual group hasn't been updated. Something went wrong`,
    });
  }, [itemUpdateError]);

  return null;
};

const UpdateCategoryComponent = (props: Props) => {
  const { categoryName, elementIds, handleCloseUpdate } = props;

  const { showSuccess } = useSnackbar();

  const [idsToRemove, setIdsToRemove] = useState<string[]>();
  const [idsToAdd, setIdsToAdd] = useState<string[]>();
  const [startPolling, setStartPolling] = useState<boolean>(false);
  const [initialList, setInitialList] = useState<string[]>();

  const { data: visuals } = useGetVisualsByCategoryQuery(categoryName, {
    pollingInterval: startPolling ? 1000 : 0,
  });

  useEffect(() => {
    if (!visuals) return;
    setInitialList(visuals.map(({ id }) => id).sort());
  }, [visuals]);

  useEffect(() => {
    if (!initialList) return;
    setIdsToRemove(initialList?.filter((id) => elementIds.indexOf(id) === -1));
  }, [initialList, elementIds]);
  useEffect(() => {
    if (!initialList) return;
    setIdsToAdd(elementIds.filter((id) => initialList?.indexOf(id) === -1));
  }, [initialList, elementIds]);

  useEffect(() => {
    if (!initialList) return;
    if (
      JSON.stringify(initialList.sort()) !== JSON.stringify(elementIds.sort())
    )
      return;
    setStartPolling(false);
    showSuccess(`${categoryName} visual group has been successfully updated`);
    handleCloseUpdate();
  }, [elementIds, initialList]);

  useEffect(() => {
    if (idsToAdd?.length === 0 && idsToRemove?.length === 0) return;
    setStartPolling(true);
  }, [idsToAdd?.length, idsToRemove?.length]);

  return (
    <>
      {idsToAdd?.map((id) => (
        <AddItemToCategoryComponent
          key={id}
          id={id}
          categoryName={categoryName}
        />
      ))}
      {idsToRemove?.map((id) => (
        <RemoveItemFromCategoryComponent
          key={id}
          id={id}
          categoryName={categoryName}
        />
      ))}
    </>
  );
};

export default UpdateCategoryComponent;
