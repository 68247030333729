import {
  Accordion,
  AccordionSummary,
  alpha,
  Box,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material';
import { Flex } from '../../../../styles';

export const Item = styled(Flex)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  justifyContent: 'space-between',
}));

export const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  margin: 0,
  flex: 1,
  '&.active': {
    borderColor: alpha(theme.palette.secondary.main, 0.7),
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },
}));

export const Group = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: `0.75rem ${theme.spacing(2)}`,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
  '&.Mui-expanded': {
    marginTop: theme.spacing(1),
  },
  '&:first-of-type': {
    marginTop: 0,
  },
}));

export const GroupHead = styled(AccordionSummary)({
  minHeight: 26,
  overflow: 'hidden',

  '>.MuiAccordionSummary-content': {
    overflow: 'hidden',
  },

  '& p': {
    fontSize: '1.23rem',
  },
});

export const Count = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.5),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  fontSize: '1.07rem',
}));

export const ImportItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const GroupsContainer = styled(Box)({
  padding: '0 2rem',
});
