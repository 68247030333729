import React from 'react';
import { Button, DialogTitle, Typography } from '@mui/material';
import { Modal, ModalContent } from '../../common/Dialogs/styles';
import { useAppSelector } from '../../redux/store';
import { IAppState } from '../../typescript/interfaces/appstate.interface';

const ReloginModal = () => {
  const user = useAppSelector((state: IAppState) => state.user.user);

  const redirectToLogin = () => {
    window.open(`${window.location.origin}/relogin`, '_blank');
  };

  return (
    <Modal
      id="ReloginModal-container"
      open={user === undefined}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <DialogTitle
          id="ReloginModal-title"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          Please log in to continue
        </DialogTitle>
        <ModalContent>
          <Typography id="ReloginModal-description">
            Login page will be opened in new tab
          </Typography>
          <Button
            id="ReloginModal-login-button"
            variant="contained"
            sx={{ width: '100%', mt: '2rem', mb: '1rem' }}
            onClick={redirectToLogin}
          >
            Log in
          </Button>
        </ModalContent>
      </>
    </Modal>
  );
};

export default ReloginModal;
