import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  styled,
  Typography,
} from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

const Summary = styled(AccordionSummary)(({ theme }) => ({
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.04)'
        : 'rgba(255, 255, 255, 0.04)',
  },
}));

interface Props {
  data: any;
}

const AccordeonComponent = ({
  title,
  value,
}: {
  title: string;
  value: any;
}) => {
  const isObj =
    typeof value === 'object' && value !== null
      ? Object.values(value).length > 0
      : false;
  return (
    <>
      {isObj && value !== undefined && value !== null ? (
        <Accordion key={title} sx={{ width: '100%' }}>
          <Summary expandIcon={<ExpandMoreRounded />}>
            <Typography variant="subtitle2">{title}</Typography>
          </Summary>
          <AccordionDetails
            sx={{
              pl: 2,
              '& .MuiAccordionSummary-root': { minHeight: '1rem !important' },
            }}
          >
            <List sx={{ px: 0 }}>
              <>
                {Object.entries(value).map(([keyInner, valueInner]: any) => (
                  <ListItem
                    sx={{
                      px: '4px',
                      py: 1,
                      width: '100%',
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        mr: '-4px',
                      },
                      '& .MuiListItem-root': {
                        paddingRight: 0,
                      },
                    }}
                    key={keyInner}
                  >
                    <AccordeonComponent title={keyInner} value={valueInner} />
                  </ListItem>
                ))}
              </>
            </List>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography key={title}>
          {typeof value === 'string' || value === 'number'
            ? `${title} : ${value}`
            : title}
        </Typography>
      )}
    </>
  );
};

const Table = (props: Props) => {
  const { data } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'auto',
        px: 2,
        maxHeight: '100%',
        '&>p:first-of-type': {
          mt: 2,
        },
        '&>section': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100% !important',
        },
      }}
    >
      {data
        ? Object.entries(data).map(([key, value]) => (
            <AccordeonComponent key={key} title={key} value={value} />
          ))
        : 'Empty'}
    </Box>
  );
};

export default Table;
