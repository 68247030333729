import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import Search from '../Search';
import { TrashIcon } from '../../icons';
import { IMPORT_APPS } from '../../constants';

interface Props {
  open: boolean;
  onClose: () => void;
}

const Head = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

const Title = styled(DialogTitle)(() => ({
  padding: '0 !important',
  lineHeight: 1.5,
  marginRight: 'auto',
}));

const Icon = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: theme.shape.borderRadius,
}));

const IconWrap = styled(ListItemAvatar)({
  minWidth: 36,
});

const SubTitle = styled(ListSubheader)(({ theme }) => ({
  textTransform: 'none',
  color: alpha(theme.palette.text.secondary, 0.75),
  backgroundColor:
    theme.palette.mode === 'dark' ? '#1E2B40' : theme.palette.background.paper,
}));

const Item = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'light' ? '#F7FAFC' : '#3b475c',
    '& .hover-action': {
      visibility: 'visible',
    },
  },
  '& .hover-action': {
    visibility: 'hidden',
  },
}));

const TextBtn = styled(Button)(() => ({
  padding: 0,
  minWidth: 0,
  minHeight: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

function ManageApps(props: Props) {
  const { open, onClose } = props;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const [isSearch, setSearchExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredApps] = useState(IMPORT_APPS);

  const handleSearchClear = () => {
    setSearchValue('');
    setSearchExpanded(false);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleExpandSearch = () => {
    setSearchExpanded(true);
  };

  const touchMobile = !desktop || isMobile;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="manage-apps-title"
      aria-describedby="manage-apps-description"
      sx={{
        zIndex: 9999,
        '& .MuiPaper-root': { width: { sm: '540px', xs: '100%' } },
      }}
    >
      <Head>
        {isSearch ? (
          <Search
            sx={{ ml: 0 }}
            placeholder="Search apps.."
            id="ManageApps-search"
            value={searchValue}
            onSearch={handleSearch}
            onCancel={handleSearchClear}
          />
        ) : (
          <>
            <Title id="manage-apps-title">Manage apps</Title>
            {touchMobile ? (
              <IconButton onClick={handleExpandSearch}>
                <SearchRounded />
              </IconButton>
            ) : (
              <Search
                sx={{ ml: '80px' }}
                placeholder="Search apps.."
                id="ManageApps-search"
                value={searchValue}
                onSearch={handleSearch}
                onCancel={handleSearchClear}
                defaultExpanded
              />
            )}
          </>
        )}
        <IconButton sx={{ mr: -2 }} onClick={onClose}>
          <CloseRounded />
        </IconButton>
      </Head>
      <DialogContent sx={{ pt: 0, px: 3 }}>
        <List sx={{ p: 0 }}>
          {filteredApps.filter((app) => app.installed).length > 0 && (
            <SubTitle>Installed</SubTitle>
          )}
          {filteredApps
            .filter(
              (app) =>
                app.installed &&
                app.name.toLowerCase().includes(searchValue.toLowerCase()),
            )
            .map((l) => (
              <Item
                key={l.id}
                secondaryAction={
                  <TextBtn
                    className={touchMobile ? '' : 'hover-action'}
                    startIcon={<TrashIcon />}
                    color="error"
                    sx={{ '& .MuiButton-startIcon': { mr: { sm: 1, xs: 0 } } }}
                  >
                    {touchMobile ? '' : 'Remove'}
                  </TextBtn>
                }
              >
                <IconWrap>
                  <Icon src="/" alt={l.name} />
                </IconWrap>
                <ListItemText
                  sx={{ my: 0 }}
                  primary={`${l.name} ${l.version}`}
                />
              </Item>
            ))}
          {filteredApps.filter((app) => app.installed === false).length > 0 && (
            <SubTitle>Available apps</SubTitle>
          )}
          {filteredApps
            .filter(
              (app) =>
                app.installed === false &&
                app.name.toLowerCase().includes(searchValue.toLowerCase()),
            )
            .map((l) => (
              <Item key={l.id} secondaryAction={<TextBtn>Install</TextBtn>}>
                <IconWrap>
                  <Icon src="/" alt={l.name} />
                </IconWrap>
                <ListItemText sx={{ my: 0 }} primary={l.name} />
              </Item>
            ))}
        </List>
      </DialogContent>
      <Box sx={{ height: '24px' }} />
    </Dialog>
  );
}

export default ManageApps;
