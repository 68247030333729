import { Box, styled, Stack, Typography, IconButton } from '@mui/material';

export const Root = styled(Stack)(({ theme }) => ({
  boxSizing: 'border-box',
  height: 'calc(100% - 112px)',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 140px)',
  },
}));

export const PanelOuter = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: `0 ${theme.spacing(3)}`,
  paddingBottom: theme.spacing(3),
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
  '& .panel': {
    height: '100%',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(2)}`,
  },
}));

export const ProjectsWrap = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.down('sm')]: {
    border: 0,
    borderRadius: 0,
  },
}));

export const Sidebar = styled(Stack)(({ theme }) => ({
  flexGrow: 0,
  maxWidth: '400px',
  minWidth: '280px',
  flexBasis: '25%',
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  padding: '4px 2px 4px 0',
  backgroundColor: theme.palette.background.paper,
  maxHeight: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    flexBasis: '100%',
    // maxHeight: 'calc(100% - 40px)',
    marginRight: 0,
    marginTop: 0,
    paddingTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    border: 0,
  },
}));

export const SideContent = styled(Stack)(({ theme }) => ({
  overflow: 'hidden',
}));

export const PanelWrap = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  maxWidth: '100%',
  flexBasis: '100%',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    flexBasis: '100%',
  },
  '&.fullWidth': {
    '& .fullscreen': {
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .detail': {
      flexGrow: 1,
      height: 'auto',
      borderRadius: 0,
    },
  },
  '& .fullscreen': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  '& .detail': {
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      border: 0,
      borderRadius: 0,
    },
  },
}));

export const ContentWrap = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  '& .content-wrap': {
    position: 'relative',
  },
  [theme.breakpoints.down('md')]: {
    '& .content-wrap': {
      height: 'calc(100% - 10px)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    border: 0,
    borderRadius: 0,
    paddingTop: '10px',
  },
}));

export const SidebarEditor = styled(Box)(({ theme }) => ({
  flex: 1,
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  width: '100%',
  boxSizing: 'border-box',
  marginLeft: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '3px',
  '& + .handle div': {
    right: '-32px !important',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: '40%',
  width: '100%',
  padding: theme.spacing(1),
  overflow: 'auto',
  marginLeft: '35px',
  marginRight: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '3px',
}));

export const FullscreenBtn = styled(IconButton)(({ theme }) => ({
  height: '44px',
  width: '44px',
  marginRight: 0,
  marginLeft: theme.spacing(2),
  color: theme.palette.mode === 'light' ? theme.palette.secondary.main : '#fff',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  '&.diagram': {
    backgroundColor: '#fff',
    borderColor: '#EBF4F9',
    color: theme.palette.secondary.main,
  },
  '&.active': {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
}));
