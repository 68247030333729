import { createSlice } from '@reduxjs/toolkit';

const initialState = JSON.parse(localStorage.getItem('isDemo') || 'false');

const isDemoSlice = createSlice({
  name: 'isDemo',
  initialState,
  reducers: {
    setIsDemo: (_state, { payload }) => {
      localStorage.setItem('isDemo', JSON.stringify(payload));
      return payload;
    },
  },
});

const { actions, reducer } = isDemoSlice;
export const { setIsDemo } = actions;
export default reducer;
