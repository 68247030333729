import { createSlice } from '@reduxjs/toolkit';
import { IEditorState } from '../../../typescript/interfaces/editor.interface';

const initialState: IEditorState = {
  visual: {
    json: {},
    errors: [],
    action: undefined,
    oldText: '',
    asset: undefined,
    fileName: undefined,
    isLoading: false,
    isFocusEditor: false,
  },
  template: {
    json: {},
    errors: [],
    action: undefined,
    oldText: '',
    asset: undefined,
    fileName: undefined,
    isLoading: false,
    isFocusEditor: false,
  },
};

const editorSlice = createSlice({
  name: 'editorSlice',
  initialState,
  reducers: {
    setVisualJson: (state, { payload }) => ({
      ...state,
      visual: { ...state.visual, json: payload },
    }),
    setVisualAction: (state, { payload }) => ({
      ...state,
      visual: { ...state.visual, action: payload },
    }),
    setVisualErrors: (state, { payload }) => ({
      ...state,
      visual: { ...state.visual, errors: payload },
    }),
    setVisualOldText: (state, { payload }) => ({
      ...state,
      visual: { ...state.visual, oldText: payload },
    }),
    setVisualAsset: (state, { payload }) => ({
      ...state,
      visual: { ...state.visual, asset: payload },
    }),
    setVisualFileName: (state, { payload }) => ({
      ...state,
      visual: { ...state.visual, fileName: payload },
    }),
    setIsVisualLoading: (state, { payload }) => ({
      ...state,
      visual: { ...state.visual, isLoading: payload },
    }),
    dropVisualAction: (state) => ({
      ...state,
      visual: { ...state.visual, action: undefined },
    }),
    setVisualIsFocusEditor: (state, { payload }) => ({
      ...state,
      visual: { ...state.visual, isFocusEditor: payload },
    }),
    setTemplateVisuals: (state, { payload }) => ({
      ...state,
      template: { ...state.template, json: payload },
    }),
    setTemplateAction: (state, { payload }) => ({
      ...state,
      template: { ...state.template, action: payload },
    }),
    setTemplateErrors: (state, { payload }) => ({
      ...state,
      template: { ...state.template, errors: payload },
    }),
    setTemplateOldText: (state, { payload }) => ({
      ...state,
      template: { ...state.template, oldText: payload },
    }),
    setTemplateAsset: (state, { payload }) => ({
      ...state,
      template: { ...state.template, asset: payload },
    }),
    setTemplateFileName: (state, { payload }) => ({
      ...state,
      template: { ...state.template, fileName: payload },
    }),
    setTemplateIsLoading: (state, { payload }) => ({
      ...state,
      template: { ...state.template, isLoading: payload },
    }),
    dropTemplateAction: (state) => ({
      ...state,
      template: { ...state.template, action: undefined },
    }),
    setTemplateIsFocusEditor: (state, { payload }) => ({
      ...state,
      template: { ...state.template, isFocusEditor: payload },
    }),
  },
});

const { actions, reducer } = editorSlice;
export const {
  setVisualJson,
  setVisualAction,
  setVisualErrors,
  setVisualOldText,
  setVisualAsset,
  setVisualFileName,
  setIsVisualLoading,
  setVisualIsFocusEditor,
  dropVisualAction,
  setTemplateVisuals,
  setTemplateAction,
  setTemplateAsset,
  setTemplateErrors,
  setTemplateFileName,
  setTemplateOldText,
  setTemplateIsLoading,
  setTemplateIsFocusEditor,
  dropTemplateAction,
} = actions;
export default reducer;
