import {
  CreateIdentityRequest,
  CreateIdentityResponse,
  UpdateUserStateRequest,
  FormProps,
  PatchIdentityRequest,
  RecoveryLinkResponse,
  UpdateCurrentUserRequest,
  V010Identity,
} from './types';
import { atreusApiUrl } from '../../../env';
import { rootApi } from '..';

const BASE_URL = `${atreusApiUrl()}/identities`;

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCurrentUser: builder.mutation<void, FormProps>({
      query: (props) => {
        const body: UpdateCurrentUserRequest = {};
        if (props?.email) body.email = props.email;
        if (props?.firstName) body.first_name = props.firstName;
        if (props?.lastName) body.last_name = props.lastName;
        if (props?.password) body.password = props.password;

        return {
          url: BASE_URL,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['CurrentUser'],
    }),
    getUsers: builder.query<V010Identity[], void>({
      query: () => BASE_URL,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Users' as const, id })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    createUser: builder.mutation<CreateIdentityResponse, CreateIdentityRequest>(
      {
        query: (body) => ({
          url: BASE_URL,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Users'],
      },
    ),
    deleteUser: builder.mutation<CreateIdentityResponse, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    editUser: builder.mutation<CreateIdentityResponse, PatchIdentityRequest>({
      query: ({ id, body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    editUserState: builder.mutation<void, UpdateUserStateRequest>({
      query: ({ id, state }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body: { state },
      }),
      invalidatesTags: ['Users'],
    }),
    createRecoveryLink: builder.mutation<RecoveryLinkResponse, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}/recovery`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useUpdateCurrentUserMutation,
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useEditUserStateMutation,
  useCreateRecoveryLinkMutation,
} = extendedApi;
