import React from 'react';
import { IconButton } from '@mui/material';
import { MoreVertRounded } from '@mui/icons-material';

const MoreButtonComponent = ({ menuOpen, handleClick }: any) => (
  <IconButton
    sx={{ ml: 'auto' }}
    id="action-button"
    aria-controls={menuOpen ? 'action-menu' : undefined}
    aria-haspopup="true"
    aria-expanded={menuOpen ? 'true' : undefined}
    onClick={handleClick}
    className="outlined"
  >
    <MoreVertRounded />
  </IconButton>
);

export default MoreButtonComponent;
