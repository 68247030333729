import { OidcConfiguration, TokenRenewMode } from '@axa-fr/react-oidc';
import { oauth2ClientId, ssoUrl } from '../env';
import { errorOIDCStorage } from '../common/OIDCComponents/AuthenticatingErrorComponent';
import { setCookies, setTokensUtil } from './helper';

export const logoutStorage = 'logout';

export enum LogoutState {
  true = 'true',
  false = 'false',
  done = 'done',
}

const SSO_URL = ssoUrl();

export const axaOidcConfig: OidcConfiguration = {
  client_id: oauth2ClientId(),
  redirect_uri: `${window.location.origin}/callback`,
  silent_redirect_uri: `${window.location.origin}/callback-silent`,
  scope: 'openid offline',
  authority: SSO_URL,
  refresh_time_before_tokens_expiration_in_second: 5,
  storage: localStorage,
  extras: {
    max_age: '0',
    nonce: 'xifqvbbhvaqmjzzqjdmqtdll',
    state: 'sxtjwhfxdyeqhpskdolfihuf',
  },
  token_renew_mode: TokenRenewMode.access_token_invalid,
  demonstrating_proof_of_possession: false,
  service_worker_only: false,
  monitor_session: true,
};

export const onEvent = (_configurationName, eventName, data) => {
  // cleanout on open as onLogoutFromSameTab doesn't fires
  if (eventName === 'loginCallbackAsync_error' && data?.message) {
    sessionStorage.setItem(errorOIDCStorage, data);
  }

  if (data?.accessToken || data?.idToken) {
    setTokensUtil(data);
  }
};

export const onLogoutFromSameTab = () => {
  sessionStorage.setItem(logoutStorage, LogoutState.true);
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token_hint');
  setCookies({
    cookieName: 'access_token',
    values: {
      expiresAt: 0,
      token: undefined,
    },
  });
};
