import { JSONSchema4, JSONSchema6, JSONSchema7 } from 'json-schema';
import { apiBaseUrlV1 } from '../../../env';
import { rootApi } from '..';

const BASE_URL = `${apiBaseUrlV1('structure/v1')}/documentSchemas`;

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    systemDefinition: builder.query<
      JSONSchema4 | JSONSchema6 | JSONSchema7,
      void
    >({
      query: () => `${BASE_URL}/SYSTEM_DEFINITION`,
    }),
    visualsDefinition: builder.query<
      JSONSchema4 | JSONSchema6 | JSONSchema7,
      void
    >({
      query: () => `${BASE_URL}/VISUAL_DEFINITION`,
    }),
  }),
});

export const { useSystemDefinitionQuery, useVisualsDefinitionQuery } =
  extendedApi;
