import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ActiveSitesProps = {
  loaded: boolean;
  activeSites: string[];
};
const sitesSlice = createSlice({
  name: 'sites',
  initialState: { loaded: false, activeSites: [] } as ActiveSitesProps,
  reducers: {
    setActiveSites(_state, action: PayloadAction<ActiveSitesProps>) {
      return action.payload;
    },
  },
});

const { actions, reducer } = sitesSlice;
export const { setActiveSites } = actions;
export default reducer;
