export const getQueryVariable = (variable: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  // eslint-disable-next-line no-restricted-syntax
  for (const iterator of vars) {
    const pair = iterator.split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

export const tokenParserStorage = ({
  storage,
  entityName,
  searchValue,
}: {
  storage: Storage;
  entityName: string;
  searchValue?: string;
}) => {
  if (!storage.getItem(entityName)) return undefined;
  if (storage.getItem(entityName) && !searchValue)
    return storage.getItem(entityName);
  if (!JSON.parse(storage.getItem(entityName))) return undefined;
  if (!JSON.parse(storage.getItem(entityName))[searchValue]) {
    return undefined;
  }
  return JSON.parse(storage.getItem(entityName))[searchValue];
};
