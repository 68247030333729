import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';

const BASE_URL = `${apiBaseUrlV1('control/v1/ioMaps')}`;

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    createIOMapsByTemplate: builder.mutation<
      any,
      { templateName: string; systemId: string; ioPairs: any }
    >({
      query: ({ templateName, systemId, ioPairs }) => ({
        url: `${BASE_URL}/createByTemplate`,
        method: 'POST',
        body: { templateName, systemId, ioPairs },
      }),
      invalidatesTags: ['ioMaps'],
    }),
    createIOMapsByYaml: builder.mutation<any, { systemId: string; file: File }>(
      {
        query: ({ systemId, file }) => {
          const formData = new FormData();
          formData.append('file', file);

          return {
            url: `${BASE_URL}/createByYaml/${systemId}`,
            method: 'POST',
            body: formData,
          };
        },
        invalidatesTags: ['ioMaps'],
      },
    ),
    getSystemIOMaps: builder.query<any, string>({
      query: (id) => ({
        url: `${BASE_URL}/system/${id}`,
      }),
      providesTags: ['ioMaps'],
    }),
    getIOMapDetails: builder.query<any, string>({
      query: (id) => ({ url: `${BASE_URL}/${id}` }),
      providesTags: ['ioMap'],
    }),
    patchIOMapDetails: builder.mutation<any, { ioMapId: string; data: any }>({
      query: ({ ioMapId, data }) => ({
        url: `${BASE_URL}/${ioMapId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['ioMap'],
    }),
  }),
});

export const {
  useCreateIOMapsByYamlMutation,
  useCreateIOMapsByTemplateMutation,
  useGetSystemIOMapsQuery,
  useGetIOMapDetailsQuery,
  usePatchIOMapDetailsMutation,
} = extendedApi;
