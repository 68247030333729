import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AlertHighIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        id="Path_168"
        data-name="Path 168"
        d="M7.251,1.482a2.231,2.231,0,0,1,3.155,0l5.769,5.769a2.231,2.231,0,0,1,0,3.155l-5.769,5.769a2.231,2.231,0,0,1-3.155,0L1.482,10.406a2.231,2.231,0,0,1,0-3.155Z"
        transform="translate(3.172 3.172)"
        fill="#fff"
      />
      <path
        id="Background"
        d="M0,0H24V24H0Z"
        fill="rgba(255,255,255,0)"
        opacity="0"
      />
      <path
        id="Path_169"
        data-name="Path 169"
        d="M-12466-14444a2.214,2.214,0,0,1-1.576-.652l-5.771-5.767a2.237,2.237,0,0,1,0-3.157l5.771-5.771a2.219,2.219,0,0,1,1.577-.653,2.218,2.218,0,0,1,1.576.653l5.771,5.771a2.239,2.239,0,0,1,0,3.157l-5.771,5.767A2.216,2.216,0,0,1-12466-14444Zm.021-5.664a.827.827,0,0,0-.82.831.828.828,0,0,0,.82.834.828.828,0,0,0,.82-.834A.827.827,0,0,0-12465.979-14449.665Zm0-6.337a.664.664,0,0,0-.657.668v4a.661.661,0,0,0,.657.664.661.661,0,0,0,.656-.664v-4A.663.663,0,0,0-12465.979-14456Z"
        transform="translate(12478 14464)"
        fill="#f59e3b"
      />
    </SvgIcon>
  );
}

export default AlertHighIcon;
