import { createActions } from 'redux-actions';
import { identity } from '../../utils';

export const {
  setEvent,
  dropEvent,
  setDiagramEvent,
  dropDiagramEvents,
  dropDiagramEvent,
  setSwState,
  dropSwState,
} = createActions({
  SET_EVENT: identity,
  DROP_EVENT: identity,
  SET_DIAGRAM_EVENT: identity,
  DROP_DIAGRAM_EVENTS: identity,
  DROP_DIAGRAM_EVENT: identity,
  SET_SW_STATE: identity,
  DROP_SW_STATE: identity,
});
