import React, { useState } from 'react';
import { Link, NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Box, Button, Avatar, IconButton, styled, alpha } from '@mui/material';
import {
  DashboardCustomizeRounded,
  ExpandMoreRounded,
  HomeRounded,
  MenuRounded,
} from '@mui/icons-material';
import { SystemIcon, ProjectIcon } from '../../icons';
import UserDropdown from './UserDropdown';
import MobileDrawer from './MobileDrawer';
import { ROUTES_PATH } from '../../constants';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import {
  envLogoSrc,
  useGetMetadataQuery,
} from '../../redux/services/environment/api';
import MoreDropdown from './MoreDropdown';
import { useAppSelector } from '../../redux/store';
import { useGetCurrentUserQuery } from '../../redux/services/atreus/api';
import useOidcCloud from '../../hooks/useOidcCloud';

const Root = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
  height: '60px',
  padding: `0 ${theme.spacing(3)}`,
  position: 'sticky',
  zIndex: 999,
  top: 0,
  left: 0,
  right: 0,
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(2)}`,
  },
}));

const ToggleMenu = styled(IconButton)(({ theme }) => ({
  marginLeft: '-0.75rem',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '-0.5rem',
  },
}));

const LogoWrap = styled(Link)({
  height: '36px',
});

const SecondaryLogo = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRight: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
}));

const RightAction = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const UserAction = styled(Box)({
  display: 'flex',
  lineHeight: 1,
  padding: 0,
});

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

const NavItem = styled(Button)<NavLinkProps>(({ theme }) => ({
  minHeight: '2.5rem',
  color: theme.palette.text.secondary,
  backgroundColor: 'transparent',
  padding: '6px 12px',
  fontWeight: 600,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid transparent',
  fontFamily: 'Source Sans Pro',
  fontSize: '1.125rem',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? '#1A2941'
        : alpha(theme.palette.primary.main, 0.04),
  },
  '&.active': {
    color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main,
    borderColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? '#1A2941'
        : alpha(theme.palette.primary.main, 0.08),
  },
  '& svg': {
    color: 'currentColor',
  },
}));
interface Props {
  isDark: boolean;
  toggleTheme: any;
}

export const MoreButton = ({
  component,
  onClick,
  startIcon,
  endIcon,
  text,
  className,
}: {
  component: React.FunctionComponent;
  onClick: (event: React.MouseEvent) => void;
  startIcon: React.JSX.Element;
  endIcon: React.JSX.Element;
  text: string;
  className: string;
}): JSX.Element => (
  // @ts-ignore
  <NavItem
    component={component}
    startIcon={startIcon}
    endIcon={endIcon}
    onClick={onClick}
    className={className}
  >
    {text}
  </NavItem>
);

const Header = (props: Props): JSX.Element => {
  const { isDark, toggleTheme } = props;
  const location = useLocation();
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  const { accessToken } = useOidcCloud();
  const { data: user } = useGetCurrentUserQuery(accessToken, {
    skip: !accessToken && !isEdgeEnv,
  });
  const { data: envMetadata } = useGetMetadataQuery(undefined, {
    skip: isEdgeEnv,
  });

  if (!user?.traits) return null;

  const { email, name } = user?.traits;
  const role = user?.role_name;

  const handleUserOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setUserAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMoreOpen = (event: React.MouseEvent) => {
    setMoreAnchorEl(event.currentTarget as HTMLElement);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  return (
    <>
      {[
        ROUTES_PATH.MAIN,
        ROUTES_PATH.LOGIN,
        ROUTES_PATH.RESET_PASSWORD,
        ROUTES_PATH.CHANGE_PASSWORD,
      ].includes(location.pathname) ? (
        ''
      ) : (
        <Root>
          <Box display="flex" alignItems="center">
            <ToggleMenu
              sx={{ display: { xs: 'inline-flex', md: 'none' } }}
              onClick={handleDrawerOpen}
              id="Header-Toggle-mobile_menu"
            >
              <MenuRounded />
            </ToggleMenu>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LogoWrap to={ROUTES_PATH.DASHBOARD}>
                <img
                  src={envLogoSrc({ isDark })}
                  alt={`${envMetadata?.environmentName || 'environment'} logo`}
                  width="116"
                  height="36"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = '/images/procaaso.svg';
                  }}
                />
              </LogoWrap>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
            <NavItem
              component={NavLink}
              to={ROUTES_PATH.DASHBOARD}
              startIcon={<HomeRounded />}
            >
              Home
            </NavItem>
            <NavItem
              component={NavLink}
              to={`${ROUTES_PATH.SYSTEMS}/list`}
              startIcon={<SystemIcon />}
              className={cn(
                location.pathname.includes(`${ROUTES_PATH.SYSTEMS}/map`) &&
                  'active',
                location.pathname.includes(ROUTES_PATH.SYSTEM_DETAIL) &&
                  'active',
              )}
            >
              Systems
            </NavItem>
            <NavItem
              component={NavLink}
              to={`${ROUTES_PATH.PROJECTS}/ens`}
              startIcon={<ProjectIcon />}
              className={cn(
                location.pathname.includes(`${ROUTES_PATH.PROJECT_DETAIL}/`) &&
                  'active',
                location.pathname.includes(`${ROUTES_PATH.CONFIG}/`) &&
                  'active',
                location.pathname.includes(ROUTES_PATH.CREATE_CONFIG) &&
                  'active',
              )}
            >
              Projects
            </NavItem>
            {!isEdgeEnv && (
              <MoreButton
                component={Button}
                startIcon={<DashboardCustomizeRounded />}
                endIcon={
                  <ExpandMoreRounded
                    sx={{ transform: moreAnchorEl ? 'rotate(180deg)' : '' }}
                  />
                }
                text="More"
                onClick={handleMoreOpen}
                className={
                  moreAnchorEl || location.pathname.includes(ROUTES_PATH.MORE)
                    ? 'active'
                    : ''
                }
              />
            )}
          </Box>
          <RightAction>
            <SecondaryLogo>
              <img
                src="/images/procaaso-icon.svg"
                alt="ProCaaSo"
                width="28"
                height="28"
              />
            </SecondaryLogo>
            <UserAction>
              <IconButton
                sx={{
                  width: '2.75rem',
                  height: '2.75rem',
                  borderRadius: '50%',
                }}
                onClick={handleUserOpen}
                id="Header-user-avatar"
              >
                <UserAvatar>
                  {(`${name.first} ${name.last}` || email).charAt(0)}
                </UserAvatar>
              </IconButton>
            </UserAction>
            <UserDropdown
              anchorEl={userAnchorEl}
              handleClose={handleUserClose}
              name={`${name.first} ${name.last}` || email}
              role={role}
              isDark={isDark}
              toggleTheme={toggleTheme}
            />
            {!isEdgeEnv && (
              <MoreDropdown
                anchorEl={moreAnchorEl}
                handleClose={handleMoreClose}
              />
            )}
          </RightAction>
          <MobileDrawer
            isOpen={drawerOpen}
            isDark={isDark}
            onClose={handleDrawerClose}
          />
        </Root>
      )}
    </>
  );
};

export default Header;
