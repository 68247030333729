import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ListIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <path d="M6 35V13q0-1.25.875-2.125T9 10h30q1.25 0 2.125.875T42 13v22q0 1.25-.875 2.125T39 38H9q-1.25 0-2.125-.875T6 35Zm3-16.65h5.3V13H9v5.35Zm8.3 0H39V13H17.3v5.35Zm0 8.3H39v-5.3H17.3v5.3Zm0 8.35H39v-5.35H17.3V35ZM9 35h5.3v-5.35H9V35Zm0-8.35h5.3v-5.3H9v5.3Z" />
    </SvgIcon>
  );
}

export default ListIcon;
