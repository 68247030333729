import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function DriveIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M146.783-267.913h666.434v-263.043H146.783v263.043Zm535.03-81.522q20.883 0 35.383-14.335 14.5-14.335 14.5-35.782 0-20.883-14.618-35.383-14.618-14.5-35.5-14.5-20.882 0-35.382 14.618-14.5 14.618-14.5 35.5 0 21.447 14.617 35.665 14.618 14.217 35.5 14.217Zm211.188-256.783H780.304l-88.13-88.13H267.826l-88.13 88.13H67.565L211.26-749.913q10.869-10.876 25.448-17.264 14.579-6.389 31.118-6.389h424.348q16.263 0 31.001 6.413t26.13 17.24l143.696 143.695ZM146.783-188.13q-33.257 0-56.238-23.147-22.98-23.146-22.98-56.636v-338.305H893v338.305q0 33.49-23.147 56.636-23.147 23.147-56.637 23.147H146.783Z" />
    </SvgIcon>
  );
}

export default DriveIcon;
