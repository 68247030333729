import {
  BufferConcentrateV1,
  BufferConcentrate,
  BufferX,
  Pixer,
  PixerV1,
  Water,
  WaterV1,
  Valve,
  EdgeDot,
  ControlValve,
  SolenoidValve,
  PoweredValve,
  HydraulicValve,
  FlowMeterSensor,
  MotorOperatedValve,
  Motor,
  ConductivitySensor,
  PhSensor,
  TextInputField,
  Alphinity,
  ProCaaSo,
  PointComponent,
  DialogCustomNode,
} from './library';
import Bar from './library/flairs/Bar';
import BooleanIndicator from './library/flairs/BooleanIndicator';
import Label from './library/flairs/Label';
import PortAnchor from './library/flairs/PortAnchor';
import DiagramComponent from './library/DiagramComponent';
import VisualViewController from './library/flairs/VisualViewController';

export const nodesTypes = {
  pixer: Pixer,
  pixerV1: PixerV1,
  water: Water,
  waterV1: WaterV1,
  bufferX: BufferX,
  edgeDot: EdgeDot,
  bufferConcentrate: BufferConcentrate,
  bufferConcetrate: BufferConcentrate,
  bufferConcentrateV1: BufferConcentrateV1,
  controlValve: ControlValve,
  solenoidValve: SolenoidValve,
  pointComponent: PointComponent,
  poweredValve: PoweredValve,
  hydraulicValve: HydraulicValve,
  flowMeterSensor: FlowMeterSensor,
  motorOperatedValve: MotorOperatedValve,
  valve: Valve,
  motor: Motor,
  conductivitySensor: ConductivitySensor,
  phSensor: PhSensor,
  textInputField: TextInputField,
  paracloud: ProCaaSo,
  secondary_logo: Alphinity,
  dialogCustom: DialogCustomNode,
  diagramComponent: DiagramComponent,
  pixerLocalType: DiagramComponent,
  bufferConcentrateLocalType: DiagramComponent,
  waterLocalType: DiagramComponent,
  bar: Bar,
  booleanIndicator: BooleanIndicator,
  label: Label,
  portAnchor: PortAnchor,
  visualViewController: VisualViewController,
};
