import React, { FC, useState } from 'react';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import lightTheme from '../lightTheme';
import darkTheme from '../darkTheme';
import { AppRouterProps } from '../App';

interface Props {
  children: FC<AppRouterProps>;
}
const ThemeProvider = ({ children }: Props) => {
  const themeIsDark = Boolean(JSON.parse(localStorage.getItem('darkMode')));
  const [darkMode, setDarkMode] = useState(themeIsDark);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
    localStorage.setItem('darkMode', `${!themeIsDark}`);
  };
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        {children({ darkMode, toggleTheme })}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
