import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import {
  StatusWrap,
  SystemInfo,
  SystemListItemStyled,
  Title,
} from '../../../common/styles/system';
import SystemStatusIconSubscription from './SystemStatusIconSubscription';
import Loader from '../../../common/Loader';
import SystemMachineStatusTextSubscription from './SystemMachineStatusTextSubscription';
import SystemControlStatusTextSubscription from './SystemControlStatusTextSubscription';
import { useAppSelector } from '../../../redux/store';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';

interface ISystemItem {
  systemId: string;
  systemName: string;
  systemLock: boolean;
  style: React.CSSProperties;
  onClickAction: (id: string) => void;
  selectedItem?: string;
}

const SystemItem = (props: ISystemItem): JSX.Element => {
  const {
    systemId,
    systemLock,
    systemName,
    style,
    onClickAction,
    selectedItem,
  } = props;

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  if (!systemId) return <Loader />;

  return (
    <SystemListItemStyled
      id={`SystemList-Item_${systemId}`}
      sx={{
        ...style,
        display: 'flex',
        height: '70px',
        width: 'calc(100% - 8px)',
      }}
      onClick={() => onClickAction(systemId)}
      selected={systemId === selectedItem}
    >
      <SystemInfo
        primaryTypographyProps={{ id: `SystemList-ItemName_${systemId}` }}
        primary={
          <Title>
            {systemLock && (
              <Tooltip title="System locked">
                <LockOutlined />
              </Tooltip>
            )}{' '}
            <span>{systemName}</span>
          </Title>
        }
        secondary={
          <>
            {!isEdgeEnv && (
              <Tooltip title="Machine status">
                <StatusWrap>
                  <SystemMachineStatusTextSubscription systemID={systemId} />
                </StatusWrap>
              </Tooltip>
            )}
            <Box component="span" width="8px" />
            <Tooltip title="Control status">
              <StatusWrap>
                <SystemControlStatusTextSubscription systemID={systemId} />
              </StatusWrap>
            </Tooltip>
          </>
        }
        sx={{
          '& .MuiListItemText-secondary': {
            overflow: 'hidden',
          },
        }}
      />
      <SystemStatusIconSubscription
        id={systemId}
        pollingInterval={0}
        block={false}
        style={undefined}
      />
    </SystemListItemStyled>
  );
};

SystemItem.defaultProps = {
  selectedItem: undefined,
};

export default SystemItem;
