import React, { useState } from 'react';
import { editor } from 'monaco-editor';
import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  styled,
  alpha,
} from '@mui/material';
import { monacoSeverity } from './ProblemsBlock';
import ProblemsCount from './ProblemsCount';

const MessageWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.primary.main, 0.07)
        : alpha(theme.palette.text.primary, 0.15),
  },
}));

interface ProblemSeverity {
  problems: editor.IMarker[];
  id: number;
  severity: string | monacoSeverity;
}

interface ProblemMessagePosition {
  startLineNumber: number;
  startColumn: number;
}

const ProblemMessage = ({
  problem,
  onClick,
}: {
  problem: editor.IMarker;
  onClick: ({ startLineNumber, startColumn }: ProblemMessagePosition) => void;
}) => {
  const { message, startLineNumber, startColumn } = problem;
  return (
    <MessageWrapper
      onClick={(ev) => {
        ev.stopPropagation();
        onClick({ startLineNumber, startColumn });
      }}
    >
      {message} {`[Ln ${startLineNumber}, Col ${startColumn}]`}
    </MessageWrapper>
  );
};

const ProblemSeverityBlock = ({
  problemSeverity,
  onClick,
}: {
  problemSeverity: ProblemSeverity;
  onClick: ({ startLineNumber, startColumn }: ProblemMessagePosition) => void;
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (ev: React.BaseSyntheticEvent) => {
    ev.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <Accordion onClick={(ev) => handleChange(ev)} expanded={expanded}>
      <AccordionSummary
        expandIcon={<span />}
        aria-controls={`${problemSeverity.severity}-content`}
        id={`${problemSeverity.severity}-header`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: 'text.primary' }}>
            {problemSeverity.severity}
          </Typography>
          <ProblemsCount
            className={problemSeverity.severity.toString().toLowerCase()}
            problemsCount={problemSeverity.problems.length}
          />
          <ExpandMoreRounded
            sx={{
              transform: expanded ? 'rotate(180deg)' : 'none',
            }}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {problemSeverity.problems.map((problem: editor.IMarker) => (
          <ProblemMessage
            key={`${problem.startColumn}${problem.message}`}
            problem={problem}
            onClick={onClick}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const ProblemsTable = ({
  problemsList,
  onClick,
}: {
  problemsList: editor.IMarker[];
  onClick: ({ startLineNumber, startColumn }: ProblemMessagePosition) => void;
}) => {
  const problemsBySeverity = Object.entries(monacoSeverity)
    .filter(([id]) => !Number.isNaN(Number(id)))
    .map(([id, severity]) => ({ id: +id, severity }))
    .map((problem) => ({
      ...problem,
      problems: problemsList.filter(({ severity }) => severity === +problem.id),
    }))
    .filter(({ problems }) => problems.length > 0);

  return (
    <Box sx={{ overflow: 'auto' }}>
      {problemsBySeverity.map((problemSeverity) => (
        <ProblemSeverityBlock
          key={problemSeverity.severity}
          problemSeverity={problemSeverity}
          onClick={onClick}
        />
      ))}
    </Box>
  );
};

export default ProblemsTable;
