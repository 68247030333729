import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function TreeIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <mask
        id="mask0_2070_15737"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2070_15737)">
        <path d="M14.1666 17.5C13.7083 17.5 13.3159 17.3368 12.9895 17.0104C12.6632 16.684 12.5 16.2917 12.5 15.8333V15H10.8333C10.375 15 9.9826 14.8368 9.65621 14.5104C9.32982 14.184 9.16663 13.7917 9.16663 13.3333V6.66667H7.49996V7.5C7.49996 7.95833 7.33676 8.35069 7.01038 8.67708C6.68399 9.00347 6.29163 9.16667 5.83329 9.16667H3.33329C2.87496 9.16667 2.4826 9.00347 2.15621 8.67708C1.82982 8.35069 1.66663 7.95833 1.66663 7.5V4.16667C1.66663 3.70833 1.82982 3.31597 2.15621 2.98958C2.4826 2.66319 2.87496 2.5 3.33329 2.5H5.83329C6.29163 2.5 6.68399 2.66319 7.01038 2.98958C7.33676 3.31597 7.49996 3.70833 7.49996 4.16667V5H12.5V4.16667C12.5 3.70833 12.6632 3.31597 12.9895 2.98958C13.3159 2.66319 13.7083 2.5 14.1666 2.5H16.6666C17.125 2.5 17.5173 2.66319 17.8437 2.98958C18.1701 3.31597 18.3333 3.70833 18.3333 4.16667V7.5C18.3333 7.95833 18.1701 8.35069 17.8437 8.67708C17.5173 9.00347 17.125 9.16667 16.6666 9.16667H14.1666C13.7083 9.16667 13.3159 9.00347 12.9895 8.67708C12.6632 8.35069 12.5 7.95833 12.5 7.5V6.66667H10.8333V13.3333H12.5V12.5C12.5 12.0417 12.6632 11.6493 12.9895 11.3229C13.3159 10.9965 13.7083 10.8333 14.1666 10.8333H16.6666C17.125 10.8333 17.5173 10.9965 17.8437 11.3229C18.1701 11.6493 18.3333 12.0417 18.3333 12.5V15.8333C18.3333 16.2917 18.1701 16.684 17.8437 17.0104C17.5173 17.3368 17.125 17.5 16.6666 17.5H14.1666ZM14.1666 7.5H16.6666V4.16667H14.1666V7.5ZM14.1666 15.8333H16.6666V12.5H14.1666V15.8333ZM3.33329 7.5H5.83329V4.16667H3.33329V7.5Z" />
      </g>
    </SvgIcon>
  );
}

export default TreeIcon;
