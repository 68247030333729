import { Box, Stack, styled } from '@mui/material';

export const Root = styled(Stack)(({ theme }) => ({
  height: 'calc(100% - 60px)',
  '&.loaded': {
    minHeight: '280px',
  },
  '& .search': {
    maxWidth: '480px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
}));

export const ContentWrap = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  padding: `0 ${theme.spacing(3)}`,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    border: 0,
    borderRadius: 0,
    padding: '0',
    marginBottom: 0,
  },
}));

export const HeadWrap = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingBottom: 0,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));
