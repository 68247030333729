import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ConnectedHMIStorage } from '../common/Header/Dropdown/EnvModeButton';

export const withUrlParse =
  <T,>(WrappedComponent: React.ComponentType<T>) =>
  // eslint-disable-next-line react/display-name
  (props: T) => {
    const { search } = useLocation();
    const history = useHistory();

    useEffect(() => {
      const searchParams = new URLSearchParams(search);
      if (!searchParams.has(ConnectedHMIStorage)) return;
      const param = searchParams.get(ConnectedHMIStorage);
      searchParams.delete(ConnectedHMIStorage);
      history.replace({
        search: searchParams.toString(),
      });
      localStorage.setItem(ConnectedHMIStorage, param);
    }, []);

    return <WrappedComponent {...props} />;
  };

export default withUrlParse;
