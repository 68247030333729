import React, { useMemo } from 'react';
import BaseFlair from './BaseFlair';

interface Props {
  className?: string;
  data?: any;
  selected?: boolean;
}

const defaultMinWidth = 42;
const defaultMinHeight = 25;

function Label(props: Props) {
  const { className, data, selected } = props;

  const textSize = useMemo(() => {
    if (data?.text_size === 'XS') return '0.3rem';
    if (data?.text_size === 'S') return '0.67rem';
    if (data?.text_size === 'M') return '1.25rem';
    if (data?.text_size === 'L') return '1.75rem';
    if (data?.text_size === 'XL') return '2.5rem';
    return '1.25rem';
  }, [data?.text_size]);

  return (
    <BaseFlair
      className={className}
      data={data}
      selected={selected}
      defaultMinWidth={defaultMinWidth}
      defaultMinHeight={defaultMinHeight}
      content={
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            backgroundColor: data?.background_color || '#702F8A',
            padding: '0 2px',
            fontSize: textSize,
            borderRadius: '2px',
            lineHeight: `calc(${textSize} + 0.25rem)`,
            textAnchor: 'middle',
            dominantBaseline: 'middle',
            color: data?.text_color || 'white',
          }}
        >
          <span>
            {data?.prefix || ''}
            {data?.label || data?.default || 'Label'}
            {data?.suffix || ''}
          </span>
        </span>
      }
    />
  );
}

Label.defaultProps = {
  className: '',
  data: {},
  selected: false,
};

export default Label;
