import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { DiagnosticsOptions, setDiagnosticsOptions } from 'monaco-yaml';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import { yamlSchema } from '../components/project/Editor/schema';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import {
  dropTemplateAction,
  dropVisualAction,
  setVisualErrors,
} from '../redux/modules/editor/slice';
import { useVisualsDefinitionQuery } from '../redux/services/documentSchemas/api';
import {
  generateSchema,
  visualsSchema,
} from '../common/Diagram/CreateProjectDiagram/schema';
import { lineSelect, nodeSelect, lineHighlighted } from '../utils/editorPureFn';
import { ActionsType } from '../typescript/interfaces/editor.interface';

interface YAMLEditor {
  yamlInstance: editor.ICodeEditor;
  setValidationMarkers: React.Dispatch<React.SetStateAction<editor.IMarker[]>>;
}

type UpdateDiagnosticOptionsFn = (val: DiagnosticsOptions) => void;

const updateDiagnosticOptions: UpdateDiagnosticOptionsFn = ({
  validate = true,
  enableSchemaRequest = false,
  format = true,
  hover = true,
  completion = true,
  schemas = [],
}) => {
  setDiagnosticsOptions({
    validate,
    enableSchemaRequest,
    format,
    hover,
    completion,
    schemas,
  });
};

const useYAMLEditor = ({
  isSetMarkers,
}: {
  isSetMarkers?: boolean;
}): YAMLEditor | undefined => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const dispatch = useAppDispatch();

  const visualAction = useAppSelector(
    (state: IAppState) => state.editor.visual.action,
  );
  const templateAction = useAppSelector(
    (state: IAppState) => state.editor.template.action,
  );

  const [yamlInstance, setYamlInstance] =
    useState<editor.ICodeEditor>(undefined);
  const [errorsLocal, setErrorsLocal] = useState<editor.IMarker[]>([]);
  const [validationMarkers, setValidationMarkers] = useState<editor.IMarker[]>(
    [],
  );

  const { data: validationSchema, isError: isValidationSchemaError } =
    useVisualsDefinitionQuery();

  useEffect(() => {
    updateDiagnosticOptions({
      schemas: yamlSchema,
    });
  }, []);

  useEffect(() => {
    if (!validationSchema) return;

    updateDiagnosticOptions({
      schemas: generateSchema(validationSchema),
    });
  }, [validationSchema]);

  useEffect(() => {
    if (!isValidationSchemaError) return;

    updateDiagnosticOptions({
      schemas: visualsSchema,
    });
  }, [isValidationSchemaError]);

  useEffect(() => {
    editor.onDidCreateEditor((newEditor) => {
      editor.onDidChangeMarkers((ev) => {
        const [{ path }] = ev;
        const errs = editor
          .getModelMarkers({})
          .filter(({ resource }) => resource.path === path);
        setErrorsLocal(errs);
      });

      setYamlInstance(newEditor);
    });
  }, []);

  useEffect(() => {
    if (!yamlInstance) return;
    if (!visualAction) return;
    if (visualAction.type === ActionsType.nodeSelected) {
      nodeSelect({ yamlInstance, action: visualAction });
      dispatch(dropVisualAction());
    }
    if (visualAction.type === ActionsType.lineSelected) {
      lineSelect({ yamlInstance, action: visualAction });
      dispatch(dropVisualAction());
    }
  }, [visualAction]);

  useEffect(() => {
    if (!yamlInstance) return;
    if (!templateAction) return;
    if (templateAction.type === ActionsType.nodeSelected) {
      nodeSelect({ yamlInstance, action: templateAction });
      dispatch(dropTemplateAction());
    }
    if (templateAction.type === ActionsType.lineSelected) {
      lineSelect({ yamlInstance, action: templateAction });
      dispatch(dropTemplateAction());
    }
    if (templateAction.type === ActionsType.lineHighlighted) {
      lineHighlighted({ yamlInstance, action: templateAction });
      dispatch(dropTemplateAction());
    }
  }, [templateAction]);

  useEffect(() => {
    if (yamlInstance) editor.setTheme(isDark ? 'vs-dark' : 'vs');
  }, [yamlInstance, theme]);

  useEffect(() => {
    if (!isSetMarkers) return;
    dispatch(setVisualErrors([...errorsLocal, ...validationMarkers]));
  }, [errorsLocal, validationMarkers]);

  return {
    yamlInstance: editor?.getEditors()?.[0] || undefined,
    setValidationMarkers,
  };
};

export default useYAMLEditor;
