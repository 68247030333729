import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ThresholdIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 -960 960 960" {...props}>
      <path d="M292.173-104.869q-38.564-.435-73.199-18.655-34.635-18.22-64.714-54.171-41.729-49.046-65.06-117.754Q65.87-364.158 65.87-437.732q0-86.451 32.448-162.626 32.448-76.175 88.442-132.437 55.994-56.262 131.689-89.082 75.695-32.819 161.835-32.819 86.249 0 161.722 32.913 75.474 32.913 131.437 89.991 55.964 57.077 88.609 133.873 32.645 76.795 32.645 164.494 0 78.398-25.695 148.499-25.696 70.1-72.468 118.01-30.62 30.917-63.857 46.482-33.238 15.565-68.031 15.565-19.232 0-37.797-4.782-18.565-4.783-37.841-14.348l-57.268-28.566q-13-6.434-26.128-9.717-13.128-3.283-26.087-3.283t-26.176 3.283q-13.218 3.283-26.451 9.795l-56.67 28.359Q351-113.999 331.763-109.217q-19.237 4.783-39.59 4.348Zm2.937-74.696q10.499 0 20.868-2.718 10.37-2.717 21.37-7.717l57.13-28.565q20.131-9.565 41.242-14.848 21.112-5.283 43.685-5.283 22.573 0 43.563 4.783 20.989 4.783 41.815 14.783L622.087-190q11 5 21.116 7.717 10.115 2.718 20.23 2.718 21.132 0 42.067-10.435t40.37-31.304q35.565-38 54.565-93.87 19-55.87 19-118.261 0-146-98.5-246T480-779.435q-142.435 0-240.935 100.718-98.5 100.717-98.5 247.282 0 63 19 117.5t54 92.5q20 21.435 39.774 31.652 19.774 10.218 41.771 10.218ZM480-480Zm0 119.609q33.253 0 56.431-23.178 23.178-23.178 23.178-56.431 0-7.812-1.5-15.623-1.5-7.812-4.935-14.812l56.087-70.13q10.304 12.87 17.891 26.975 7.587 14.106 12.022 30.373h76.696q-19.13-86.913-85.132-142.218Q564.737-680.74 480-680.74q-84.743 0-151.241 55.305-66.499 55.305-85.194 142.218h77.261q17-54.565 60.652-88.696 43.652-34.13 98.522-34.13 18.547 0 36.882 4.434 18.335 4.435 34.074 13.106l-55.485 70.329q-3.894-1-7.736-1.218-3.841-.217-7.735-.217-33.253 0-56.431 23.178-23.178 23.178-23.178 56.431t23.178 56.431q23.178 23.178 56.431 23.178Z" />
    </SvgIcon>
  );
}

export default ThresholdIcon;
