import { handleActions } from 'redux-actions';
import { addNotification } from '.';
import data from './mock';

const initialState = data;

const notificationsReducer = handleActions(
  {
    [addNotification]: (state, { payload }) => [...state, payload],
  },
  initialState,
);

export default notificationsReducer;
