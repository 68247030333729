import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AlertLowIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        id="Rectangle"
        d="M2,0H14a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
        transform="translate(4 4)"
        fill="#fff"
      />
      <path
        id="Background"
        d="M0,0H24V24H0Z"
        fill="rgba(255,255,255,0)"
        opacity="0"
      />
      <path
        id="Path_170"
        data-name="Path 170"
        d="M-12460-14540h-12a2,2,0,0,1-2-2v-12a2,2,0,0,1,2-2h12a2,2,0,0,1,2,2v12A2,2,0,0,1-12460-14540Zm-6-5.085a1.023,1.023,0,0,0-1,1.041,1.025,1.025,0,0,0,1,1.045,1.025,1.025,0,0,0,1-1.045A1.023,1.023,0,0,0-12466-14545.084Zm0-7.917a.818.818,0,0,0-.8.833v5a.818.818,0,0,0,.8.833.818.818,0,0,0,.8-.833v-5A.818.818,0,0,0-12466-14553Z"
        transform="translate(12478 14560)"
        fill="#b19ebd"
      />
    </SvgIcon>
  );
}

export default AlertLowIcon;
