import { editor } from 'monaco-editor';
import { Connection, Node } from 'react-flow-renderer';

interface IEditor {
  json: any;
  errors: editor.IMarker[];
  action: EditorActions | undefined;
  oldText: string;
  asset: string | undefined;
  fileName: string | undefined;
  isLoading: boolean;
  isFocusEditor: boolean;
}

export interface IEditorState {
  visual: IEditor;
  template: IEditor;
}

export interface EditorActions
  extends NodeSelection,
    LineSelection,
    LineHighlight {
  type: ActionsType;
}

export enum ActionsType {
  nodeSelected = 'nodeSelected',
  lineSelected = 'lineSelected',
  lineHighlighted = 'lineHighlighted',
  nodeResized = 'nodeResized',
  nodeCreated = 'nodeCreated',
}

export type IEdgeAction = IEdgeAddAction | IEdgeRemoveAction;

export interface IEdgeAddAction {
  type: 'add';
  edge: Connection;
}

export interface IEdgeRemoveAction {
  type: 'remove';
  id: string;
}

interface NodeSelection {
  nodeId: Node;
}

interface LineSelection {
  line: number;
  column: number;
}

interface LineHighlight {
  startLine: number;
  endLine: number;
  column: number;
}
