import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { styled, Box } from '@mui/material';
import Progress from './Progress';
import { useGetPortsQuery } from '../../../../redux/services/ports/api';

const Root = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
  '&:hover .edit-btn': {
    opacity: 1,
  },
  '& .progress': {
    top: -37,
    left: -54,
  },
  '& .edit-btn': {
    minWidth: '4rem',
    minHeight: '4rem',
    position: 'absolute',
    right: '-46px',
    top: 0,
    opacity: 0,
    '& svg': {
      fontSize: '3rem',
    },
  },
});

const Label = styled('span')(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  zIndex: 99,
  left: 4,
  right: 4,
  top: '25%',
  lineHeight: '1.5',
  textAlign: 'center',
  fontSize: '2rem',
  color: '#484848',
}));

interface Props {
  isLibrary?: boolean;
  progress?: number;
  id: string;
  data?: any;
}

function BufferConcentrateV1(props: Props) {
  const { isLibrary, progress, id, data } = props;
  const { size } = data || {};
  const { width, height } = size || {};

  const { data: ports } = useGetPortsQuery(undefined);

  const { name } = { name: 'Buffer Concentrate V1' };

  const [port, setPort] = useState([]);
  useEffect(() => {
    if (ports) {
      setPort(ports?.filter(({ parentAggregate }) => parentAggregate === id));
    }
  }, [ports]);

  return (
    <Root
      style={{
        width: width || '',
        height: height || '',
      }}
    >
      {!isLibrary && (
        <Progress progress={progress} className="progress" maxValue={10} />
      )}
      <Box sx={{ position: 'relative' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="330"
          height="324"
          viewBox="0 0 88 96"
        >
          <g
            id="Buffer_Concentrate"
            data-name="Buffer Concentrate"
            transform="translate(0.258)"
          >
            <g
              id="Path_47"
              data-name="Path 47"
              transform="translate(-4921.515 -15080.898)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M4921.256,15080.9h88v67.932l-28.691,28.068h-30.343l-28.965-28.336Z"
                stroke="none"
              />
              <path
                d="M 4922.65625 15082.298828125 L 4922.65625 15147.9736328125 L 4950.79248046875 15175.498046875 L 4979.994140625 15175.498046875 L 5007.8564453125 15148.2412109375 L 5007.8564453125 15082.298828125 L 4922.65625 15082.298828125 M 4921.25634765625 15080.8984375 L 5009.25634765625 15080.8984375 L 5009.25634765625 15148.830078125 L 4980.56494140625 15176.8984375 L 4950.2216796875 15176.8984375 L 4921.25634765625 15148.5625 L 4921.25634765625 15080.8984375 Z"
                stroke="none"
                fill="#3b82f6"
              />
            </g>
          </g>
        </svg>
        <Label>{name}</Label>
        {port.length ? (
          port.map((p, i) => (
            <Handle
              key={p._id}
              className="point"
              type={p.name === 'Outlet' ? 'target' : 'source'}
              position={
                [Object.values(Position)[0], Object.values(Position)[2]][i]
              }
              id="a"
            />
          ))
        ) : (
          <>
            <Handle
              className="point"
              type="source"
              position={Position.Right}
              id="a"
            />
            <Handle
              className="point"
              type="target"
              position={Position.Left}
              id="a"
            />
          </>
        )}
      </Box>
    </Root>
  );
}
BufferConcentrateV1.defaultProps = {
  isLibrary: false,
  progress: 0,
  data: {},
};

export default BufferConcentrateV1;
