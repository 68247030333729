import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CheckboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="Checkbox" transform="translate(2 2)" fill="rgba(255,255,255,0)">
        <path
          d="M 15.2941198348999 19.5 L 4.705880165100098 19.5 C 2.386749982833862 19.5 0.5 17.61325073242188 0.5 15.2941198348999 L 0.5 4.705880165100098 C 0.5 2.386749982833862 2.386749982833862 0.5 4.705880165100098 0.5 L 15.2941198348999 0.5 C 17.61325073242188 0.5 19.5 2.386749982833862 19.5 4.705880165100098 L 19.5 15.2941198348999 C 19.5 17.61325073242188 17.61325073242188 19.5 15.2941198348999 19.5 Z"
          stroke="none"
        />
        <path
          d="M 4.705880165100098 1 C 2.662450790405273 1 1 2.662450790405273 1 4.705880165100098 L 1 15.2941198348999 C 1 17.33754920959473 2.662450790405273 19 4.705880165100098 19 L 15.2941198348999 19 C 17.33754920959473 19 19 17.33754920959473 19 15.2941198348999 L 19 4.705880165100098 C 19 2.662450790405273 17.33754920959473 1 15.2941198348999 1 L 4.705880165100098 1 M 4.705880165100098 0 L 15.2941198348999 0 C 17.89310073852539 0 20 2.106899261474609 20 4.705880165100098 L 20 15.2941198348999 C 20 17.89310073852539 17.89310073852539 20 15.2941198348999 20 L 4.705880165100098 20 C 2.106899261474609 20 0 17.89310073852539 0 15.2941198348999 L 0 4.705880165100098 C 0 2.106899261474609 2.106899261474609 0 4.705880165100098 0 Z"
          stroke="none"
          fill="#cbd5e1"
        />
      </g>
    </SvgIcon>
  );
}

export default CheckboxIcon;
