import { configureStore } from '@reduxjs/toolkit';
import promiseMiddleware from 'redux-promise-middleware';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootReducer, { RootState } from '../rootReducer';
import { loadState, saveState } from './localStorage';
import { rootApi } from '../services';

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([promiseMiddleware, rootApi.middleware]),
  devTools: { maxAge: 15 },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

store.subscribe(() => {
  saveState({
    breadcrumbs: store.getState().breadcrumbs,
    isDemo: store.getState().isDemo,
    ...(store.getState().environment === 'edge' && {
      quickAccess: store.getState().quickAccess,
    }),
  });
});

export default store;
