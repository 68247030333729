import { alpha, ListItem, ListItemText, styled, Tab } from '@mui/material';

export const SystemListItemStyled = styled(ListItem)(({ theme }) => ({
  minHeight: '39px',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.shape.borderRadius,
  maxWidth: '100%',
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: '0.75rem',
  transition: 'none',
  background:
    theme.palette.mode === 'light'
      ? theme.palette.background.paper
      : theme.palette.gradient.main,
  alignItems: 'flex-start',
  '&:hover': {
    cursor: 'pointer',
    background:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.08)
        : 'rgba(255, 255, 255, 0.08)',
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
  '&.Mui-selected': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.bg.dark
        : alpha(theme.palette.primary.main, 0.1),
    borderColor:
      theme.palette.mode === 'dark'
        ? '#676F87'
        : alpha(theme.palette.primary.main, 0.25),
  },
}));

export const SystemTab = styled(Tab)(({ theme }) => ({
  minHeight: '39px',
  padding: '0.125rem 1rem',
  borderRadius: theme.shape.borderRadius,
  maxWidth: '100%',
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: '0.75rem',
  background:
    theme.palette.mode === 'light'
      ? theme.palette.background.paper
      : theme.palette.gradient.main,
  alignItems: 'flex-start',
  '&:hover': {
    background:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.08)
        : 'rgba(255, 255, 255, 0.08)',
  },
  '&.Mui-selected': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.bg.dark
        : alpha(theme.palette.primary.main, 0.1),
    borderColor:
      theme.palette.mode === 'dark'
        ? '#676F87'
        : alpha(theme.palette.primary.main, 0.25),
  },
}));

export const SystemInfo = styled(ListItemText)(({ theme }) => ({
  maxWidth: 'calc(100% - 20px)',
  paddingRight: theme.spacing(2),
  margin: 0,
  '& .MuiListItemText-primary': {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    display: 'block',
    textAlign: 'left',
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.6',
  },
}));

export const StatusWrap = styled('span')({
  overflow: 'hidden',
  minWidth: '55px',
});

export const Title = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  overflow: 'hidden',
  '& span': {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& svg': {
    color: theme.palette.text.secondary,
    fontSize: '1.35rem',
  },
}));

export const CnsIndicator = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.bg.dark,
  borderRadius: theme.shape.borderRadius,
  padding: '2px 4px',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '1',
  textTransform: 'uppercase',
  minHeight: '16px',
  marginRight: theme.spacing(1),
  fontWeight: 400,
}));

export const CnsName = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '1.125rem',
  fontWeight: 400,
}));

export const StatusIcon = styled('span')(({ theme, ...otherProps }) => ({
  color: theme.palette.text.disabled,
  fontSize: '1rem',
  textTransform: 'uppercase',
  display: 'flex',
  position: 'absolute',
  right: '0.75rem',
  top: '50%',
  transform: 'translateY(-50%)',
  ...otherProps.style,
}));
