import React from 'react';
import { Box, ListItemIcon, MenuItem, Switch } from '@mui/material';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';
import { setIsDemo } from '../../../redux/modules/isDemo/slice';
import { envVars } from '../../../env';

interface DemoModeToggleProps {
  handleClose: () => void;
}

const DemoModeToggle = (props: DemoModeToggleProps) => {
  const { handleClose } = props;

  const dispatch = useAppDispatch();

  const isToggleAccessible = useAppSelector(
    (state: IAppState) => state.view.isToggleAccessible,
  );

  const isDemo = useAppSelector((state: IAppState) => state.isDemo);

  const enableDemo = () => {
    dispatch(setIsDemo(!isDemo));
    handleClose();
  };

  if (!isToggleAccessible || !envVars?.demoMode) return null;

  return (
    <MenuItem id="UserMenu-demo-mode" onClick={enableDemo} sx={{ p: '6px' }}>
      <ListItemIcon>
        <AutoAwesomeOutlined />
      </ListItemIcon>
      {isDemo ? 'Disable demo mode' : 'Enable demo mode'}
      <Box sx={{ ml: 2 }} />
      <Switch checked={isDemo} sx={{ ml: 'auto' }} />
    </MenuItem>
  );
};

export default DemoModeToggle;
