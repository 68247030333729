import React from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { StyledSelect } from '../../../../Dialogs/styles';
import { useGetVisualCategoriesQuery } from '../../../../../redux/services/visualCategory/api';
import { useGetVisualsQuery } from '../../../../../redux/services/visuals/api';
import { Count } from './styles';
import { sortArrayByProperty } from '../../../../../utils/stringUtils';

interface Props {
  groupName: string[];
  handleGroupChange: (ev: SelectChangeEvent<string[] | unknown>) => void;
}

const ExportComponent = (props: Props) => {
  const { groupName, handleGroupChange } = props;

  const { data: visualCategories, isLoading: isVisualsCategoriesLoading } =
    useGetVisualCategoriesQuery();
  const { data: visuals, isLoading: isVisualsLoading } = useGetVisualsQuery();

  return (
    <FormControl fullWidth>
      <InputLabel shrink>Export group</InputLabel>
      <StyledSelect
        id="ExportComponent"
        multiple
        value={groupName}
        onChange={handleGroupChange}
        renderValue={() =>
          groupName.length > 0 ? (
            groupName.join(', ')
          ) : (
            <Typography>Choose groups to export</Typography>
          )
        }
        disabled={isVisualsCategoriesLoading}
        displayEmpty
      >
        {sortArrayByProperty(visualCategories, 'name')?.map((group) => {
          const countOfItemsInGroup = visuals?.filter(
            ({ category }) => category === group.name,
          ).length;
          return (
            <MenuItem
              key={group.id}
              value={group.name}
              disabled={countOfItemsInGroup === 0}
            >
              <Checkbox
                sx={{ ml: -2 }}
                checked={groupName?.indexOf(group.name) > -1}
              />
              <ListItemText primary={group.name} />
              {!isVisualsLoading ? (
                <Count>({countOfItemsInGroup})</Count>
              ) : undefined}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </FormControl>
  );
};

export default ExportComponent;
