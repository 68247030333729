import { Box, IconButton, styled } from '@mui/material';

export const Actions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: '100%',
  },
  '& .favBtn': {
    width: '36px',
    height: '36px',
    marginRight: theme.spacing(2),
  },
}));

export const RightContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  padding: 0,
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  position: 'relative',
  '&.fullscreen': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1300,
    backgroundColor: theme.palette.background.default,
  },
}));

export const LoaderWrp = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  zIndex: 10,
}));

export const DiagramWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
}));

export const ToogleBtn = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% - 20px)',
  right: 0,
  width: 40,
  height: 40,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  zIndex: 9,
  borderRight: '0 !important',
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));
