import { SchemasSettings } from 'monaco-yaml';
import { JSONSchema4, JSONSchema6, JSONSchema7 } from 'json-schema';

const uri = 'https://procaaso/template-schema.json';

enum Types {
  Boolean = 'boolean',
  String = 'string',
  Number = 'number',
  Object = 'object',
  Array = 'array',
  Null = 'null',
}

const defaultTemplateFallbackSchema: JSONSchema6 = {
  title: 'SystemDefinition',
  description:
    'A system definition describes a system within the ProCaaSo framework',
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      minLength: 1,
      type: 'string',
    },
    description: {
      title: 'Description',
      minLength: 1,
      type: 'string',
    },
    operationMode: {
      default: 'BATCH_BASED',
      allOf: [
        {
          $ref: '#/definitions/OperationMode',
        },
      ],
    },
    trend: {
      $ref: '#/definitions/TrendConfiguration',
    },
    visuals: {
      title: 'Visuals',
      type: 'object',
      additionalProperties: {
        $ref: '#/definitions/VisualReference',
      },
    },
    components: {
      title: 'Components',
      type: 'object',
      additionalProperties: {
        $ref: '#/definitions/ComponentDefinition',
      },
    },
    connectors: {
      title: 'Connectors',
      type: 'object',
      additionalProperties: {
        $ref: '#/definitions/ConnectorDefinition',
      },
    },
  },
  required: ['name', 'description', 'visuals', 'components', 'connectors'],
  definitions: {
    OperationMode: {
      title: 'OperationMode',
      description: 'The operation mode of systems based on this definition',
      enum: ['BATCH_BASED', 'CONTINUOUS'],
      type: 'string',
    },
    TrendMetricReference: {
      title: 'TrendMetricReference',
      description:
        'A TrendMetricReference lets control programmers reference previously\ndefined trend metrics',
      type: 'object',
      properties: {
        group: {
          title: 'Group',
          type: 'string',
        },
        metric: {
          title: 'Metric',
          type: 'string',
        },
      },
      required: ['group', 'metric'],
    },
    TrendMetricDefinition: {
      title: 'TrendMetricDefinition',
      description:
        'Definition of a predefined "metric" as part of the programatic trend\ncontrol',
      type: 'object',
      properties: {
        query: {
          title: 'Query',
          type: 'string',
        },
      },
      required: ['query'],
    },
    TrendConfiguration: {
      title: 'TrendConfiguration',
      description:
        'TrendConfiguration enables process engineers to have further control over\nthe trend page',
      type: 'object',
      properties: {
        charts: {
          title: 'Charts',
          type: 'object',
          additionalProperties: {
            type: 'array',
            items: {
              $ref: '#/definitions/TrendMetricReference',
            },
          },
        },
        metrics: {
          title: 'Metrics',
          type: 'object',
          additionalProperties: {
            type: 'object',
            additionalProperties: {
              $ref: '#/definitions/TrendMetricDefinition',
            },
          },
        },
      },
    },
    FlairPlacement: {
      title: 'FlairPlacement',
      description:
        'FlairPlacement dictates the positioning of a piece of flair on the Visual',
      type: 'object',
      properties: {
        x: {
          title: 'X',
          type: 'integer',
        },
        y: {
          title: 'Y',
          type: 'integer',
        },
      },
      required: ['x', 'y'],
    },
    TextSizePoint: {
      title: 'TextSizePoint',
      description: 'Arbitrary text size indicators',
      enum: ['XS', 'S', 'M', 'L', 'XL'],
      type: 'string',
    },
    LabelFlairDefinition: {
      title: 'LabelFlairDefinition',
      description:
        'A `label` displays input passed to it without applying any modifications',
      type: 'object',
      properties: {
        placement: {
          $ref: '#/definitions/FlairPlacement',
        },
        type: {
          title: 'Type',
          default: 'label',
          enum: ['label'],
          type: 'string',
        },
        default: {
          title: 'Default',
          minLength: 1,
          type: 'string',
        },
        textSize: {
          $ref: '#/definitions/TextSizePoint',
        },
        backgroundColor: {
          title: 'Backgroundcolor',
          type: 'string',
        },
        textColor: {
          title: 'Textcolor',
          type: 'string',
        },
        prefix: {
          title: 'Prefix',
          type: 'string',
        },
        suffix: {
          title: 'Suffix',
          type: 'string',
        },
      },
      required: ['placement', 'default', 'textSize'],
    },
    Orientation: {
      title: 'Orientation',
      description: 'Visual orientation options for flairs that support them',
      enum: ['HORIZONTAL', 'VERTICAL'],
      type: 'string',
    },
    BarFlairDefinition: {
      title: 'BarFlairDefinition',
      description:
        'A `bar` flair corresponds to a fillable bar which can be positioned as\neither horizontally or vertically',
      type: 'object',
      properties: {
        placement: {
          $ref: '#/definitions/FlairPlacement',
        },
        type: {
          title: 'Type',
          default: 'bar',
          enum: ['bar'],
          type: 'string',
        },
        maximum: {
          title: 'Maximum',
          type: 'number',
        },
        orientation: {
          $ref: '#/definitions/Orientation',
        },
      },
      required: ['placement', 'maximum', 'orientation'],
    },
    PortFlowClass: {
      title: 'PortFlowClass',
      description: 'The flow class assigned to the port',
      enum: ['INLET', 'OUTLET'],
      type: 'string',
    },
    PortHeading: {
      title: 'PortHeading',
      description:
        "Where is the port located in relation to the component's center point",
      enum: ['TOP', 'LEFT', 'RIGHT', 'BOTTOM'],
      type: 'string',
    },
    PortAnchorFlairDefinition: {
      title: 'PortAnchorFlairDefinition',
      description:
        'A `portAnchor` corresponds to the visual placement point of a port',
      type: 'object',
      properties: {
        placement: {
          $ref: '#/definitions/FlairPlacement',
        },
        type: {
          title: 'Type',
          default: 'portAnchor',
          enum: ['portAnchor'],
          type: 'string',
        },
        flow: {
          $ref: '#/definitions/PortFlowClass',
        },
        heading: {
          $ref: '#/definitions/PortHeading',
        },
      },
      required: ['placement'],
    },
    BooleanIndicatorFlairDefinition: {
      title: 'BooleanIndicatorFlairDefinition',
      description:
        'A `booleanIndicator` provides visual indication for a boolean value',
      type: 'object',
      properties: {
        placement: {
          $ref: '#/definitions/FlairPlacement',
        },
        type: {
          title: 'Type',
          default: 'booleanIndicator',
          enum: ['booleanIndicator'],
          type: 'string',
        },
        trueColor: {
          title: 'Truecolor',
          type: 'string',
        },
        falseColor: {
          title: 'Falsecolor',
          type: 'string',
        },
      },
      required: ['placement', 'trueColor', 'falseColor'],
    },
    VisualSize: {
      title: 'VisualSize',
      description: 'VisualSize controls sizing of the visual on the diagram',
      type: 'object',
      properties: {
        height: {
          title: 'Height',
          exclusiveMinimum: 0,
          type: 'integer',
        },
        width: {
          title: 'Width',
          exclusiveMinimum: 0,
          type: 'integer',
        },
      },
      required: ['height', 'width'],
    },
    VisualReference: {
      title: 'VisualReference',
      description: 'Reference pointing to a Visual',
      type: 'object',
      properties: {
        id: {
          title: 'Id',
          type: 'string',
        },
        version: {
          title: 'Version',
          type: 'integer',
        },
        flairs: {
          title: 'Flairs',
          default: {},
          type: 'object',
          additionalProperties: {
            anyOf: [
              {
                $ref: '#/definitions/LabelFlairDefinition',
              },
              {
                $ref: '#/definitions/BarFlairDefinition',
              },
              {
                $ref: '#/definitions/PortAnchorFlairDefinition',
              },
              {
                $ref: '#/definitions/BooleanIndicatorFlairDefinition',
              },
            ],
          },
        },
        size: {
          $ref: '#/definitions/VisualSize',
        },
      },
      required: ['id', 'version'],
    },
    ComponentType: {
      title: 'ComponentType',
      description: 'An enumeration.',
      enum: ['bufferConcentrate', 'pixer', 'water', 'hidden'],
      type: 'string',
    },
    ComponentVisualisation: {
      title: 'ComponentVisualisation',
      description: 'Instructions on how to visualise the component',
      type: 'object',
      properties: {
        $ref: {
          title: '$Ref',
          type: 'string',
        },
        input: {
          title: 'Input',
          type: 'object',
          additionalProperties: {
            type: 'string',
          },
        },
        flairs: {
          title: 'Flairs',
          default: {},
          type: 'object',
          additionalProperties: {
            anyOf: [
              {
                $ref: '#/definitions/LabelFlairDefinition',
              },
              {
                $ref: '#/definitions/BarFlairDefinition',
              },
              {
                $ref: '#/definitions/PortAnchorFlairDefinition',
              },
              {
                $ref: '#/definitions/BooleanIndicatorFlairDefinition',
              },
              {
                type: 'null',
              },
            ],
          },
        },
        size: {
          $ref: '#/definitions/VisualSize',
        },
      },
      required: ['$ref', 'input'],
    },
    AttributeStateDefault: {
      title: 'AttributeStateDefault',
      description:
        'Raw object representing the default state for the attribute',
      type: 'object',
      properties: {},
    },
    AttributeDefinition: {
      title: 'AttributeDefinition',
      description:
        'Definition of an attribute entity in the configuration tree',
      type: 'object',
      properties: {
        stateSchemaId: {
          title: 'Stateschemaid',
          type: 'string',
        },
        default: {
          $ref: '#/definitions/AttributeStateDefault',
        },
      },
      required: ['stateSchemaId', 'default'],
    },
    InstrumentDefinition: {
      title: 'InstrumentDefinition',
      description: 'Definition of an instrument in the configuration tree',
      type: 'object',
      properties: {
        attributes: {
          title: 'Attributes',
          type: 'object',
          additionalProperties: {
            $ref: '#/definitions/AttributeDefinition',
          },
        },
      },
      required: ['attributes'],
    },
    PortDefinition: {
      title: 'PortDefinition',
      description: 'Definition of a port in the configuration tree',
      type: 'object',
      properties: {
        anchor: {
          title: 'Anchor',
          type: 'string',
        },
        attributes: {
          title: 'Attributes',
          default: {},
          type: 'object',
          additionalProperties: {
            $ref: '#/definitions/AttributeDefinition',
          },
        },
      },
    },
    ComponentDefinition: {
      title: 'ComponentDefinition',
      description: 'Definition of a component in the configuration tree',
      type: 'object',
      properties: {
        render: {
          title: 'Render',
          type: 'array',
          minItems: 2,
          maxItems: 2,
          items: [
            {
              type: 'integer',
            },
            {
              type: 'integer',
            },
          ],
        },
        componentType: {
          $ref: '#/definitions/ComponentType',
        },
        visual: {
          $ref: '#/definitions/ComponentVisualisation',
        },
        instruments: {
          title: 'Instruments',
          type: 'object',
          additionalProperties: {
            $ref: '#/definitions/InstrumentDefinition',
          },
        },
        ports: {
          title: 'Ports',
          default: {},
          type: 'object',
          additionalProperties: {
            $ref: '#/definitions/PortDefinition',
          },
        },
      },
      required: ['render', 'instruments'],
    },
    PortReference: {
      title: 'PortReference',
      description: 'Reference to a Port entity',
      type: 'object',
      properties: {
        $ref: {
          title: '$Ref',
          type: 'string',
        },
      },
      required: ['$ref'],
    },
    Waypoint: {
      title: 'Waypoint',
      description:
        'A Waypoint represents the coordinates of a point of the connector line in\n2D space',
      type: 'object',
      properties: {
        x: {
          title: 'X',
          type: 'integer',
        },
        y: {
          title: 'Y',
          type: 'integer',
        },
      },
      required: ['x', 'y'],
    },
    ConnectorDefinition: {
      title: 'ConnectorDefinition',
      description: 'Definition of a connector between two ports',
      type: 'object',
      properties: {
        ports: {
          title: 'Ports',
          type: 'array',
          minItems: 2,
          maxItems: 2,
          items: [
            {
              $ref: '#/definitions/PortReference',
            },
            {
              $ref: '#/definitions/PortReference',
            },
          ],
        },
        instruments: {
          title: 'Instruments',
          type: 'object',
          additionalProperties: {
            $ref: '#/definitions/InstrumentDefinition',
          },
        },
        waypoints: {
          title: 'Waypoints',
          default: [],
          type: 'array',
          items: {
            $ref: '#/definitions/Waypoint',
          },
        },
      },
      required: ['ports', 'instruments'],
    },
  },
};

const defaultVisualsFallbackSchema: JSONSchema6 = {
  title: 'VisualDefinition',
  description:
    'A VisualDefinition represents the Visual definition structure document',
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      minLength: 1,
      type: 'string',
    },
    description: {
      title: 'Description',
      minLength: 1,
      type: 'string',
    },
    flairs: {
      title: 'Flairs',
      type: 'object',
      additionalProperties: {
        discriminator: {
          propertyName: 'type',
          mapping: {
            label: '#/definitions/LabelFlairDefinition',
            bar: '#/definitions/BarFlairDefinition',
            portAnchor: '#/definitions/PortAnchorFlairDefinition',
            booleanIndicator: '#/definitions/BooleanIndicatorFlairDefinition',
          },
        },
        oneOf: [
          {
            $ref: '#/definitions/LabelFlairDefinition',
          },
          {
            $ref: '#/definitions/BarFlairDefinition',
          },
          {
            $ref: '#/definitions/PortAnchorFlairDefinition',
          },
          {
            $ref: '#/definitions/BooleanIndicatorFlairDefinition',
          },
        ],
      },
    },
    size: {
      $ref: '#/definitions/VisualSize',
    },
  },
  required: ['name', 'description', 'flairs'],
  definitions: {
    FlairPlacement: {
      title: 'FlairPlacement',
      description:
        'FlairPlacement dictates the positioning of a piece of flair on the Visual',
      type: 'object',
      properties: {
        x: {
          title: 'X',
          type: 'integer',
        },
        y: {
          title: 'Y',
          type: 'integer',
        },
      },
      required: ['x', 'y'],
    },
    TextSizePoint: {
      title: 'TextSizePoint',
      description: 'Arbitrary text size indicators',
      enum: ['XS', 'S', 'M', 'L', 'XL'],
      type: 'string',
    },
    LabelFlairDefinition: {
      title: 'LabelFlairDefinition',
      description:
        'A `label` displays input passed to it without applying any modifications',
      type: 'object',
      properties: {
        placement: {
          $ref: '#/definitions/FlairPlacement',
        },
        type: {
          title: 'Type',
          default: 'label',
          enum: ['label'],
          type: 'string',
        },
        default: {
          title: 'Default',
          minLength: 1,
          type: 'string',
        },
        textSize: {
          $ref: '#/definitions/TextSizePoint',
        },
        backgroundColor: {
          title: 'Backgroundcolor',
          type: 'string',
        },
        textColor: {
          title: 'Textcolor',
          type: 'string',
        },
        prefix: {
          title: 'Prefix',
          type: 'string',
        },
        suffix: {
          title: 'Suffix',
          type: 'string',
        },
      },
      required: ['placement', 'default', 'textSize'],
    },
    Orientation: {
      title: 'Orientation',
      description: 'Visual orientation options for flairs that support them',
      enum: ['HORIZONTAL', 'VERTICAL'],
      type: 'string',
    },
    BarFlairDefinition: {
      title: 'BarFlairDefinition',
      description:
        'A `bar` flair corresponds to a fillable bar which can be positioned as\neither horizontally or vertically',
      type: 'object',
      properties: {
        placement: {
          $ref: '#/definitions/FlairPlacement',
        },
        type: {
          title: 'Type',
          default: 'bar',
          enum: ['bar'],
          type: 'string',
        },
        maximum: {
          title: 'Maximum',
          type: 'number',
        },
        orientation: {
          $ref: '#/definitions/Orientation',
        },
      },
      required: ['placement', 'maximum', 'orientation'],
    },
    PortFlowClass: {
      title: 'PortFlowClass',
      description: 'The flow class assigned to the port',
      enum: ['INLET', 'OUTLET'],
      type: 'string',
    },
    PortHeading: {
      title: 'PortHeading',
      description:
        "Where is the port located in relation to the component's center point",
      enum: ['TOP', 'LEFT', 'RIGHT', 'BOTTOM'],
      type: 'string',
    },
    PortAnchorFlairDefinition: {
      title: 'PortAnchorFlairDefinition',
      description:
        'A `portAnchor` corresponds to the visual placement point of a port',
      type: 'object',
      properties: {
        placement: {
          $ref: '#/definitions/FlairPlacement',
        },
        type: {
          title: 'Type',
          default: 'portAnchor',
          enum: ['portAnchor'],
          type: 'string',
        },
        flow: {
          $ref: '#/definitions/PortFlowClass',
        },
        heading: {
          $ref: '#/definitions/PortHeading',
        },
      },
      required: ['placement'],
    },
    BooleanIndicatorFlairDefinition: {
      title: 'BooleanIndicatorFlairDefinition',
      description:
        'A `booleanIndicator` provides visual indication for a boolean value',
      type: 'object',
      properties: {
        placement: {
          $ref: '#/definitions/FlairPlacement',
        },
        type: {
          title: 'Type',
          default: 'booleanIndicator',
          enum: ['booleanIndicator'],
          type: 'string',
        },
        trueColor: {
          title: 'Truecolor',
          type: 'string',
        },
        falseColor: {
          title: 'Falsecolor',
          type: 'string',
        },
      },
      required: ['placement', 'trueColor', 'falseColor'],
    },
    VisualSize: {
      title: 'VisualSize',
      description: 'VisualSize controls sizing of the visual on the diagram',
      type: 'object',
      properties: {
        height: {
          title: 'Height',
          exclusiveMinimum: 0,
          type: 'integer',
        },
        width: {
          title: 'Width',
          exclusiveMinimum: 0,
          type: 'integer',
        },
      },
      required: ['height', 'width'],
    },
  },
};

export const generateSchema = (
  schema: JSONSchema4 | JSONSchema6 | JSONSchema7,
): SchemasSettings[] => [
  {
    uri,
    fileMatch: ['*'],
    schema,
  },
];

export const templateSchema: SchemasSettings[] = generateSchema(
  defaultTemplateFallbackSchema,
);
export const visualsSchema: SchemasSettings[] = generateSchema(
  defaultVisualsFallbackSchema,
);
