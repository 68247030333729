import React from 'react';
import BaseFlair from './BaseFlair';

interface Props {
  className?: string;
  data?: any;
  selected?: boolean;
}

const defaultMinWidth = 6;
const defaultMinHeight = 6;

function PortAnchor(props: Props) {
  const { className, data, selected } = props;

  return (
    <BaseFlair
      className={className}
      data={data}
      selected={selected}
      resizeRatio={1}
      lockAspectRatio
      defaultMinWidth={defaultMinWidth}
      defaultMinHeight={defaultMinHeight}
      content={
        <div
          style={{
            transform: 'none',
            backgroundColor: '#C51C1C',
            boxSizing: 'inherit',
            border: '1px solid #fff',
            borderRadius: '100%',
            height: '100%',
            width: '100%',
          }}
        />
      }
    />
  );
}

PortAnchor.defaultProps = {
  className: '',
  data: {},
  selected: false,
};

export default PortAnchor;
