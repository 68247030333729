import { TabList } from '@mui/lab';
import {
  Box,
  IconButton,
  FormControl,
  Stack,
  styled,
  alpha,
} from '@mui/material';
import { FixedSizeList as List } from 'react-window';

export const Root = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  maxHeight: '100%',
  overflow: 'hidden',
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.default,
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    paddingRight: theme.spacing(3),
    marginRight: '-1.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    border: 0,
    borderRadius: 0,
    marginBottom: theme.spacing(1),
    padding: `0 ${theme.spacing(2)}`,
    marginRight: 0,
  },
}));

export const LeftPanel = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: '330px',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

export const SearchFilter = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),
}));

export const SearchControl = styled(FormControl)({
  width: '100%',
  flex: 1,
  marginBottom: 0,
});

export const ToggleBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: '1px solid transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&.active': {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    borderColor:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  },
}));

export const StyledTabs = styled(TabList)(({ theme }) => ({
  height: '100%',
  padding: 0,
  borderBottom: 0,
  width: '100%',
  flex: 1,
  '& .MuiTabs-scrollableY': {
    paddingRight: theme.spacing(1),
    marginBottom: '0 !important',
    overflow: 'auto !important',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const ListTabs = styled(List)({
  height: '100%',
  padding: 0,
  borderBottom: 0,
  width: '100%',
  overflowY: 'auto',
  paddingRight: '6px',
  '&::-webkit-scrollbar': {
    display: 'inherit !important',
  },
});

export const ListWrap = styled(Stack)(({ theme }) => ({
  flexGrow: 0,
  maxWidth: '300px',
  minWidth: '300px',
  flexBasis: '35%',
  maxHeight: 'calc(100% - 4.5rem)',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    flexBasis: '100%',
  },
}));
