import React from 'react';
import {
  Box,
  Button,
  Divider,
  InputLabel,
  Stack,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { envVars } from '../../env';
import Input from '../../common/Input';
import { FormProps } from '../../redux/services/users/types';

// Should look into making use of Ory Elements instead of what we do here
// https://github.com/ory/elements

const AlertBox = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  backgroundColor: alpha(theme.palette.info.main, 0.025),
  fontSize: '1rem',
  fontWeight: 600,
  maxHeight: '300px',
  transition: 'max-height 0.5s ease-in',
  overflow: 'hidden',
  '&.saved': {
    borderColor: alpha(theme.palette.success.main, 0.5),
    backgroundColor: alpha(theme.palette.success.main, 0.1),
  },
  '&.hidden': {
    maxHeight: 0,
    padding: 0,
    marginBottom: 0,
    borderWidth: 0,
    transition: 'max-height 0.5s ease-out',
    transitionDelay: '0.5',
    transitionProperty: 'padding, border-width, margin-bottom',
  },
}));

const isEdge = envVars.environment === 'edge';

const AlertItem = ({ matched, label }: { matched: boolean; label: string }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      color: matched ? 'success.main' : 'error.main',
    }}
  >
    {matched ? <CheckRounded /> : <CloseRounded />}
    {label}
  </Box>
);

const PasswordAlert = ({
  isSaved,
  rules,
  isPasswordsMatched,
  isNotEmpty,
}: {
  rules: { rule: string; isMatched: boolean }[];
  isSaved: boolean;
  isPasswordsMatched: boolean;
  isNotEmpty: boolean;
}) => {
  if (!rules?.length && !isSaved) return null;
  return (
    <AlertBox
      className={`${isSaved ? 'saved' : ''} ${isNotEmpty ? '' : 'hidden'}`}
    >
      {isSaved ? (
        <AlertItem matched label="Password updated successfully." />
      ) : (
        <>
          {rules.map(({ rule, isMatched }) => (
            <AlertItem key={rule} matched={isMatched} label={rule} />
          ))}
          <AlertItem
            matched={isPasswordsMatched}
            label="Passwords must match"
          />
        </>
      )}
    </AlertBox>
  );
};

const formLabels = {
  email: 'E-mail',
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
  passwordConfirmation: 'Confirm password',
};

const formIdsForInputs = {
  email: 'EditProfile-E-Mail-input',
  firstName: 'EditProfile-FirstName-input',
  lastName: 'EditProfile-LastName-input',
  password: 'EditProfile-Password-input',
  passwordConfirmation: 'EditProfile-Confirm-password-input',
};

const TextField = ({
  label,
  value,
  type,
  handleChange,
  isDisabled,
  isError,
}: {
  label: string;
  value: string;
  type: string;
  handleChange: (event: React.BaseSyntheticEvent, label: string) => void;
  isDisabled?: boolean;
  isError?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box>
      <InputLabel
        error={isError}
        sx={{ '&.Mui-error': { borderColor: theme.palette.error.main } }}
      >
        {formLabels?.[label]}
      </InputLabel>
      <Input
        placeholder={type === 'password' && '***********'}
        value={value}
        error={isError}
        type={type}
        onChange={(e) => handleChange(e, label)}
        disabled={isEdge || isDisabled}
        id={formIdsForInputs[label]}
        fullWidth
        sx={{
          mt: '4px !important',
          mb: 3,
          '&.Mui-error': { borderColor: theme.palette.error.main },
        }}
      />
    </Box>
  );
};
TextField.defaultProps = {
  isDisabled: undefined,
  isError: false,
};

export function SettingsForm({
  state,
  setState,
  patterns,
  isConfirmPasswordError,
  isPasswordError,
}: {
  state: FormProps;
  setState: React.Dispatch<React.SetStateAction<FormProps>>;
  patterns: { rule: string; isMatched: boolean }[];
  isConfirmPasswordError: boolean;
  isPasswordError: boolean;
}) {
  const handleState = (e: React.BaseSyntheticEvent, label: string) => {
    setState((prev) => ({ ...prev, [label]: e.target.value }));
  };

  return (
    <Box>
      {Object.entries(state)
        .filter(([key]) => key.indexOf('password') === -1)
        .map(([key, value]) => (
          <TextField
            key={key}
            label={key}
            value={value}
            handleChange={handleState}
            type="text"
          />
        ))}
      <Divider sx={{ m: 2 }} />
      <PasswordAlert
        isSaved={false}
        rules={patterns}
        isPasswordsMatched={
          Boolean(state.password) &&
          Boolean(state.passwordConfirmation) &&
          state.password === state.passwordConfirmation
        }
        isNotEmpty={
          Boolean(state.password) || Boolean(state.passwordConfirmation)
        }
      />
      {Object.entries(state)
        .filter(([key]) => key.indexOf('password') !== -1)
        .map(([key, value]) => (
          <TextField
            key={key}
            label={key}
            value={value}
            handleChange={handleState}
            type="password"
            isError={isConfirmPasswordError || isPasswordError}
          />
        ))}
    </Box>
  );
}
