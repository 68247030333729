import * as React from 'react';
import { Box, alpha } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

function RunningLoader(props: CircularProgressProps) {
  const { size, value } = props;
  const defaultSize: number = +size || 40;
  return (
    <Box
      sx={{
        width: defaultSize,
        height: defaultSize,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          lineHeight: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) => alpha(theme.palette.success.main, 0.2),
            [`& .${circularProgressClasses.circle}`]: {
              animation: 'none',
            },
          }}
          size={defaultSize}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant={value ? 'determinate' : 'indeterminate'}
          value={value}
          disableShrink={!value}
          color="success"
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              animation: 'none',
              strokeLinecap: 'round',
            },
          }}
          size={defaultSize}
          thickness={4}
          {...props}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '64%',
            height: '64%',
            borderRadius: '50%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: defaultSize * 0.35,
            lineHeight: 0,
            backgroundColor: (theme) => theme.palette.success.main,
          }}
        />
      </Box>
    </Box>
  );
}

export default RunningLoader;
