import React from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import { ISystem } from '../../typescript/interfaces/system.interface';
import { IProject } from '../../typescript/interfaces/project.interface';
import DialogComponent from './DialogComponent';

interface Props {
  entity: IProject | ISystem | any;
  open: boolean;
  onClose: any;
  handleAction: any;
}

const DeleteConfigTemplate = (props: Props) => {
  const { entity, open, onClose, handleAction } = props;

  return (
    <DialogComponent
      isOpened={open}
      closeModal={onClose}
      title={`Delete ${entity?.name}`}
      actionTitle="Confirm"
      handleAction={handleAction}
      cancelId="DeleteConfig-cancel"
      actionId="DeleteConfig-confirm"
    >
      <DialogContent>
        <DialogContentText>
          Please confirm if you want to delete <strong>{entity?.name}</strong>.
          <br />
          process is irreversible. All data for given config will be scrubbed
          and irretrievable.
        </DialogContentText>
      </DialogContent>
    </DialogComponent>
  );
};

DeleteConfigTemplate.defaultProps = {};
export default DeleteConfigTemplate;
