import React, { FC, useMemo, useContext, useEffect, useCallback } from 'react';
import { styled } from '@mui/material';
import { EdgeProps, getMarkerEnd, useNodes } from 'react-flow-renderer';
import cn from 'classnames';
import { Context } from '../../../CreateProjectDiagram/context';

const G = styled('g')({
  '&.edit': {
    pointerEvents: 'auto',
  },
  '& .react-flow__edge-path': {
    strokeWidth: 2,
    '&.transparent': {
      strokeWidth: 10,
      stroke: 'transparent !important',
    },
  },
});

const CustomQEdge: FC<EdgeProps> = (props) => {
  const {
    id,
    source,
    target,
    markerStart,
    markerEnd,
    style,
    sourceX,
    sourceY,
    targetX,
    targetY,
    selected,
  } = props;

  const { setNodes, nodesDraggable } = useContext(Context) || {};

  const waypoints = useMemo(
    () => props?.data?.waypoints,
    [props?.data?.waypoints],
  );

  const nodes = useNodes();
  // @ts-ignore
  const markerEndType = getMarkerEnd(markerStart, markerEnd);

  const sourceNode = useMemo(
    () => nodes.find((n) => n.id === source),
    [source, nodes],
  );
  const targetNode = useMemo(
    () => nodes.find((n) => n.id === target),
    [target, nodes],
  );

  // S - source
  // I - interim
  // T - target

  const SX = sourceX;
  const SY = sourceY;
  const TX = targetX;
  const TY = targetY;

  const transformedWaypoints = useMemo(
    () =>
      waypoints?.map((waypoint, idx) => ({
        data: { isHidden: true },
        height: 32,
        width: 32,
        id: `edgeDot_${id}_${idx}`,
        position: {
          x: sourceNode.position.x + waypoint.x,
          y: sourceNode.position.y + waypoint.y,
        },
        positionAbsolute: {
          x: sourceNode.position.x + waypoint.x,
          y: sourceNode.position.y + waypoint.y,
        },
        type: 'edgeDot',
      })),
    [waypoints],
  );

  const allPoints = useMemo(() => {
    if (transformedWaypoints) {
      const summarizedNodes = [
        ...nodes.filter((node) => node.id.includes(`edgeDot_${id}`)),
        ...transformedWaypoints,
      ];

      return summarizedNodes.filter(
        (n, idx) =>
          summarizedNodes.map((sNodes) => sNodes.id).indexOf(n.id) === idx,
      );
    }
    return nodes.filter((node) => node.id.includes(`edgeDot_${id}`));
  }, [nodes, transformedWaypoints]);

  const extraPoints = useMemo(
    () => allPoints.filter((point) => point.id.includes('extra')),
    [allPoints],
  );
  const points = useMemo(
    () => allPoints.filter((point) => !point.id.includes('extra')),
    [allPoints],
  );

  const { strMainQ, strQ } = useMemo(() => {
    if (!points.length && !extraPoints.length) {
      return {
        strQ: `Q ${(SX + TX) / 2} ${(SY + TY) / 2}`,
        strMainQ: `Q ${(SX + TX) / 2} ${(SY + TY) / 2}`,
      };
    }

    if (extraPoints.length === 1) {
      return {
        strQ: `Q ${extraPoints[0].position.x} ${extraPoints[0].position.y}`,
        strMainQ: `Q ${extraPoints[0].position.x} ${extraPoints[0].position.y}`,
      };
    }
    const foundSelectedExtraPoint: any = extraPoints.find(
      (point) => point.dragging || point.selected,
    );
    const points2 = [...points];

    if (foundSelectedExtraPoint) {
      const { prevDot, nextDot } = foundSelectedExtraPoint.data || {};
      const foundIndexPrevDot = points.findIndex((node) => node.id === prevDot);
      const foundIndexNextDot = points.findIndex((node) => node.id === nextDot);

      if (foundIndexPrevDot !== -1 && foundIndexNextDot === -1) {
        points2.splice(foundIndexPrevDot + 1, 0, foundSelectedExtraPoint);
      } else if (foundIndexNextDot !== -1) {
        points2.splice(foundIndexNextDot, 0, foundSelectedExtraPoint);
      } else if (foundIndexPrevDot === -1 && foundIndexNextDot === -1) {
        points2.push(foundSelectedExtraPoint);
      }
    }

    if (!sourceNode || !targetNode) {
      return null;
    }

    return {
      strMainQ: points
        .map((p, i) => {
          let str = `Q ${p.position.x} ${p.position.y}`;
          if (i === points.length - 1) {
            return str;
          }
          str += ` ${(p.position.x + points[i + 1].position.x) / 2} ${
            (p.position.y + points[i + 1].position.y) / 2
          }`;

          return str;
        })
        .join(' '),
      strQ: points2
        .map((p, i) => {
          let str = `Q ${p.position.x} ${p.position.y}`;
          if (i === points2.length - 1) {
            return str;
          }
          str += ` ${(p.position.x + points2[i + 1].position.x) / 2} ${
            (p.position.y + points2[i + 1].position.y) / 2
          }`;

          return str;
        })
        .join(' '),
    };
  }, [
    JSON.stringify(points),
    JSON.stringify(extraPoints),
    JSON.stringify(transformedWaypoints),
    SX,
    SY,
    TX,
    TY,
  ]);

  const dots = useMemo(() => {
    const arr = [];

    if (!points.length) {
      arr.push({
        x: (SX + TX) / 2,
        y: (SY + TY) / 2,
      });
    }

    points.forEach((p, i) => {
      if (i === 0) {
        arr.push({
          x: (p.position.x + SX) / 2,
          y: (p.position.y + SY) / 2,
          nextDot: p.id,
        });
      }

      if (i === points.length - 1) {
        arr.push({
          x: (p.position.x + TX) / 2,
          y: (p.position.y + TY) / 2,
          prevDot: p.id,
        });
      } else {
        arr.push({
          x: (p.position.x + points[i + 1].position.x) / 2,
          y: (p.position.y + points[i + 1].position.y) / 2,
          prevDot: p.id,
          nextDot: points[i + 1].id,
        });
      }
    });

    return arr.map(({ x, y, prevDot, nextDot }, i) => ({
      id: `edgeDot_${id}_${i}_extra`,
      type: 'edgeDot',
      position: { x, y },
      data: { prevDot, nextDot, isHidden: true },
    }));
  }, [JSON.stringify(points), SX, SY, TX, TY]);

  useEffect(() => {
    if (setNodes) {
      setNodes((prev) => {
        let nNodes = prev.filter((n) => !n.id.includes(id));
        const isFoundSelectedPoint =
          prev.findIndex(
            (node) =>
              node.id.includes(`edgeDot_${id}`) &&
              (node.selected || !node.data.isHidden),
          ) !== -1;
        const isHidden = !(isFoundSelectedPoint || selected);
        const allNodes = prev
          .filter((node) => node.id.includes(`edgeDot_${id}`))
          .map((node) => ({ ...node, data: { ...node.data, isHidden } }));
        let mainNodes = allNodes.filter((point) => !point.id.includes('extra'));
        const extraNodes = allNodes.filter((point) =>
          point.id.includes('extra'),
        );
        const newExtraNodes = dots.map((node) => ({
          ...node,
          data: { ...node.data, isHidden },
        }));
        const isNoExtraNodes = Boolean(!extraNodes.length && mainNodes.length);
        if (isNoExtraNodes) {
          mainNodes = mainNodes
            .filter((node) => node.id.includes(`edgeDot_${id}`))
            .map((node) => ({
              ...node,
              data: { ...node.data, isHidden: true },
            }));
        }

        if (selected) {
          nNodes = nNodes.map((n) =>
            n.id.includes('edgeDot_')
              ? { ...n, data: { ...n.data, isHidden: true } }
              : n,
          );
        }

        const newNodes = [...nNodes, ...mainNodes, ...newExtraNodes];
        if (
          (isFoundSelectedPoint || selected || isNoExtraNodes) &&
          JSON.stringify(prev) !== JSON.stringify(newNodes)
        ) {
          return newNodes;
        }

        return prev;
      });
    }
  }, [dots, selected]);

  const handleRemoveWayPoints = useCallback(() => {
    if (nodesDraggable) {
      setNodes((prev) => prev.filter((n) => !n.id.includes(id)));
    }
  }, [setNodes, id, nodesDraggable]);

  return (
    <G
      className={cn('react-flow__connection', nodesDraggable && 'edit')}
      onDoubleClick={handleRemoveWayPoints}
    >
      <path
        id={id}
        className="react-flow__edge-path transparent"
        d={`M${SX} ${SY} ${strMainQ} ${TX} ${TY}`}
        markerEnd={markerEndType}
        style={style}
      />
      <path
        id={id}
        className="react-flow__edge-path"
        d={`M${SX} ${SY} ${strMainQ} ${TX} ${TY}`}
        markerEnd={markerEndType}
        style={style}
      />
      <path
        id={id}
        className="react-flow__edge-path"
        d={`M${SX} ${SY} ${strQ} ${TX} ${TY}`}
        markerEnd={markerEndType}
        style={{ ...style, strokeDasharray: 10 }}
      />
    </G>
  );
};

export default CustomQEdge;
