import { CreateSiteCommand, Site } from './types';
import { apiBaseUrlV1 } from '../../../env';
import { rootApi } from '..';

const BASE_URL = apiBaseUrlV1('structure/v1/sites');

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSites: builder.query<Site[], void>({
      query: () => BASE_URL,
      providesTags: ['Sites'],
    }),
    createSite: builder.mutation<Site, CreateSiteCommand>({
      query: (data) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        body: data,
      }),
    }),
    updateSite: builder.mutation<Site, { data: CreateSiteCommand; id: string }>(
      {
        query: ({ data, id }) => ({
          url: `${BASE_URL}/${id}`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: ['Sites'],
      },
    ),
    deleteSite: builder.mutation<undefined, string>({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetSitesQuery,
  useCreateSiteMutation,
  useDeleteSiteMutation,
  useUpdateSiteMutation,
} = extendedApi;
