import { handleActions } from 'redux-actions';
import { setNewState } from './actions';

const initialState = {
  projects: undefined,
  systems: undefined,
};

const stateReducer = handleActions(
  {
    [setNewState]: (_, { payload }) => ({ ...payload }),
  },
  initialState,
);

export default stateReducer;
