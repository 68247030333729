import { handleActions } from 'redux-actions';
import { setQuickAccess, setQuickAccessOrder } from './actions';

const storedState = JSON.parse(localStorage.getItem('quickAccess'));

const quickAccessReducer = handleActions(
  {
    [setQuickAccess]: (state, { payload }) => {
      if (typeof payload !== 'string') return state;

      const newState = state.find((item) => item === payload)
        ? state.filter((item) => item !== payload)
        : [...state, payload];

      localStorage.setItem('quickAccess', JSON.stringify(newState));
      return newState;
    },
    [setQuickAccessOrder]: (_, { payload }) => payload,
  },
  storedState,
);

export default quickAccessReducer;
