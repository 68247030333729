/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  styled,
} from '@mui/material';
import { MoreHorizRounded } from '@mui/icons-material';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import { Home } from './styles';
import { ROUTES_PATH } from '../constants';
import { useAppDispatch } from '../redux/store';
import { setBreadcrumbsBasedOnLayer } from '../redux/modules/breadcrumbs/slice';
import { IBreadcrumb } from '../typescript/interfaces/breadcrumbs.interface';

interface Props {
  className?: string;
  sx?: any;
  isMobile?: boolean;
}

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '2.5rem',
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginBottom: theme.spacing(1),
  },
}));

const BCrumbs = styled(MuiBreadcrumbs)({
  display: 'flex',
});

const MoreBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#101b31' : '#f5f5f5',
  borderRadius: 4,
  height: 'auto',
  width: 'auto',
  padding: '0 0.25rem',
  margin: '0 0.25rem',
}));

function Breadcrumbs(props: Props) {
  const { className, sx, isMobile } = props;
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const breadcrumbs = useSelector((state: IAppState) => state.breadcrumbs);

  const handleBreadcrumbsClick = (breadcrumb: IBreadcrumb) => {
    dispatch(setBreadcrumbsBasedOnLayer(breadcrumb.layer));
  };

  const getBreadcrumbId = (breadcrumbId: string) => `-${breadcrumbId}`;

  const withDropdown = isMobile && breadcrumbs?.length > 2;

  return (
    <Root className={className} sx={sx}>
      <BCrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          to={ROUTES_PATH.DASHBOARD}
          key="Home"
          id="breadcrumb-Home"
        >
          <Home />
        </Link>
        {withDropdown && (
          <>
            <MoreBtn
              id="breadcrumbs-button"
              aria-controls={open ? 'breadcrumbs-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreHorizRounded />
            </MoreBtn>
            <Menu
              id="breadcrumbs-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'breadcrumbs-button',
              }}
            >
              {breadcrumbs
                ?.filter(({ name }) => name !== 'Home')
                ?.slice(0, breadcrumbs?.length - 2)
                ?.map((breadcrumb) => (
                  <MenuItem
                    component={Link}
                    color="inherit"
                    to={`${breadcrumb.url}`}
                    key={`${breadcrumb.url}-${breadcrumb.name}`}
                    id={`breadcrumb${
                      breadcrumb?.id ? getBreadcrumbId(breadcrumb?.id) : ''
                    }`}
                    onClick={() => {
                      handleBreadcrumbsClick(breadcrumb);
                    }}
                  >
                    {breadcrumb.name}
                  </MenuItem>
                ))}
            </Menu>
          </>
        )}
        {breadcrumbs
          ?.filter(({ name }) => name !== 'Home')
          ?.slice(withDropdown ? -1 : 0)
          ?.map((breadcrumb) => (
            <Link
              color="inherit"
              to={`${breadcrumb.url}`}
              key={`${breadcrumb.url}-${breadcrumb.name}`}
              id={`breadcrumb${
                breadcrumb?.id ? getBreadcrumbId(breadcrumb?.id) : ''
              }`}
              onClick={() => {
                handleBreadcrumbsClick(breadcrumb);
              }}
            >
              {breadcrumb.name}
            </Link>
          ))}
      </BCrumbs>
    </Root>
  );
}

Breadcrumbs.defaultProps = {
  className: '',
  sx: {},
  isMobile: false,
};

export default Breadcrumbs;
