import React from 'react';
import { CssBaseline, Portal } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Snackbar from './common/Snackbar';
import Header from './common/Header';
import { routes } from './constants';
import PrivateRoute from './utils/PrivateRoute';
import './api/axios';
import ErrorBoundary from './common/ErrorBoundary';
import withOidcProvider from './hoc/withOIdcProvider';
import PasswordExpirationBar from './common/PasswordExpirationBar';

export interface AppRouterProps {
  darkMode: boolean;
  toggleTheme: () => void;
}

const AppRouter = ({ darkMode, toggleTheme }: AppRouterProps) => (
  <ConfirmProvider
    defaultOptions={{
      confirmationButtonProps: {
        autoFocus: true,
        variant: 'contained',
      },
      cancellationButtonProps: {
        variant: 'outlined',
        color: 'inherit',
      },
    }}
    dialogProps={{
      sx: { '& .MuiDialogContent-root': { px: 3 } },
    }}
  >
    <CssBaseline />
    <BrowserRouter>
      <Portal>
        <Snackbar />
      </Portal>
      <PasswordExpirationBar />
      <Header isDark={darkMode} toggleTheme={toggleTheme} />
      <ErrorBoundary>
        <Switch>
          {routes.map((route) =>
            route.componentParent === 'Route' ? (
              <Route
                key={`${route.path}`}
                component={route.component}
                path={route.path}
                exact
              />
            ) : (
              <PrivateRoute key={`${route.path}`} {...route} />
            ),
          )}
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  </ConfirmProvider>
);

export default withOidcProvider(AppRouter);
