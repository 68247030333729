import { getOrigin } from '../../../env';
import { rootApi } from '..';
import { UiContainer } from '../../../api/auth';

const BASE_URL = getOrigin();

interface EdgeLoginFlow {
  ui: UiContainer;
}

interface EdgeLogoutProps {
  logout_token: string;
  logout_url: string;
}

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getEdgeLoginFlow: builder.query<EdgeLoginFlow, string | void>({
      query: (returnTo) => {
        const params = {
          refresh: true,
          returnTo,
        };
        return {
          url: `${BASE_URL}/kratos/self-service/login/browser`,
          params,
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json,text/plain,*/*',
          },
        };
      },
      method: 'GET',
    }),
    silentEdgeLogoutFlow: builder.query<EdgeLogoutProps, void>({
      query: () => ({
        url: `${BASE_URL}/kratos/self-service/logout/browser`,
        method: 'GET',
      }),
    }),
    silentUserLogout: builder.query<void, EdgeLogoutProps>({
      query: ({ logout_url: url }) => ({
        url,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetEdgeLoginFlowQuery,
  useLazySilentEdgeLogoutFlowQuery,
  useLazySilentUserLogoutQuery,
} = extendedApi;
