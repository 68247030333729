import axios, { AxiosError } from 'axios';
import { environment, ssoStorage, token } from '../env';
import { tokenParserStorage } from '../hoc/utils';
import { authExpiredRedirectIfNeeded } from '../utils/helper';

const env = environment();

// Negative check because we are assuming that the default is cloud where the env might not be specified
if (env !== 'edge') {
  axios.interceptors.request.use((config) => {
    // Might want some caching for the token so we don't parse it from cookie for each request
    const accessToken = tokenParserStorage({
      storage: localStorage,
      entityName: ssoStorage(),
      searchValue: 'tokens',
    })?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });
}

axios.interceptors.response.use(
  (resp) => resp,
  (error: AxiosError) => {
    authExpiredRedirectIfNeeded(error.config.url, error.response?.status);
    return Promise.reject(error);
  },
);
